import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import OverridableField from 'components/DoorForm/OverridableField';

export default function PartsTable() {
  const { setFieldValue, values } = useFormikContext();

  return (
    <div id="parts-table" className="col-12 col-sm-12">
      <table className="table table-fixed table-hover">
        <thead>
          <th></th>
          <th>Code</th>
          <th>Unit Price</th>
          <th>Quantity</th>
        </thead>
        <tbody>
          <tr>
            <td>Framing Timber</td>
            <td>{values.calculator && values.calculator.framing_timber.part_code}</td>
            <td>{values.calculator && values.calculator.framing_timber.part_price}</td>
            <td>{values.calculator && values.calculator.framing_timber.quantity}</td>
          </tr>
          <tr>
            <td>Core</td>
            <td>{values.calculator && values.calculator.core.part_code}</td>
            <td>{values.calculator && values.calculator.core.part_price}</td>
            <td>{values.calculator && values.calculator.core.quantity}</td>
          </tr>
          <tr>
            <td>Core Kit</td>
            <td>{values.calculator && values.calculator.core_kit.part_code}</td>
            <td>{values.calculator && values.calculator.core_kit.part_price}</td>
            <td>{values.calculator && values.calculator.core_kit.quantity}</td>
          </tr>
          <tr>
            <td>Skin</td>
            <td>{values.calculator && values.calculator.skin.part_code}</td>
            <td>{values.calculator && values.calculator.skin.part_price}</td>
            <td>{values.calculator && values.calculator.skin.quantity}</td>
          </tr>
          <tr>
            <td>Extra Skin</td>
            <td>{values.calculator && values.calculator.extra_skin.part_code}</td>
            <td>{values.calculator && values.calculator.extra_skin.part_price}</td>
            <td>{values.calculator && values.calculator.extra_skin.quantity}</td>
          </tr>
          <tr>
            <td>Jamb</td>
            <td>{values.calculator && values.calculator.jamb.part_code}</td>
            <td>{values.calculator && values.calculator.jamb.part_price}</td>
            <td>{values.calculator && values.calculator.jamb.quantity}</td>
          </tr>
          <tr>
            <td>Hinge</td>
            <td>{values.calculator && values.calculator.hinge.part_code}</td>
            <td>{values.calculator && values.calculator.hinge.part_price}</td>
            <td>{values.calculator && values.calculator.hinge.quantity}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
