import React, { createContext, useState, useContext } from "react";

const SidebarContext = createContext();
export const SidebarProvider = ({ children }) => {
  const [active, setActive] = useState(false);

  function toggleActive() {
    setActive(!active);
  }

  return (
    <SidebarContext.Provider value={{ active, toggleActive }}>
      {children}
    </SidebarContext.Provider>
  );
};

export function useSidebar() {
  return useContext(SidebarContext);
}
