import React, { useMemo } from 'react';
import { useQuery } from 'react-query';

import { getTipcoConfigOptions } from 'utils/horizon-api-client';

export function useTipcoConfigOptions() {
  const { data, status } = useQuery('tipcoConfigOptions', fetchOptions);
  const optionsData = useMemo(parseOptionsData, [data]);

  async function fetchOptions(key) {
    const response = await getTipcoConfigOptions();
    return response;
  }

  function parseOptionsData() {
    if (typeof data !== 'undefined') {
      return {
        tipcoHungTypes: data.data.tipco_hung_types,
        tipcoJambs: data.data.tipco_jambs,
        tipcoLeaves: data.data.tipco_leaves,
        tipcoHumesDesignations: data.data.tipco_humes_designations,
        tipcoCroppingDimensions: data.data.tipco_cropping_dimensions,
        doorJambHandings: data.data.door_jamb_handings,
      };
    } else {
      return {
        tipcoHungTypes: [],
        tipcoJambs: [],
        tipcoLeaves: [],
        tipcoHumesDesignations: [],
        tipcoCroppingDimensions: [],
        doorJambHandings: [],
      };
    }
  }

  return {
    ...optionsData,
    status,
  };
}
