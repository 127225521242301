import React from 'react';
import ReactTooltip from 'react-tooltip';
import ReactDOMServer from 'react-dom/server';
import Portal from 'utils/Portal';

import 'assets/stylesheets/reactTooltip.css';

function Tooltip({ text }) {
  return (
    <>
      <Portal>
        <ReactTooltip type="info" place="bottom" event="click" className="tooltip" />
      </Portal>
      <span
        className="material-icons-outlined icon-middle-size mb-1"
        data-tip={ReactDOMServer.renderToString(text)}
        data-html={true}
      >
        info
      </span>
    </>
  );
}

export default Tooltip;
