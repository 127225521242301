import React from "react";
import Header from "components/Header";

function Home() {
  return (
    <>
      <Header pageTitle="Home Page" />
    </>
  );
};

export default Home;
