import React from "react";
import { toast } from "react-toastify";
import { useParams, Link } from "react-router-dom";

import DoorExtraForm from "components/DoorExtraForm";
import FormHeader from "components/FormHeader";

import { updateDoorExtra } from "utils/horizon-api-client";
import { useDoorExtra } from "hooks/useDoorExtra";
import { useDoorExtraPolicy } from "policies/useDoorExtraPolicy";

function EditDoorExtra() {
  const { id } = useParams();
  const { data, status, refetch } = useDoorExtra({ id: id });
  const isLoading = status === "loading";
  const { canCreate } = useDoorExtraPolicy();

  async function onSubmit(values, actions) {
    const response = await updateDoorExtra({
      id: id,
      values: values,
    });

    actions.setErrors(response.data.errors);
    sendNotification(response);
  }

  function sendNotification(response) {
    switch (response.status) {
      case 200:
        toast.success("Extra successfully saved!");
        refetch();
        break;
      case 403:
        toast.error(
          "You do not have permissions to update this extra. Please contact an Admin"
        );
        break;
      case 422:
        toast.error("Error saving door extra.");
        break;
      case 500:
        toast.error("Internal server error.");
        break;
    }
  }

  return (
    <>
      <DoorExtraForm
        onSubmit={onSubmit}
        initialValues={data}
        isLoading={isLoading}
        header={
          <FormHeader
            title={
              <>
                <Link to="/door-extras">
                  <span className="material-icons-outlined">arrow_back</span>
                </Link>
                Edit DoorExtra
              </>
            }
            canCreate={canCreate}
          />
        }
      />
    </>
  );
}

export default EditDoorExtra;
