import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { find } from 'lodash';
import { useDoorConfigOptions } from 'hooks/useDoorConfigOptions';
import { matchAnywhere } from 'utils/filter-utils';

import DropdownInput from 'components/Formik/DropdownInput';
import Input from 'components/Formik/Input';

function PrehangExtra({ index, onRemove, onDuplicate, objectData, doorPrehangExtra }) {
  const { doorExtras: dropdownOptions } = useDoorConfigOptions();
  const { setFieldValue, values } = useFormikContext();
  const [margin, setMargin] = useState(1.4);

  useEffect(() => {
    if (isPersisted()) {
      updateMarginFromSell({ value: values.door_prehang_extras[index].sell });
    }
  }, []);

  function updateMarginFromSell({ value }) {
    setMargin(calculateMargin(value));
  }

  function calculateMargin(sell) {
    const cost = values.door_prehang_extras[index].cost;
    return (sell / cost).toFixed(2);
  }

  function afterCostUpdate({ value }) {
    updateSellFromMargin({ cost: value, margin: margin });
  }

  function isPersisted() {
    return typeof doorPrehangExtra.id !== 'undefined';
  }

  function handleMarginChange(e) {
    setMargin(e.target.value);
    const cost = values.door_prehang_extras[index].cost;
    updateSellFromMargin({ cost: cost, margin: e.target.value });
  }

  function updateSellFromMargin({ cost, margin }) {
    const sellFromMargin = (cost * margin).toFixed(2);
    setFieldValue(`door_prehang_extras[${index}].sell`, sellFromMargin);
  }

  function handleExtraCodeChange({ value }) {
    resetCostSellToDefault({ value: value });
    let newMargin;
    const chosenExtra = getExtraProps({ extraId: value });
    const sellCents = chosenExtra.sell_cents;
    const costCents = chosenExtra.cost_cents;
    const preconfiguredMargin = chosenExtra.margin;
    const calculatedMargin = (sellCents / costCents).toFixed(2);

    if (typeof preconfiguredMargin !== 'undefined' && Number(preconfiguredMargin) > 0) {
      newMargin = preconfiguredMargin;
    } else {
      newMargin = calculatedMargin;
    }

    setMargin(newMargin);
    updateSellFromMargin({ cost: costCents / 100, margin: newMargin });
  }

  function getExtraProps({ extraId }) {
    return find(dropdownOptions, option => option.value === extraId);
  }

  function resetCostSellToDefault({ value }) {
    const chosenExtra = find(dropdownOptions, option => option.value === value);

    setFieldValue(`door_prehang_extras[${index}].cost`, (chosenExtra.cost_cents / 100.0).toFixed(2));
    setFieldValue(`door_prehang_extras[${index}].sell`, (chosenExtra.sell_cents / 100.0).toFixed(2));
  }

  if (doorPrehangExtra._destroy) return null;

  return (
    <div className="form-group-bordered">
      <div className="card-header card-header-mobile d-flex d-sm-none">
        <span className="label">Extra {index + 1}</span>
        <div className="form-icons-group ml-auto">
          <a href="#">
            <span className="material-icons-outlined">delete</span>
          </a>
        </div>
      </div>
      <span className="label d-none d-sm-block">Extra {index + 1}</span>

      <div
        className={`form-row mt-5 mt-sm-0 align-items-center mt-3 ${doorPrehangExtra._destroy ? 'for-destroy' : ''}`}
      >
        <div className="col-md-10 col-lg-10">
          <div className="form-row mt-0">
            <div className="col-4 col-sm-4 col-md-4 col-lg-4">
              <div className="form-group">
                <label htmlFor={`door_prehang_extras[${index}].door_extra_id`}>Code</label>
                <Field
                  name={`door_prehang_extras[${index}].door_extra_id`}
                  placeholder="Code"
                  component={DropdownInput}
                  options={dropdownOptions}
                  customFilter={matchAnywhere}
                  afterChange={handleExtraCodeChange}
                  as="select"
                >
                  <option value=""></option>
                </Field>
              </div>
            </div>

            <div className="col-2 col-sm-2 col-md-2 col-lg-2">
              <div className="form-group">
                <label htmlFor={`door_prehang_extras[${index}].qty`}>Qty</label>
                <Field name={`door_prehang_extras[${index}].qty`} placeholder={1} component={Input} />
              </div>
            </div>

            <div className="col-2 col-sm-2 col-md-2 col-lg-2">
              <div className="form-group">
                <label>Cost</label>
                <Field name={`door_prehang_extras[${index}].cost`} component={Input} afterChange={afterCostUpdate} />
              </div>
            </div>

            <div className="col-2 col-sm-2 col-md-2 col-lg-2">
              <div className="form-group">
                <label htmlFor="extraMargin">Margin</label>
                <input className="form-control" onChange={handleMarginChange} value={`${margin}`} />
              </div>
            </div>

            <div className="col-2 col-sm-2 col-md-2 col-lg-2">
              <div className="form-group">
                <label>Sell</label>
                <Field
                  name={`door_prehang_extras[${index}].sell`}
                  afterChange={updateMarginFromSell}
                  component={Input}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-1 col-lg-1 d-sm-block d-none">
          <div className="form-icons-group">
            <a href="" onClick={onDuplicate}>
              <span className="material-icons">file_copy</span>
            </a>
            <a href="" onClick={onRemove}>
              <span className="material-icons-outlined">delete</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrehangExtra;
