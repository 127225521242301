import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import IndexTable from 'components/IndexTable';

import { useSearchHelperOptions } from 'hooks/useSearchHelperOptions';
import { useInfiniteIndexData } from 'hooks/useInfiniteIndexData';
import { getQuotes } from 'utils/horizon-api-client';

import SearchTooltip from 'components/SearchTooltip';
import Search from 'components/Search';
import ModalWrapper from 'components/ModalWrapper';
import QuoteStatusButton from 'components/QuoteStatusButton';

import 'assets/stylesheets/globalSearchResults.css';

function GlobalSearchInput({ onShow, onHide }) {
  const [active, toggleActive] = useState(false);
  const [showResults, setShowResults] = useState(false);

  const initialTableState = {
    sortBy: [
      {
        id: 'last_updated',
        desc: true,
      },
    ],
  };

  const searchOptions = {
    quote_no: {
      name: 'quote_no',
      description: 'Quote number contains value',
      aliases: ['id', 'quote', 'number', 'no'],
      predicate: 'cont_any',
      displayName: 'Quote No.',
      defaultSearchTerm: 'quote:',
    },
    company_name: {
      name: 'customer_company_name',
      description: 'Find quotes associated with customer company name',
      aliases: ['customer', 'cust', 'company_name', 'customer_name'],
      predicate: 'cont_any',
      displayName: 'Customer Name',
      defaultSearchTerm: 'customer:',
    },
    job_name: {
      name: 'job_name',
      description: 'Find quotes associated with job name',
      aliases: ['job', 'job_name'],
      predicate: 'cont_any',
      displayName: 'Job Name',
      defaultSearchTerm: 'job:',
    },
    other: {
      name: 'quote_no',
      aliases: [],
      predicate: 'cont_any',
    },
  };

  const { searchHelperOptions } = useSearchHelperOptions({ searchOptions });

  const { recordData, totalCount, searchFieldValue, setSearchTerm, status } = useInfiniteIndexData({
    requestData: getQuotes,
    searchOptions,
    nestedResourceId: null,
    perPage: 25,
    mapRecordToTable: mapQuoteToTable,
    prefilter: '',
  });

  function mapQuoteToTable(quote) {
    return {
      quote_no: quote.quote_no,
      description: quote.description,
      order_no: quote.order_no,
      customer_name: quote.customer.company_name,
      total_doors: quote.total_doors,
      total_cost: quote.total_cost_cents / 100,
      total_sale_price: quote.total_sale_price_cents / 100,
      sales_person: quote.user.name,
      core_date: quote.core_date,
      added_value_date: quote.added_value_date,
      painting_date: quote.painting_date,
      press_date: quote.press_date,
      dlo_date: quote.dlo_date,
      edd_date: quote.edd_date,
      last_updated: moment(quote.updated_at).fromNow(),
      last_updated_at: quote.updated_at,
      status: <QuoteStatusButton status={quote.status} />,
      link: (
        <Link to={`/quotes/${quote.id}/edit`} className="edit">
          <span className="material-icons icon-grey">edit</span>
        </Link>
      ),
    };
  }

  // TODO Extract these from here and Quotes
  const sortOnStatus = useCallback((rowA, rowB, columnId) => {
    const rowAValue = rowA.values.status.props.status;
    const rowBValue = rowB.values.status.props.status;
    if (rowAValue > rowBValue) return 1;
    if (rowAValue < rowBValue) return -1;
    return 0;
  }, []);
  const sortOnLastUpdated = useCallback((rowA, rowB, columnId) => {
    const rowAValue = Date.parse(rowA.original.last_updated_at);
    const rowBValue = Date.parse(rowB.original.last_updated_at);
    if (rowAValue > rowBValue) return 1;
    if (rowAValue < rowBValue) return -1;
    return 0;
  }, []);

  function handleSearchOpen() {
    toggleActive(true);
    setShowResults(true);
  }

  const columns = useMemo(() => {
    const arr = [
      {
        Header: 'Quote No ',
        accessor: 'quote_no',
      },
      {
        Header: 'Customer Name ',
        accessor: 'customer_name',
      },
      {
        Header: 'Total Doors ',
        accessor: 'total_doors',
        className: 'v-sm-visible',
      },
      {
        Header: 'Total Sale Price ',
        accessor: 'total_sale_price',
        className: 'v-sm-visible',
      },
      {
        Header: 'Sales Person ',
        accessor: 'sales_person',
        className: 'v-sm-visible',
      },
      {
        Header: 'Status ',
        accessor: 'status',
        className: 'v-sm-visible',
        sortType: sortOnStatus,
      },
      {
        Header: 'Last Updated ',
        accessor: 'last_updated',
        className: 'v-sm-visible',
        sortType: sortOnLastUpdated,
      },
      {
        Header: ' ',
        accessor: 'link',
        className: 'v-sm-visible',
      },
    ];
    return arr;
  }, []);

  function handleOnHide() {
    onHide();
    setShowResults(false);
  }

  function handleOnShow() {
    onShow();
  }

  return (
    <div className="global-search-input">
      {!active ? (
        <FontAwesomeIcon icon={['fa', 'search']} className="icon" onClick={handleSearchOpen} />
      ) : (
        <div className="d-flex">
          <div className="d-flex mr-2 on-top">
            <SearchTooltip searchHelperOptions={searchHelperOptions} />
          </div>
          <Search
            searchHelperOptions={searchHelperOptions}
            setSearchTerm={setSearchTerm}
            searchFieldValue={searchFieldValue}
            onTagChange={() => setShowResults(true)}
            placeholder={'Global Search Filter'}
          />
        </div>
      )}

      <ModalWrapper
        dialogClassName={'search-results-modal'}
        onHide={() => handleOnHide()}
        onShow={() => handleOnShow()}
        show={showResults}
        title="Quotes"
      >
        <IndexTable columns={columns} data={recordData} status={status} initialState={initialTableState} />
      </ModalWrapper>
    </div>
  );
}

export default GlobalSearchInput;
