import React from 'react';
import { Map, Marker, TileLayer } from 'react-leaflet';

function MapDisplay({ coords, shouldShowMarker }) {
  const zoom = shouldShowMarker ? 15 : 5;
  const position = [coords.lat, coords.lng];
  const marker = shouldShowMarker ? <Marker position={position} /> : null;

  if (typeof coords.lat === 'undefined' || typeof coords.lng === 'undefined') return null;

  return (
    <>
      <Map center={position} zoom={zoom}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {marker}
      </Map>
    </>
  );
}

export default MapDisplay;
