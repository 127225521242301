import React, { useState, useEffect } from "react";
import Select from "react-select";

import { ErrorMessage } from "formik";

import Input from "components/Formik/Input";
import "assets/stylesheets/editableDropdownInput.css";

const EditableDropdownInput = ({
  field,
  form,
  options,
  afterChange,
  onFocus,
  placeholder = "Select..",
  ...props
}) => {
  const meta = form.getFieldMeta(field.name);
  const hasError = typeof meta.error !== "undefined";
  const hasBeenTouched = meta.touched;
  const shouldDisplayError = hasError && hasBeenTouched;

  const [inputValue, setInputValue] = useState("");

  const opts = options;

  function onInputChange(inputValue, { action }) {
    if (action === "input-change") {
      setInputValue(inputValue);
      form.setFieldValue(field.name, inputValue);

      if (typeof afterChange === "function") {
        afterChange({ value: inputValue });
      }
    }
  }

  function onSelection(option) {
    setInputValue(option ? option.value : "");
    form.setFieldValue(field.name, option.value);

    if (typeof afterChange === "function") {
      afterChange({ value: option.value });
    }
  }

  useEffect(() => {
    setInputValue(field.value);
  }, [field.value]);

  return (
    <div className="editable-select">
      <Select
        options={opts}
        isClearable={true}
        value={inputValue}
        inputValue={inputValue}
        onFocus={onFocus}
        placeholder={placeholder}
        onInputChange={onInputChange}
        onChange={onSelection}
        isDisabled={props.disabled}
        controlShouldRenderValue={false}
        component={Input}
        className={
          shouldDisplayError
            ? "dropdownInput-wrapper invalid-input"
            : "dropdownInput-wrapper"
        }
      />
      {shouldDisplayError && (
        <span className="input-error">
          <ErrorMessage name={field.name} />
        </span>
      )}
    </div>
  );
};

export default EditableDropdownInput;
