import React, { useEffect, useState } from 'react';

import DoorDropdownMenu from 'components/DoorsList/DoorDropdownMenu';
import DuplicateDoor from 'components/DoorsList/DuplicateDoor';

function DoorUtilities({ door }) {
  const [selectedUtility, setSelectedUtility] = useState('dropdown');
  const [activeUtility, setUtility] = useState(null);

  const resourceId = door.id;
  const quoteId = door.quote_id;
  const isChild = !!door.parent_id;

  useEffect(() => {
    if (selectedUtility === 'dropdown') {
      setUtility(
        <DoorDropdownMenu
          door={door}
          resourceId={resourceId}
          setSelectedUtility={setSelectedUtility}
          isChild={isChild}
          quoteId={quoteId}
          isSlider={door.is_slider}
        />
      );
    } else if (selectedUtility === 'duplicateDoor') {
      setUtility(
        <DuplicateDoor door={door} resourceId={resourceId} quoteId={quoteId} setSelectedUtility={setSelectedUtility} />
      );
    } else {
      setUtility(<DoorDropdownMenu door={door} resourceId={resourceId} setSelectedUtility={setSelectedUtility} />);
    }
  }, [selectedUtility, resourceId]);

  return <>{activeUtility}</>;
}

export default DoorUtilities;
