import React from 'react';
import { toast } from 'react-toastify';

import { Link, useParams } from 'react-router-dom';

import FireDoorForm from 'components/DoorForm/Fire/FireDoorForm';
import DoorFormHeaderNav from 'components/DoorForm/DoorFormHeaderNav';
import FormHeader from 'components/FormHeader';

import { updateFireDoor } from 'utils/horizon-api-client';
import { useFireDoor } from 'hooks/useFireDoor';

function EditFireDoor() {
  const { doorId } = useParams();
  const { data, status, refetch } = useFireDoor({ id: doorId });
  const isLoading = status === 'loading';

  async function onSubmit(values, actions) {
    const response = await updateFireDoor({
      id: doorId,
      values: values,
    });

    actions.setErrors(response.data.errors);
    sendNotification(response);
  }

  function sendNotification(response) {
    if (response.status === 200) {
      toast.success('Door successfully saved!');
      refetch();
    } else if (response.status === 403) {
      toast.error('You do not have permissions to update this door. Please contact an Admin');
    } else if (response.status === 422) {
      if (response.data.errors.base) {
        toast.error(response.data.errors.base[0]);
      } else {
        toast.error('Error saving door');
      }
    } else if (response.status === 500) {
      toast.error('Internal server error.');
    }
  }

  return (
    <>
      <FireDoorForm
        onSubmit={onSubmit}
        initialValues={data}
        isLoading={isLoading}
        quoteId={data.quote_id}
        headerRightNav={<DoorFormHeaderNav isSaving={false} handleSave={onSubmit} />}
        header={
          <FormHeader
            title={
              <>
                <Link to="/doors">
                  <span className="material-icons-outlined">arrow_back</span>
                </Link>
                Edit Door
              </>
            }
          />
        }
      />
    </>
  );
}

export default EditFireDoor;
