import React, { useEffect, useMemo, useState } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { find } from 'lodash';
import StandaloneComponent from './StandaloneComponent';
import { useComponents } from '../../hooks/useComponents';

function StandaloneComponentsList({ onDelete }) {
  const { setFieldValue, values } = useFormikContext();
  const { components, status } = useComponents();
  const [componentsCount, setComponentsCount] = useState(0);

  const dropdownOptions = useMemo(() => {
    return components
      .filter(c => !!c.part_no)
      .map(c => {
        return { label: `${c.part_no} - ${c.part_description || ''}` || '', value: c.id };
      });
  }, [status]);

  useEffect(() => {
    setComponentsCount(values.standalone_item_components ? values.standalone_item_components.length : 0);
  }, [values.standalone_item_components]);

  function getObjData(id) {
    const data = find(components, obj => obj.id === id);
    return data || {};
  }

  function addPanel(e, arrayHelpers) {
    e.preventDefault();
    arrayHelpers.push({
      qty: 1,
    });
  }

  function removeObj(e, index, arrayHelpers) {
    e.preventDefault();
    if (typeof onDelete === 'function') {
      onDelete(values.standalone_item_components[index]);
    }
    const item = values.standalone_item_components[index];

    if (item._destroy) {
      delete item._destroy;
      setFieldValue(`standalone_item_components[${index}]`, item);
    } else if (typeof item.id !== 'undefined') {
      setFieldValue(`standalone_item_components[${index}]`, Object.assign(item, { _destroy: true }));
    } else {
      arrayHelpers.remove(index);
    }
  }

  function duplicateObj(e, index, arrayHelpers) {
    e.preventDefault();
    const newPanel = Object.assign({}, values.standalone_item_components[index]);
    newPanel.id = null;
    arrayHelpers.push(newPanel);
  }

  function hasPendingRemovals() {
    if (typeof values.standalone_item_components === 'undefined') return false;
    const keys = values.standalone_item_components.map(sic => Object.keys(sic));
    return keys.flat().includes('_destroy');
  }

  hasPendingRemovals();

  return (
    <FieldArray
      name="standalone_item_components"
      render={arrayHelpers => (
        <div className="card mt-0">
          <div className="card-header d-flex align-items-center">
            <h2 className="card-heading mb-0">
              Components ({componentsCount})
              {hasPendingRemovals() && (
                <span className="pending-removals pl-2"> has pending component removals. Save to execute</span>
              )}
            </h2>
            <a
              href="#"
              className="btn btn-rounded btn-red btn-shadow ml-auto"
              onClick={e => {
                addPanel(e, arrayHelpers);
              }}
            >
              <span className="material-icons-outlined">add</span>
            </a>
          </div>

          <div className="card-body">
            {values.standalone_item_components && values.standalone_item_components.length > 0
              ? values.standalone_item_components.map((obj, index) => (
                  <StandaloneComponent
                  index={index}
                  key={index}
                  standaloneItemComponent={obj}
                  dropdownOptions={dropdownOptions}
                  onRemove={e => {
                    removeObj(e, index, arrayHelpers);
                  }}
                  onDuplicate={e => {
                    duplicateObj(e, index, arrayHelpers);
                  }}
                  objectData={getObjData(obj.door_extra_id)}
                  />
              ))
              : ''}
          </div>
        </div>
      )}
    />
  );
}

export default StandaloneComponentsList;
