import React, {useMemo, useRef, useState} from 'react';
import {Field, useFormikContext} from 'formik';

import DropdownInput from 'components/Formik/DropdownInput';
import Input from 'components/Formik/Input';

import PartsTable from 'components/DoorForm/Fire/PartsTable';

import {withBlankOption} from 'utils/form-utils';

import {useFireConfigOptions} from 'hooks/useFireConfigOptions';
import PricingOverview from '../Fire/PricingOverview';
import {useFireDoor} from '../../../hooks/useFireDoor';
import {useParams} from 'react-router-dom';

/**
 * @returns {JSX.Element}
 * @constructor
 */
export default function DoorDetails() {
    const {values, setFieldValue} = useFormikContext();
    const {
        manufacturers,
        fireCores,
        fireMeetingStiles,
        skinCodes,
        fireSkins,
        fireGrilleOpenings,
        fireVisionPanels,
        fireDoorJambTimbers,
        doorJambProfiles,
        fireDoorJambHandings,
        fireDoorJambConts,
        fireDoorClashingStrips,
    } = useFireConfigOptions();

    const {doorId} = useParams();
    const {data} = useFireDoor({id: doorId});

    const [showFullDetails, setShowFullDetails] = useState(false);

    const pairWidth = useRef();

    const manufacturerSelectOptions = useMemo(() => withBlankOption(manufacturers), [manufacturers]);
    const fireCoreSelectOptions = useMemo(() => {
        return withBlankOption(fireCores)
    }, [fireCores]);
    const fireMeetingStilesSelectOptions = useMemo(() => withBlankOption(fireMeetingStiles), [fireMeetingStiles]);
    const skinCodesSelectOptions = useMemo(() => withBlankOption(skinCodes), [skinCodes]);
    const fireSkinsSelectOptions = useMemo(() => withBlankOption(fireSkins), [fireSkins]);
    const fireDoorClashingStripsSelectOptions = useMemo(() => withBlankOption(fireDoorClashingStrips), [
        fireDoorClashingStrips,
    ]);

    /**
     * Go to next field when slash is entered
     * @param event
     * @param field
     * @returns {void}
     */
    const nextFieldOnSlash = ({event, field}) => {
        if (event.nativeEvent.data === '/') {
            setFieldValue(field.name, field.value);
            setFieldValue('width_pair', '');
            pairWidth.current.focus();
        }
    };

    const handleShowFullDetails = e => {
        e.preventDefault();
        setShowFullDetails(!showFullDetails);
    };

    function isNewDoorPage() {
        return document.location.pathname.includes('/new');
    }

    const doorTypeOptions = [
        {
            value: 'SL',
            label: 'Single',
        },
        {
            value: 'PR',
            label: 'Pair',
        },
        {
            value: 'PU',
            label: 'Uneven Pair',
        },
        {
            value: 'W',
            label: 'Window',
        },
        {
            value: 'SS',
            label: 'SS',
        },
        {
            value: 'FW',
            label: 'FW',
        },
    ];

    return (
        <div>
            <div className="card card-top card-top-big">
                <div className="card-header">
                    <h2 className="card-heading">Door Details</h2>
                    <hr/>
                    <div className="form-row">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                            {!isNewDoorPage() && (
                                <>
                                    <PricingOverview doorData={data}/>
                                    <a href="#">
                                        <span onClick={handleShowFullDetails}>{showFullDetails ? 'Hide' : 'Show'} full details</span>
                                    </a>
                                </>
                            )}
                        </div>

                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <Field
                                    name="notes"
                                    cols="30"
                                    rows="4"
                                    placeholder="Door Notes"
                                    className="form-control"
                                    as="textarea"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-row">{showFullDetails && <PartsTable/>}</div>
                </div>

                <div className="card-body py-3 pb-5">
                    <div className="form-row">
                        <div className="col-6 col-sm-6 col-md-4 col-lg-4">
                            <label htmlFor="serial">Serial</label>
                            <Field name="serial" placeholder="Serial" component={Input} disabled/>
                        </div>

                        <div className="col-6 col-sm-6 col-md-4 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="reference">Reference</label>
                                <Field name="reference" placeholder="Reference" component={Input}/>
                            </div>
                        </div>

                        <div className="col-6 col-sm-6 col-md-4 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="door_type">Type</label>
                                <Field
                                    name="door_type"
                                    placeholder="Type"
                                    component={DropdownInput}
                                    options={doorTypeOptions}
                                    as="select"
                                >
                                    <option value=""></option>
                                </Field>
                            </div>
                        </div>
                    </div>

                    {/* Row 2 */}
                    <div className="form-row">
                        <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                            <div className="form-group">
                                <label htmlFor="height">Height</label>
                                <Field name="height" placeholder="Height" component={Input}/>
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                            <div className="form-group">
                                <label htmlFor="width">Width</label>
                                <Field name="width" placeholder="Width" afterChange={nextFieldOnSlash}
                                       component={Input}/>
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                            <div className="form-group">
                                <label htmlFor="width_pair">Pair Width</label>
                                <Field name="width_pair" placeholder="Pair Width" innerRef={pairWidth}
                                       component={Input}/>
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                            <div className="form-group">
                                <label htmlFor="thickness">Thickness</label>
                                <Field name="thickness" placeholder="Thickness" component={Input}/>
                            </div>
                        </div>
                    </div>

                    {/* Row 3 */}
                    <div className="form-row">
                        <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                            <div className="form-group">
                                <label htmlFor="manufacturer">Manufacturer</label>
                                <Field
                                    name="manufacturer"
                                    placeholder="Manufacturer"
                                    component={DropdownInput}
                                    options={manufacturerSelectOptions}
                                    as="select"
                                >
                                    <option value=""></option>
                                </Field>
                            </div>
                        </div>

                        <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                            <div className="form-group">
                                <label htmlFor="frr">FRR</label>
                                <Field name="frr" placeholder="FRR" component={Input}/>
                            </div>
                        </div>

                        <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                            <label htmlFor="discount_factor">Discount Factor</label>
                            <Field name="discount_factor" placeholder="Discount Factor" component={Input}/>
                        </div>

                        <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                            <div className="form-group">
                                <label htmlFor="fire_core_id">Core</label>
                                <Field
                                    name="fire_core_id"
                                    placeholder="Core"
                                    component={DropdownInput}
                                    options={fireCoreSelectOptions}
                                    as="select"
                                ></Field>
                            </div>
                        </div>
                    </div>

                    {/* Row 4 */}
                    <div className="form-row">
                        <div className="col-6 col-sm-6 col-md-4 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="skin_code_id">Skin Type</label>
                                <Field
                                    name="skin_code_id"
                                    placeholder="Skin Type"
                                    component={DropdownInput}
                                    options={skinCodesSelectOptions}
                                    as="select"
                                ></Field>
                            </div>
                        </div>

                        <div className="col-6 col-sm-6 col-md-4 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="extra_skin_id">Extra Skin</label>
                                <Field
                                    name="extra_skin_id"
                                    placeholder="Extra Skin"
                                    component={DropdownInput}
                                    options={fireSkinsSelectOptions}
                                    as="select"
                                ></Field>
                            </div>
                        </div>

                        <div className="col-6 col-sm-6 col-md-4 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="extra_skin_quantity">Extra Skin Qty</label>
                                <Field name="extra_skin_quantity" placeholder="Extra Skin Qty" component={Input}/>
                            </div>
                        </div>
                    </div>
                    {/* Row 5 */}

                    <div className="form-row">
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label htmlFor="fire_door_clashing_strip_id">Clashing Type</label>
                                <Field
                                    name="fire_door_clashing_strip_id"
                                    placeholder="Clashing Type"
                                    component={DropdownInput}
                                    options={fireDoorClashingStripsSelectOptions}
                                    as="select"
                                ></Field>
                            </div>
                        </div>

                        <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label htmlFor="fire_meeting_stile_id">Meeting Stile</label>
                                <Field
                                    name="fire_meeting_stile_id"
                                    placeholder="Meeting Stile"
                                    component={DropdownInput}
                                    options={fireMeetingStilesSelectOptions}
                                    as="select"
                                ></Field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Card 2 : Extra Machining */}
        </div>
    );
}
