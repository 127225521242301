import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useExpanded, useGroupBy, useSortBy, useTable } from 'react-table';

import LoadingOverlay from 'components/LoadingOverlay';

const defaultPropGetter = () => ({});

function IndexTable({
  columns,
  status,
  data = [],
  rowProps = () => ({}),
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  groupRowMetadata = {},
  onDataUpdate,
  initialState = {},
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { groupBy, expanded },
  } = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      autoResetExpanded: false,
      onDataUpdate,
      initialState: initialState,
    },
    useGroupBy,
    useSortBy,
    useExpanded
  );

  const isLoading = status === 'loading';

  function getSubrowCount(headerRow) {
    if (typeof groupRowMetadata[headerRow.groupByVal] !== 'undefined') {
      return groupRowMetadata[headerRow.groupByVal].count;
    } else {
      return headerRow.subRows.length;
    }
  }

  return (
    <div className="containar">
      <div className="content-body">
        <LoadingOverlay isLoading={isLoading} />
        <table {...getTableProps()} className={`table table-fixed table-hover ${isLoading ? 'loading-content' : ''}`}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps([
                      {
                        className: column.className,
                        style: column.style,
                      },
                      getColumnProps(column),
                      getHeaderProps(column),
                      column.getSortByToggleProps(),
                    ])}
                  >
                    {column.render('Header')}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon icon={['fas', 'sort-down']} />
                      ) : (
                        <FontAwesomeIcon icon={['fas', 'sort-up']} />
                      )
                    ) : (
                      ''
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps(rowProps(row))}>
                  {row.cells.map(cell => {
                    return (
                      <>
                        <td
                          {...cell.getCellProps([
                            {
                              className: cell.column.className,
                              style: cell.column.style,
                            },
                            getColumnProps(cell.column),
                            getCellProps(cell),
                          ])}
                        >
                          {cell.isGrouped ? (
                            <>
                              {cell.render('Cell')} ({getSubrowCount(row)}){' '}
                              <span {...row.getToggleRowExpandedProps()}>
                                {row.isExpanded ? (
                                  <span className="material-icons-outlined">expand_more</span>
                                ) : (
                                  <span className="material-icons-outlined">chevron_right</span>
                                )}
                              </span>{' '}
                            </>
                          ) : cell.isAggregated ? (
                            // If the cell is aggregated, use the Aggregated
                            // renderer for cell
                            cell.render('Aggregated')
                          ) : cell.isPlaceholder ? null : (
                            cell.render('Cell')
                          )}
                        </td>
                      </>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default IndexTable;
