import React from "react";
import Quotes from "pages/Quotes";

function DispatchedQuotes() {
  return (
    <>
      <Quotes prefilter="dispatch" />
    </>
  );
}

export default DispatchedQuotes;
