import React from 'react';
import {toast} from 'react-toastify';
import {Link, useHistory} from 'react-router-dom';

import DoorExtraForm from 'components/DoorExtraForm';
import FormHeader from 'components/FormHeader';
import {createDoorExtra} from 'utils/horizon-api-client';
import {useDoorExtraPolicy} from 'policies/useDoorExtraPolicy';

function NewDoorExtra() {
    const history = useHistory();
    const {canCreate} = useDoorExtraPolicy();

    const initialValues = {
        code: '',
        cost: 0.0,
        sell: 0.0,
        full_description: '',
        supplier: '',
    };

    async function onSubmit(values, actions) {
        const response = await createDoorExtra({
            values: values,
        });

        actions.setErrors(response.data.errors);
        sendNotification(response);

        if (response.status === 200) {
            // Needs to be called twice to clear form persistence.
            // Bug in formik-persist?
            actions.resetForm();
            actions.resetForm();
            redirectToExtrasPage();
        }
    }

    function redirectToExtrasPage() {
        history.push(`/door-extras`);
    }

    function sendNotification(response) {
        switch (response.status) {
            case 200:
                toast.success('Extra successfully saved!');
                break;
            case 403:
                toast.error('You do not have permissions to create a new extra. Please contact an Admin');
                break;
            case 422:
                toast.error('Error saving extra.');
                break;
            case 500:
                toast.error('Internal server error.');
                break;
        }
    }

    return (
        <>
            <DoorExtraForm
                onSubmit={onSubmit}
                initialValues={initialValues}
                isLoading={false}
                shouldPersist={true}
                formName="new-door-extra-form"
                header={
                    <FormHeader
                        title={
                            <>
                                <Link to="/door-extras">
                                    <span className="material-icons-outlined">arrow_back</span>
                                </Link>
                                New Door Extra
                            </>
                        }
                        canCreate={canCreate}
                    />
                }
            />
        </>
    );
}

export default NewDoorExtra;
