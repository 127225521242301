import { useMemo } from 'react';
import { useAuth } from 'contexts/auth';

export function useQuotePolicy({ quote }) {
  const { userRole } = useAuth();

  const canCreate = useMemo(() => {
    return typeof userRole !== 'undefined';
  }, [userRole]);

  const permittedAttributesForUpdate = useMemo(() => {
    if (quote.status === 'sent') {
      return ['internal_notes'];
    } else {
      var fields = [
        'tagged',
        'customer_id',
        'job_id',
        'order_no',
        'public_notes',
        'internal_notes',
        'quote_date',
        'valid_til',
      ];

      if (!((quote.status === 'draft' || quote.status === 'cancelled') && quote.is_expired)) {
        fields.push('status');
      }

      if (userRole === 'factory' || userRole === 'admin') {
        fields.push('core_date');
        fields.push('added_value_date');
        fields.push('painting_date');
        fields.push('press_date');
        fields.push('dlo_date');
        fields.push('edd_date');
      }

      if (quote.reprint_on == null) {
        fields.push('reprint_on');
      }

      return fields;
    }
  }, [userRole, quote]);

  const permittedAttributesForCreate = useMemo(() => {
    const arr = [
      'status',
      'tagged',
      'customer_id',
      'job_id',
      'order_no',
      'public_notes',
      'internal_notes',
      'quote_date',
      'reprint_on',
      'valid_til',
    ];

    if (userRole === 'factory' || userRole === 'admin') {
      arr.push('core_date');
      arr.push('added_value_date');
      arr.push('painting_date');
      arr.push('press_date');
      arr.push('dlo_date');
      arr.push('edd_date');
    }
    return arr;
  }, [userRole, quote]);

  return {
    canCreate,
    permittedAttributesForUpdate,
    permittedAttributesForCreate,
  };
}
