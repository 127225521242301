import React from "react";
import Quotes from "pages/Quotes";

function OrderedQuotes() {
  return (
    <>
      <Quotes prefilter="orders" />
    </>
  );
}

export default OrderedQuotes;
