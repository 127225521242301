import React from 'react';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';

import FireDoorForm from 'components/DoorForm/Fire/FireDoorForm';
import DoorFormHeaderNav from 'components/DoorForm/DoorFormHeaderNav';
import { createFireDoor } from 'utils/horizon-api-client';

/**
 * @returns {JSX.Element}
 * @constructor
 */
export default function NewFireDoor() {
  const history = useHistory();
  const { id } = useParams();

  const initialValues = {
    height: 0,
    width: 0,
    width_pair: 0,
    thickness: 0,
    reference: '',
    notes: '',
  };

  async function onSubmit(values, actions) {
    const response = await createFireDoor({
      quoteId: id,
      values: values,
    });

    actions.setErrors(response.data.errors);
    sendNotification(response);

    if (response.status === 200) {
      // Needs to be called twice to clear form persistence.
      // Bug in formik-persist?
      actions.resetForm();
      actions.resetForm();

      redirectToDoorEditPage(response.data.id);
    }
  }

  function redirectToDoorEditPage(doorId) {
    history.push(`/fire_doors/${doorId}/edit`);
  }

  function sendNotification(response) {
    if (response.status === 200) {
      toast.success('Door successfully saved!');
    } else if (response.status === 403) {
      toast.error('You do not have permissions to create a door. Please contact an Admin');
    } else if (response.status === 422) {
      toast.error('Error saving door.');
    } else if (response.status === 500) {
      toast.error('Internal server error.');
    }
  }

  return (
    <>
      <FireDoorForm
        onSubmit={onSubmit}
        headerRightNav={<DoorFormHeaderNav isSaving={false} handleSave={onSubmit} />}
        quoteId={id}
        initialValues={initialValues}
        isLoading={false}
        shouldPersist={true}
        formName={`quote-${id}-new-fire-door-form`}
      />
    </>
  );
}
