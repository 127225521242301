import React from 'react';
import { Link } from 'react-router-dom';

import DropdownMenu from 'components/DropdownMenu';
import DuplicateQuote from 'components/QuotesList/DuplicateQuote';

function QuoteUtilities({ quote }) {
  return (
    <>
      <DropdownMenu resourceId={quote.id}>
        <Link to={`/quotes/${quote.id}/edit`} className="dropdown-item">
          Edit Quote {quote.quote_no}
        </Link>
        <DuplicateQuote resourceId={quote.id} />
      </DropdownMenu>
    </>
  );
}

export default QuoteUtilities;
