import React, { useMemo } from "react";
import Tooltip from "components/Tooltip";
import "assets/stylesheets/searchTooltip.css";

import { capitalize, find } from "lodash";

// Take the searchHelperOptions (assigned in data fetch hooks such as useJobs)
// and generate some human readable descriptions for each
function SearchTooltip({ searchHelperOptions = [] }) {
  const defaultSearchTooltip = useMemo(() => {
    const defaultOption = find(
      searchHelperOptions,
      (opt) => opt.optionKey === "other"
    );
    return (
      <div>
        <u>Default Search (with no options)</u>
        <br />
        <strong>{formatTerm(defaultOption.name)}</strong>
        <span>
          {" "}
          <i>{predicateToHumanName(defaultOption.predicate)}</i>{" "}
        </span>
        <span>value</span>
        <br />
      </div>
    );
  }, [searchHelperOptions]);

  function optionDescription(opt) {
    if (typeof opt.description !== "undefined" && opt.description.length > 0) {
      return <strong> {opt.description} </strong>;
    } else {
      return (
        <>
          <strong>{formatTerm(opt.name)}</strong>
          <span>
            {" "}
            <i>{predicateToHumanName(opt.predicate)}</i>{" "}
          </span>
          <span>
            <strong>value</strong>
          </span>
        </>
      );
    }
  }

  const searchTooltips = useMemo(() => {
    const options = searchHelperOptions.filter(
      (option) => option.optionKey !== "other"
    );

    return (
      <>
        {options.map((opt) => {
          const description = optionDescription(opt);

          return (
            <div>
              <hr />
              <u>{opt.displayName}</u>{" "}
              <span>
                '<i>{opt.defaultSearchTerm}value</i>'
              </span>
              <br />
              {description}
              <br />
              <span>Also Accepts: {aliases(opt.aliases)} </span>
            </div>
          );
        })}
      </>
    );
  }, [searchHelperOptions]);

  function predicateToHumanName(predicateStr) {
    switch (predicateStr) {
      case "eq":
        return "equals";
      case "cont_any":
        return "contains";
      case "cont":
        return "contains";
      case "gt":
        return "is greater than";
      case "lt":
        return "is less than";
      case "gteq":
        return "is greater than or equal to";
      case "lteq":
        return "is less than or equal to";
    }
  }

  function aliases(aliasArr) {
    return <i>{aliasArr.join(" | ")}</i>;
  }

  function formatTerm(str) {
    return str
      .replaceAll("_", " ")
      .replaceAll(":", "")
      .split(" ")
      .map((str) => capitalize(str))
      .join(" ");
  }

  return (
    <div id="search-tooltip" className="d-flex">
      <div className="justify-content-center align-self-center">
        <Tooltip
          text={
            <div>
              {defaultSearchTooltip}
              {searchTooltips}
            </div>
          }
        />
      </div>
    </div>
  );
}

export default SearchTooltip;
