import React from "react";

function QuoteStatusButton({ status }) {
  let buttonClass;

  switch (status) {
    case "draft":
      buttonClass = "btn-pending";
      break;
    case "ready_for_dispatch":
      buttonClass = "btn-active";
      break;
    case "ordered":
      buttonClass = "btn-active";
      break;
    case "sent":
      buttonClass = "btn-active";
      break;
    case "dispatched":
      buttonClass = "btn-active";
      break;
    case "invoiced":
      buttonClass = "btn-active";
      break;
    case "cancelled":
      buttonClass = "btn-danger";
      break;
  }

  return <a className={"table-column-btn " + buttonClass}>{status}</a>;
}

export default QuoteStatusButton;
