import React from "react";
import { Link } from "react-router-dom";
import { find } from "lodash";
import { getCustomers } from "utils/horizon-api-client";
import { useInfiniteIndexData } from "hooks/useInfiniteIndexData";

export function useCustomers() {
  const searchOptions = {
    id: {
      name: "id",
      aliases: ["id"],
      predicate: "eq",
      displayName: "ID",
      defaultSearchTerm: "id:",
    },
    company_name: {
      name: "company_name",
      aliases: ["company", "name", "company_name", "company name"],
      predicate: "cont_any",
      displayName: "Company Name",
      defaultSearchTerm: "company_name:",
    },
    email: {
      name: "contacts_email",
      description: "Contacts email contains value",
      aliases: [
        "primary_email",
        "primary email",
        "account email",
        "account_email",
        "email",
        "mail",
      ],
      predicate: "cont_any",
      displayName: "Email",
      defaultSearchTerm: "email:",
    },
    contact_name: {
      name: "contacts_name",
      description: "Contacts name contains value",
      aliases: ["contact_name", "name"],
      predicate: "cont_any",
      displayName: "Contact Name",
      defaultSearchTerm: "contact_name:",
    },
    other: {
      name: "company_name",
      aliases: [],
      predicate: "cont_any",
    },
  };

  const {
    fetchMore,
    canFetchMore,
    recordData,
    totalCount,
    searchFieldValue,
    setSearchTerm,
    status,
  } = useInfiniteIndexData({
    perPage: 50,
    requestData: getCustomers,
    searchOptions: searchOptions,
    mapRecordToTable: mapCustomerToTable,
  });

  function mapCustomerToTable(customer) {
    const contacts = customer.contacts;
    const primaryContact = find(
      contacts,
      (contact) => contact.kind === "primary"
    );
    const primaryEmail = primaryContact ? primaryContact.email : "";

    const accountContact = find(
      contacts,
      (contact) => contact.kind === "account"
    );
    const accountEmail = accountContact ? accountContact.email : "";

    return {
      id: customer.id,
      company_name: customer.company_name,
      primary_email: primaryEmail,
      account_email: accountEmail,
      extras: (
        <Link to={`/customers/${customer.id}/edit`}>
          <span className="material-icons icon-grey">edit</span>
        </Link>
      ),
    };
  }

  return {
    fetchMore: fetchMore,
    canFetchMore: canFetchMore,
    customers: recordData,
    totalCustomers: totalCount,
    searchFieldValue: searchFieldValue,
    setSearchTerm: setSearchTerm,
    searchOptions: searchOptions,
    status: status,
  };
}
