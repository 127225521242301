import React, {useMemo} from 'react';
import {find} from 'lodash';
import {getDoors} from 'utils/horizon-api-client';
import {useIndexData} from 'hooks/useIndexData';
import {useDoorConfigOptions} from 'hooks/useDoorConfigOptions';
import {searchOptions} from 'utils/base-door-search-options';

import DoorUtilities from 'components/DoorsList/DoorUtilities';

export function useDoors({quoteId = null, additionalSearchOptions = {}}) {
    const {coreTypes, meetingStiles, clashingTimbers, skinCodes, doorJambTimbers} = useDoorConfigOptions();
    
    const shouldFetchDoors = useMemo(() => {
        return coreTypes.length > 0 && skinCodes.length > 0;
    }, [coreTypes, meetingStiles, clashingTimbers, skinCodes]);

    const {refetch, recordData, totalCount, searchFieldValue, setSearchTerm, status} = useIndexData({
        requestData: getDoors,
        requestParams: {quoteId},
        searchOptions: {...additionalSearchOptions, ...searchOptions},
        mapRecordToTable: mapDoorToTable,
        isEnabled: shouldFetchDoors,
    });

    function mapDoorToTable(door) {
        const skinCode = find(skinCodes, skinCode => door.skin_code_id === skinCode.value);
        const coreType = find(coreTypes, core => door.core_type_id === core.value);
        const meetingStile = find(meetingStiles, stile => door.meeting_stile_id === stile.value);
        const clashing = find(clashingTimbers, timber => door.clashing_timber_id === timber.value);
        const clashingDetail = `E${door.clashing_1_thickness}/${door.clashing_2_thickness} 
      ${meetingStile ? meetingStile.shortcode : ''}
      ${clashing ? clashing.shortcode : ''}
    `;
        const jambType = find(doorJambTimbers, djt => {
            return door.prehang && door.prehang.door_jamb_timber_id === djt.value;
        });

        const vpCount = door.vision_panels.length;
        const rgCount = door.resorcinol_glues.length;
        const grCount = door.grille_openings.length;
        const deCount = door.door_door_extras.length;
        const peCount = door.door_prehang_extras.length;

        let attachedItemCounts = "";
        if (vpCount) {
            attachedItemCounts = attachedItemCounts.concat(`VP:${vpCount} `)
        }
        if (rgCount) {
            attachedItemCounts = attachedItemCounts.concat(`RG:${rgCount} `)
        }
        if (grCount) {
            attachedItemCounts = attachedItemCounts.concat(`GR:${grCount} `)
        }
        if (deCount) {
            attachedItemCounts = attachedItemCounts.concat(`Ex:${deCount} `)
        }
        if (peCount) {
            attachedItemCounts = attachedItemCounts.concat(`PEx:${peCount}`)
        }

        return {
            id: door.id,
            door_class: 'Standard',
            subRows: door.children.map(child => mapDoorToTable(child)),
            quote_id: door.quote_id,
            door_type: door.door_type,
            leaf_type: door.door_type,
            position: door.position,
            height: door.height,
            width: door.width,
            width_pair: door.width_pair,
            jamb_type: (jambType && jambType.label) || '',
            thickness: door.thickness,
            reference: door.reference,
            parent_id: door.parent_id,
            skin_shortcode: skinCode ? skinCode.shortcode : '',
            core_shortcode: coreType ? coreType.shortcode : '',
            clashing_detail: clashingDetail,
            serial: door.serial,
            sell_price_with_phang: (Number(door.sell_price) + Number(door.phang_sell_price)).toFixed(2) || 0,
            description: 'Standard Door',
            attached_item_counts: attachedItemCounts,
            dropdown: <DoorUtilities door={{door_class: 'Standard', ...door}}/>,
        };
    }

    return {
        doors: recordData,
        totalDoors: totalCount,
        searchFieldValue: searchFieldValue,
        setSearchTerm: setSearchTerm,
        searchOptions: searchOptions,
        status: status,
        refetch,
    };
}
