import React, {useCallback, useMemo} from 'react';
import Header from 'components/Header';
import TableHeader from 'components/TableHeader';
import IndexTable from 'components/IndexTable';
import IndexTableWrapper from 'components/IndexTableWrapper';

import {getQuotes} from 'utils/horizon-api-client';
import {useQuotes} from 'hooks/useQuotes';
import {useSearchHelperOptions} from 'hooks/useSearchHelperOptions';
import {sortByLastUpdatedFunc} from '../utils/table-utils';

function Quotes({prefilter = '', initialTableState = {}}) {
    const {
        fetchMore,
        canFetchMore,
        quotes,
        status,
        searchOptions,
        totalQuotes,
        setSearchTerm,
        searchFieldValue,
    } = useQuotes({requestData: getQuotes, prefilter});
    const {searchHelperOptions} = useSearchHelperOptions({searchOptions});
    const sortOnLastUpdated = useCallback(sortByLastUpdatedFunc, []);

    const sortOnStatus = useCallback((rowA, rowB, columnId) => {
        const rowAValue = rowA.values.status.props.status;
        const rowBValue = rowB.values.status.props.status;
        if (rowAValue > rowBValue) return 1;
        if (rowAValue < rowBValue) return -1;
        return 0;
    }, []);

    const columns = useMemo(() => {
        const arr = [
            {
                Header: 'Quote No ',
                accessor: 'quote_no',
            },
            {
                Header: 'Customer Name ',
                accessor: 'customer_name',
            },
            {
                Header: 'Total Doors ',
                accessor: 'total_doors',
                className: 'v-sm-visible',
            },
            {
                Header: 'Total Sale Price ',
                accessor: 'total_sale_price',
                className: 'v-sm-visible',
            },
            {
                Header: 'Sales Person ',
                accessor: 'sales_person',
                className: 'v-sm-visible',
            },
            {
                Header: 'Job ',
                accessor: 'job_link',
                className: 'v-sm-visible',
            },
            {
                Header: 'Status ',
                accessor: 'status',
                className: 'v-sm-visible',
                sortType: sortOnStatus,
            },
        ];

        if (prefilter === 'orders') {
            [
                {
                    accessor: 'core_date',
                    Header: 'Core Date ',
                    className: 'v-md-visible',
                },
                {
                    accessor: 'press_date',
                    Header: 'Press Date ',
                    className: 'v-md-visible',
                },
                {
                    accessor: 'dlo_date',
                    Header: 'DLO Date ',
                    className: 'v-md-visible',
                },
                {
                    accessor: 'painting_date',
                    Header: 'Painting Date ',
                    className: 'v-md-visible',
                },
                {
                    accessor: 'added_value_date',
                    Header: 'AV Date ',
                    className: 'v-md-visible',
                },
                {
                    accessor: 'edd_date',
                    Header: 'EDD Date ',
                    className: 'v-md-visible',
                },
            ].forEach(col => arr.push(col));
        }

        // End columns
        arr.push({
            Header: 'Last Updated ',
            accessor: 'last_updated',
            className: 'v-sm-visible',
            sortType: sortOnLastUpdated,
        });

        arr.push({
            Header: '',
            accessor: 'dropdown',
            disableSortBy: true,
        });

        return arr;
    }, []);

    return (
        <>
            <Header pageTitle="Quotes"/>
            <IndexTableWrapper
                onScrollToBottom={fetchMore}
                canFetchMore={typeof canFetchMore === 'undefined' ? false : canFetchMore}
            >
                <TableHeader
                    setSearchTerm={setSearchTerm}
                    searchFieldValue={searchFieldValue}
                    searchHelperOptions={searchHelperOptions}
                    resourceName="Quote"
                />
                <IndexTable
                    columns={columns}
                    data={quotes}
                    status={status}
                    initialState={{
                        ...initialTableState,
                        ...{
                            sortBy: [
                                {
                                    id: 'last_updated',
                                    desc: true,
                                },
                            ],
                        },
                    }}
                />
                <span className="table-footer-label">
          Showing {quotes.length} of {totalQuotes} quotes
        </span>
            </IndexTableWrapper>
        </>
    );
}

export default Quotes;
