import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { getJob } from 'utils/horizon-api-client';

export function useJob({ id }) {
  const { data, status, refetch } = useQuery(['job', id], fetchJobById);
  const jobData = useMemo(mapJobData, [data]);

  async function fetchJobById(key, id) {
    const response = await getJob({ id: id });
    return response.data;
  }

  function mapJobData() {
    if (typeof data === 'undefined') return {};
    let addressData;

    if (typeof data.address !== 'undefined') {
      addressData = {
        id: data.address.id,
        line_1: data.address.line_1,
        line_2: data.address.line_2,
        city: data.address.city,
        post_code: data.address.post_code,
        coords_x: data.address.coords_x,
        coords_y: data.address.coords_y,
      };
    } else {
      addressData = {
        line_1: '',
        line_2: '',
        city: '',
        post_code: '',
        coords_x: '',
        coords_y: '',
      };
    }

    return {
      name: data.name,
      description: data.description,
      status: data.status,
      required_before: data.required_before,
      address: addressData,
      contact_name: data.contact_name,
      contact_email: data.contact_email,
      contact_phone: data.contact_phone,
    };
  }

  return {
    data: jobData,
    status,
    refetch,
  };
}
