export const newZealandCities = [
  { value: "auckland", label: "Auckland" },
  { value: "wellington", label: "Wellington" },
  { value: "christchurch city", label: "Christchurch City" },
  { value: "waitakere", label: "Waitakere" },
  { value: "northcote", label: "Northcote" },
  { value: "hamilton", label: "Hamilton" },
  { value: "tauranga", label: "Tauranga" },
  { value: "dunedin", label: "Dunedin" },
  { value: "lower hutt", label: "Lower Hutt" },
  { value: "palmerston north", label: "Palmerston North" },
  { value: "nelson", label: "Nelson" },
  { value: "napier", label: "Napier" },
  { value: "porirua pa", label: "Porirua Pa" },
  { value: "new plymouth", label: "New Plymouth" },
  { value: "invercargill", label: "Invercargill" },
  { value: "wanganui", label: "Wanganui" },
  { value: "gisborne", label: "Gisborne" },
  { value: "timaru", label: "Timaru" },
  { value: "pukekohe east", label: "Pukekohe East" },
  { value: "paraparaumu beach", label: "Paraparaumu Beach" },
  { value: "taupo", label: "Taupo" },
  { value: "masterton", label: "Masterton" },
  { value: "levin", label: "Levin" },
  { value: "whakatane", label: "Whakatane" },
  { value: "tokoroa", label: "Tokoroa" },
  { value: "hawera", label: "Hawera" },
  { value: "greymouth", label: "Greymouth" },
  { value: "thames", label: "Thames" },
  { value: "kerikeri", label: "Kerikeri" },
  { value: "wanaka", label: "Wanaka" },
  { value: "westport", label: "Westport" },
  { value: "turangi", label: "Turangi" },
  { value: "hokitika", label: "Hokitika" },
  { value: "waioruarangi", label: "Waioruarangi" },
  { value: "te anau", label: "Te Anau" },
  { value: "waitangi", label: "Waitangi" },
];
