import { useMemo } from 'react';
import { useAuth } from 'contexts/auth';

export function useComponentPolicy() {
  const { userRole } = useAuth();

  const canView = useMemo(() => {
    return userRole === 'admin';
  }, [userRole]);

  const canUpdate = useMemo(() => {
    return userRole === 'admin';
  }, [userRole]);

  const canCreate = useMemo(() => {
    return userRole === 'admin';
  }, [userRole]);

  return {
    canView,
    canCreate,
    canUpdate,
  };
}
