import { useMemo, useState } from 'react';
import hash from 'object-hash';
import { find, split } from 'lodash';

export function useSearch(options) {
  const [searchFieldValue, setSearchTerm] = useState([]);
  const searchFilter = useMemo(generateFilter, [searchFieldValue]);
  const searchKey = useMemo(() => hash(searchFilter), [searchFilter]);

  function generateFilter() {
    if (!searchFieldValue.length) return {};
    return conditions();
  }

  function conditions() {
    const allConditions = {};

    searchFieldValue.forEach(inputValue => {
      const [conditionOpts, value] = getConditionOptsAndValue(inputValue);
      const conditionObj = buildCondition({ conditionOpts, value });

      if (conditionObj.predicate.includes('cont_any')) {
        if (typeof allConditions[conditionObj.name] === 'undefined') {
          allConditions[conditionObj.name] = [conditionObj.value];
        } else {
          allConditions[conditionObj.name].push(conditionObj.value);
        }
      } else {
        allConditions[conditionObj.name] = conditionObj.value;
      }
    });

    return allConditions;
  }

  function buildCondition({ conditionOpts, value }) {
    if (typeof conditionOpts === 'undefined') return {};
    if (typeof conditionOpts.transformFunc === 'function') {
      value = conditionOpts.transformFunc(value);
    }

    return {
      name: `${conditionOpts.name}_${conditionOpts.predicate}`,
      predicate: conditionOpts.predicate,
      value: value,
    };
  }

  function getConditionOptsAndValue(val) {
    let operator;

    if (val.includes(':')) operator = ':';
    if (val.includes('>')) operator = '>';
    if (val.includes('<')) operator = '<';

    if (typeof operator === 'undefined') return [options.other, val];

    const [suppliedAttributeName, suppliedValue] = split(val, operator);
    const conditionOpts = findMatchingConditionOpts(suppliedAttributeName, operator);

    return [conditionOpts, suppliedValue];
  }

  function findMatchingConditionOpts(suppliedAttributeName, operator) {
    return find(options, o => {
      if (operator === ':') {
        return o.aliases.includes(suppliedAttributeName);
      } else {
        let matchPredicate;
        operator === '>' ? (matchPredicate = 'gt') : (matchPredicate = 'lt');
        return o.aliases.includes(suppliedAttributeName) && o.predicate.includes(matchPredicate);
      }
    });
  }

  return {
    searchFieldValue: searchFieldValue,
    setSearchTerm: setSearchTerm,
    searchFilter: searchFilter,
    searchKey: searchKey,
  };
}
