import React from "react";
import { useFormikContext } from "formik";

function SaveButton({ isSaving, handleSave }) {
  const { submitForm } = useFormikContext();

  function onSubmit(e) {
    e.preventDefault();
    if (!isSaving) submitForm();
  }

  return (
    <>
      <li className="nav-item">
        <a href="" className="nav-btn btn-fill" onClick={onSubmit}>
          {!isSaving ? (
            <>
              <span className="material-icons-outlined">save</span>
              <span>Save</span>
            </>
          ) : (
            <>
              <span className="material-icons-outlined">cloud_upload</span>
              <span>Submitting..</span>
            </>
          )}
        </a>
      </li>
    </>
  );
}

export default SaveButton;
