import React from 'react';
import { Link } from 'react-router-dom';
import { getJobs } from 'utils/horizon-api-client';
import { useInfiniteIndexData } from 'hooks/useInfiniteIndexData';

export function useJobs() {
  const searchOptions = {
    status: {
      name: 'status',
      aliases: ['st', 'stat', 'status'],
      predicate: 'cont_any',
      displayName: 'Status',
      defaultSearchTerm: 'status:',
    },
    name: {
      name: 'name',
      aliases: ['name'],
      predicate: 'cont_any',
      displayName: 'Job Name',
      defaultSearchTerm: 'name:',
    },
    description: {
      name: 'description',
      description: 'Description contains value',
      aliases: ['desc', 'description'],
      predicate: 'cont_any',
      displayName: 'Description',
      defaultSearchTerm: 'desc:',
    },
    required_date_gt: {
      name: 'required_before',
      description: 'Required Before date is greater than value',
      aliases: ['date', 'required_date', 'required_before'],
      predicate: 'gteq',
      displayName: 'Required Date (after)',
      defaultSearchTerm: 'date>',
    },
    required_date_lt: {
      name: 'required_before',
      description: 'Required Before date is less than than value',
      aliases: ['date', 'required_date', 'required_before'],
      predicate: 'lteq',
      displayName: 'Required Date (before)',
      defaultSearchTerm: 'date<',
    },
    other: {
      name: 'name_or_address_line_1',
      aliases: [],
      predicate: 'cont_any',
    },
  };

  const {
    fetchMore,
    canFetchMore,
    recordData,
    totalCount,
    searchFieldValue,
    setSearchTerm,
    status,
  } = useInfiniteIndexData({
    perPage: 50,
    requestData: getJobs,
    searchOptions: searchOptions,
    mapRecordToTable: mapJobToTable,
  });

  function mapJobToTable(job) {
    return {
      id: job.id,
      name: job.name,
      description: job.description,
      status: <JobStatusButton status={job.status} />,
      required_before: job.required_before,
      contact_name: job.contact_name,
      contact_email: job.contact_email,
      contact_phone: job.contact_phone,
      address: parseAddress(job),
      extras: (
        <Link to={`/jobs/${job.id}/edit`}>
          <span className="material-icons icon-grey">edit</span>
        </Link>
      ),
    };
  }

  function parseAddress(job) {
    if (typeof job.address === 'undefined') return {};
    return {
      line_1: job.address.line_1,
      line_2: job.address.line_2,
      city: job.address.city,
      post_code: job.address.post_code,
    };
  }

  return {
    fetchMore: fetchMore,
    canFetchMore: canFetchMore,
    jobs: recordData,
    totalJobs: totalCount,
    status: status,
    searchFieldValue: searchFieldValue,
    setSearchTerm: setSearchTerm,
    searchOptions: searchOptions,
  };
}

function JobStatusButton({ status }) {
  let buttonClass;

  switch (status) {
    case 'active':
    buttonClass = 'btn-pending';
      break;
  case 'prospective':
    buttonClass = 'btn-pending';
      break;
  case 'lost':
    buttonClass = 'btn-inactive';
      break;
  case 'completed':
    buttonClass = 'btn-active';
    break;
  }

  return <a className={'table-column-btn ' + buttonClass}>{status}</a>;
}
