import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Persist } from 'formik-persist';

import Input from 'components/Formik/Input';
import LoadingOverlay from 'components/LoadingOverlay';

import { useDoorExtraPolicy } from 'policies/useDoorExtraPolicy';

const DoorExtraSchema = Yup.object().shape({
  code: Yup.string().required('Required'),
  supplier: Yup.string(),
  full_description: Yup.string(),
  cost: Yup.number().required('Required'),
  sell: Yup.number().required('Required'),
});

function DoorExtraForm({ onSubmit, initialValues, isLoading, header, shouldPersist, formName }) {
  const { canCreate } = useDoorExtraPolicy();

  return (
    <Formik
      validationSchema={DoorExtraSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      <div id="content">
        {header}
        {shouldPersist && <Persist name={formName} />}
        <LoadingOverlay isLoading={isLoading} />

        <div className="card card-top">
          <div className="card-header">
            <h2 className="card-heading">Door Extra Details</h2>
          </div>

          <div className="card-body">
            <Form>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="code">Code</label>
                    <Field name="code" placeholder="Code" component={Input} disabled={!canCreate} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="supplier">Supplier</label>
                    <Field name="supplier" component={Input} placeholder="Supplier" disabled={!canCreate} />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="full_description">Description</label>
                    <Field name="full_description" component={Input} placeholder="Description" disabled={!canCreate} />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="cost">Cost</label>
                    <Field name="cost" component={Input} placeholder="Cost" disabled={!canCreate} />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="sell">Sell Price</label>
                    <Field name="sell" component={Input} placeholder="Sell Price" disabled={!canCreate} />
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Formik>
  );
}

export default DoorExtraForm;
