const dollarsToCents = v => v * 100;

export const searchOptions = {
  serial: {
    name: 'serial',
    aliases: ['serial'],
    predicate: 'cont',
    displayName: 'Serial',
    defaultSearchTerm: 'serial:',
  },
  reference: {
    name: 'reference',
    aliases: ['reference', 'ref'],
    predicate: 'cont',
    displayName: 'Reference',
    defaultSearchTerm: 'reference:',
  },
  height: {
    name: 'height',
    aliases: ['height'],
    predicate: 'eq',
    displayName: 'Height (equals)',
    defaultSearchTerm: 'height:',
  },
  height_gt: {
    name: 'height',
    aliases: ['height'],
    predicate: 'gt',
    displayName: 'Height (greater than)',
    defaultSearchTerm: 'height>',
  },
  height_lt: {
    name: 'height',
    aliases: ['height'],
    predicate: 'lt',
    displayName: 'Height (less than)',
    defaultSearchTerm: 'height<',
  },
  width: {
    name: 'width',
    aliases: ['width'],
    predicate: 'eq',
    displayName: 'Width (equals)',
    defaultSearchTerm: 'width:',
  },
  width_gt: {
    name: 'width',
    aliases: ['width'],
    predicate: 'gt',
    displayName: 'Width (greater than)',
    defaultSearchTerm: 'width>',
  },
  width_lt: {
    name: 'width',
    aliases: ['width'],
    predicate: 'lt',
    displayName: 'Width (less than)',
    defaultSearchTerm: 'width<',
  },
  sell_price_cents: {
    name: 'sell_price_cents',
    aliases: ['sell_price_cents', 'price'],
    description: 'Unit Sell Price equals value',
    predicate: 'eq',
    displayName: 'Unit Price (equals)',
    defaultSearchTerm: 'price:',
    transformFunc: dollarsToCents,
  },
  sell_price_cents_gt: {
    name: 'sell_price_cents',
    aliases: ['sell_price_cents', 'price'],
    description: 'Unit Sell Price is greater than value',
    predicate: 'gt',
    displayName: 'Unit Price (greater than)',
    defaultSearchTerm: 'price>',
    transformFunc: dollarsToCents,
  },
  sell_price_cents_lt: {
    name: 'sell_price_cents',
    description: 'Unit Sell Price is less than value',
    aliases: ['sell_price_cents', 'price'],
    predicate: 'lt',
    displayName: 'Unit Price (less than)',
    defaultSearchTerm: 'price<',
    transformFunc: dollarsToCents,
  },
  other: {
    name: 'id',
    aliases: [],
    defaultSearchTerm: 'serial:',
    predicate: 'eq',
  },
};
