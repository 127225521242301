import React from 'react';

import { Field, useFormikContext } from 'formik';
import Input from '../Formik/Input';

function StandaloneItemDetails() {
  const { values, setFieldValue } = useFormikContext();

  return (
    <div className="card card-top card-top-big">
      <div className="card-header">
        <h2 className="card-heading">Misc Sale Details</h2>
        <hr />

        <div className="form-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <div className="form-group">
                <label htmlFor="serial">Serial</label>
                <Field name="serial" placeholder="Serial" component={Input} disabled />
              </div>
            </div>
            <div className="form-row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="form-group">
                  <label htmlFor="reference">Reference</label>
                  <Field name="reference" placeholder="Reference" component={Input} />
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="form-group">
                  <label htmlFor="contribution_factor">Contribution Factor</label>
                  <Field name="contribution_factor" placeholder="Contribution Factor" component={Input} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="form-group">
              <label htmlFor="reference">Notes</label>
              <Field name="notes" cols="30" rows="4" placeholder="Item Notes" className="form-control" as="textarea" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StandaloneItemDetails;
