import React, {useEffect, useMemo, useState} from 'react';
import {Form, Formik} from 'formik';
import {Persist} from 'formik-persist';
import {useHistory} from 'react-router-dom';
import * as Yup from 'yup';

import 'assets/stylesheets/doorForm.css';

import QuoteFormHeader from 'components/QuoteForm/QuoteFormHeader';

import LoadingOverlay from 'components/LoadingOverlay';

import DoorDetails from 'components/DoorForm/Standard/DoorDetails';
import PrehangSection from 'components/DoorForm/PrehangSection';
import VisionPanelsList from 'components/DoorForm/VisionPanelsList';
import GrilleOpeningsList from 'components/DoorForm/Standard/GrilleOpeningsList';
import ResorcinolGluesList from 'components/DoorForm/Standard/ResorcinolGluesList';
import DoorExtrasList from 'components/DoorForm/DoorExtrasList';
import PrehangExtrasList from 'components/DoorForm/PrehangExtrasList';

import SkipDoorValidation from 'utils/SkipDoorValidation';
import {useQuote} from 'hooks/useQuote';

function buildDoorSchema({prehangActive, hingesNeeded, skipValidations}) {
    if (skipValidations) {
        return Yup.object();
    }

    return Yup.object().shape({
        order_no: Yup.string(),
        door_type: Yup.string().required('Required'),
        height: Yup.number().required('Required').min(300).max(3600),
        width: Yup.number().required('Required').min(200).max(2115),
        width_pair: Yup.number().when('door_type', {
            is: val => ['PR', 'PU'].includes(val),
            then: Yup.number().min(200).max(2115),
            otherwise: Yup.number()
                .oneOf([0, null])
                .nullable()
                .transform((v, ov) => (ov === 0 ? null : Number(ov))),
        }),
        thickness: Yup.number().required('Required').min(30).max(70),
        contribution_factor: Yup.number(),
        assigned_slider_type: Yup.string().nullable(),
        discount_factor: Yup.number(),
        core_type_id: Yup.number().nullable(),
        skin_code_id: Yup.number().nullable(),
        clashing_timber_id: Yup.number().nullable(),
        extra_skin_code_id: Yup.number().nullable(),
        number_of_clashings: Yup.number().when('clashing_timber_id', {
            is: val => !!val,
            then: Yup.number().oneOf([0, 1, 2, 3, 4]),
            otherwise: Yup.number().oneOf([0, 1, 2, 3, 4]).nullable(),
        }),
        clashing_1_thickness: Yup.number().when('number_of_clashings', {
            is: val => [1, 2, 3, 4].includes(val),
            then: Yup.number().min(1).max(25),
            otherwise: Yup.number(),
        }),
        clashing_2_thickness: Yup.number().when('number_of_clashings', {
            is: val => [1, 2, 3, 4].includes(val),
            then: Yup.number().min(1).max(25),
            otherwise: Yup.number(),
        }),
        meeting_stile_id: Yup.number().nullable(),
        reference: Yup.string().nullable(),
        vision_panel_ids: Yup.array(),
        calculator_override: Yup.object({
            // using test as a workaround because it seems to be the only way to access the parent object
            // https://github.com/jquense/yup/issues/225
            extra_skins_thickness: Yup.number().test('required', 'Required when Extra Skin is chosen', function (val) {
                return this.from[1].value.extra_skin_code_id ? Yup.number().min(0.001).isValidSync(val) : true;
            }),
            extra_skins_part_price: Yup.number().test('required', 'Required when Extra Skin is chosen', function (val) {
                return this.from[1].value.extra_skin_code_id ? Yup.number().min(0.001).isValidSync(val) : true;
            }),
        }),

        // TODO extract Prehang Validation???
        prehang: Yup.object().when('prehangActive', {
            is: v => prehangActive,
            then: Yup.object({
                door_jamb_handing_id: Yup.number().nullable(),
                ph_galv_steel_frame: Yup.boolean().when('prehangActive', {
                    is: v => prehangActive,
                    then: Yup.boolean(),
                    otherwise: Yup.boolean().nullable(),
                }),
                discount_factor_phang: Yup.number(),
                number_of_hinges: Yup.number().when('door_jamb_handing_id', {
                    is: v => prehangActive && hingesNeeded,
                    then: Yup.number().min(2).max(6),
                    otherwise: Yup.number().when('door_jamb_handing_id', {
                        is: v => !prehangActive && hingesNeeded,
                        then: Yup.number().notRequired(),
                        otherwise: Yup.number().min(0).max(0),
                    }),
                }),
                standard_jamb: Yup.boolean().when('prehangActive', {
                    is: v => prehangActive,
                    then: Yup.boolean(),
                    otherwise: Yup.boolean().nullable(),
                }),
                jamb_thickness: Yup.number().when('prehangActive', {
                    is: v => prehangActive,
                    then: Yup.number().min(18).max(60),
                }),
                manual_machining_code: Yup.string().nullable(),
                number_of_sides: Yup.number().when('prehangActive', {
                    is: v => prehangActive,
                    then: Yup.number().min(1).max(4),
                }),
                wall_stud_width: Yup.number().when('prehangActive', {
                    is: v => prehangActive,
                    then: Yup.number().min(50).max(400),
                }),
                hinge_face_gib_lining_thickness: Yup.number(),
                non_hinge_face_gib_lining_thickness: Yup.number(),
            }),
        }),
    });
}

function StandardDoorForm({
                              quoteId,
                              onSubmit,
                              initialValues,
                              isLoading,
                              onVisionPanelDelete,
                              shouldPersist,
                              formName,
                              headerRightNav,
                          }) {
    const history = useHistory();
    const [prehangActive, toggleShowPrehangActive] = useState(false);
    const [hingesNeeded, markHingesNeeded] = useState(true);
    const [skipValidations, markSkipValidations] = useState(false);
    const {data: quoteData} = useQuote({id: quoteId});

    useEffect(() => {
        toggleShowPrehangActive(initialValues.prehang_enabled);
    }, [initialValues.prehang_enabled]);

    const doorSchema = useMemo(
        () =>
            buildDoorSchema({
                prehangActive,
                hingesNeeded,
                skipValidations,
            }),
        [prehangActive, hingesNeeded, skipValidations]
    );

    function handleNavigation(e) {
        e.preventDefault();
        const selected = e.target.dataset.name;
        history.push(`/quotes/${quoteId}/edit?tab=${selected}`);
    }

    return (
        <Formik validationSchema={doorSchema} initialValues={initialValues} onSubmit={onSubmit}
                enableReinitialize={true}>
            <>
                <QuoteFormHeader
                    onSubmit={onSubmit}
                    quoteId={quoteId}
                    onNavigation={handleNavigation}
                    selectedNav={'quoteDoors'}
                    isSaving={false}
                    headerTitle={`Quote No: ${quoteData.quote_no}`}
                    rightNav={headerRightNav}
                />
                <LoadingOverlay isLoading={isLoading}/>
                <Form>
                    {shouldPersist && <Persist name={formName}/>}
                    <SkipDoorValidation markSkipValidations={markSkipValidations}/>
                    <DoorDetails/>

                    <VisionPanelsList onVisionPanelDelete={onVisionPanelDelete}/>
                    <GrilleOpeningsList onDelete={() => {
                    }}/>
                    <ResorcinolGluesList onDelete={() => {
                    }}/>
                    <DoorExtrasList onDelete={() => {
                    }}/>
                    <PrehangSection
                        prehangActive={prehangActive}
                        toggleShowPrehangActive={toggleShowPrehangActive}
                        markHingesNeeded={markHingesNeeded}
                    />
                    <PrehangExtrasList onDelete={() => {
                    }}/>
                </Form>
            </>
        </Formik>
    );
}

export default StandardDoorForm;
