import 'assets/stylesheets/auditLogsTable.css';
import { useAuditLogs } from '../hooks/useAuditLogs';
import { ComponentAuditLogsTable } from '../components/Components/ComponentAuditLogsTable';
import Header from '../components/Header';
import { SubHeader } from '../components/ComponentsList/SubHeader';
import React from 'react';

function ComponentAuditLogs() {
  const { auditLogs, status } = useAuditLogs({ componentId: null });

  return (
    <ComponentAuditLogsTable
      auditLogs={auditLogs}
      status={status}
      header={
        <>
          <Header pageTitle="Component Audit Logs" classes="no-box-shadow" />
          <SubHeader selectedNav={'auditLogs'} />
        </>
      }
    />
  );
}

export default ComponentAuditLogs;
