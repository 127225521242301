import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import QuoteForm from 'components/QuoteForm/QuoteForm';
import NewQuoteHeaderNav from 'components/QuoteForm/NewQuoteHeaderNav';
import { createQuote } from 'utils/horizon-api-client';
import { serializeQuoteForm } from 'utils/quote-form';
import { useAuth } from 'contexts/auth';
import { useQuotePolicy } from 'policies/useQuotePolicy';

function NewQuote() {
  const { user } = useAuth();
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);
  const { permittedAttributesForCreate } = useQuotePolicy({ quote: {} });

  const initialValues = {
    status: 'draft',
    tagged: false,
    customer_id: '',
    files: [],
    job_id: '',
    public_notes: '',
    internal_notes: '',
    valid_til: moment(Date.now() + 60 * 60 * 60 * 24 * 1000).format('MM/DD/YYYY'),
    quote_date: moment(Date.now()).format('MM/DD/YYYY'),
    _salesPerson: user && user.name,
  };

  async function onSubmit(values, actions) {
    setIsSaving(true);
    const formData = new FormData();
    serializeQuoteForm({ formData, values });

    const response = await createQuote({
      values: formData,
    });

    setIsSaving(false);
    actions.setErrors(response.data.errors);
    sendNotification(response);

    if (response.status === 200) {
      // Needs to be called twice to clear form persistence.
      // Bug in formik-persist?
      actions.resetForm();
      actions.resetForm();

      redirectToQuoteEditPage(response.data.id);
    }
  }

  function redirectToQuoteEditPage(quoteId) {
    history.push(`/quotes/${quoteId}/edit`);
  }

  function sendNotification(response) {
    switch (response.status) {
    case 200:
      toast.success('Quote successfully saved!');
        break;
    case 403:
      toast.error('You do not have permissions to create a quote. Please contact an Admin');
        break;
    case 422:
      toast.error('Error saving quote.');
        break;
    case 500:
      toast.error('Internal server error.');
        break;
    }
  }

  return (
    <>
      <QuoteForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        isLoading={false}
        isSaving={isSaving}
        headerTitle="New Quote"
        headerRightNav={<NewQuoteHeaderNav isSaving={isSaving} handleSave={onSubmit} />}
        editableFields={permittedAttributesForCreate}
        shouldPersist={true}
        formName="new-quote-form"
      />
    </>
  );
}

export default NewQuote;
