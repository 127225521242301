import {Field, useFormikContext} from 'formik';
import React, {useEffect, useState} from 'react';
import {find} from 'lodash';
import DropdownInput from '../../Formik/DropdownInput';
import Input from '../../Formik/Input';

export function FireGrilleOpening({dropdownOptions, order}) {
    const {values} = useFormikContext();

    const [prevSelected, setPrevSelected] = useState(null);
    const [cost, setCost] = useState(0);
    const [sell, setSell] = useState(0);
    const [description, setDescription] = useState('');

    useEffect(() => {
        updatePricingState();
    }, [dropdownOptions]);

    /**
     * @param id ID (Primary Key) of the GrilleOpening
     * @returns {*|{}}
     */
    function getPanelData(id) {
        if (id === undefined) return {};
        const data = find(dropdownOptions, panel => panel.value === id);
        return data || {};
    }

    function updatePricingState() {
        setPrevSelected(values[`extra_machining_${order}_id`]);
        const data = getPanelData(values[`extra_machining_${order}_id`]);

        if (data.cost_price_cents !== undefined) {
            if (data.cost_price_cents > 0) {
                setCost(data.cost_price_cents / 100);
            } else {
                setCost(0);
            }

            if (data.sell_price_cents > 0) {
                setSell(data.sell_price_cents / 100);
            } else {
                setSell(0);
            }

            setDescription(data.description);
        } else {
            setCost(0);
            setSell(0);
            setDescription('');
        }
    }

    if (values[`extra_machining_${order}_id`] !== prevSelected) {
        updatePricingState();
    }

    return (
        <div className="form-group-bordered">
            <div className="card-header card-header-mobile d-flex d-sm-none">
                <span className="label">GO {order.toUpperCase()}</span>
            </div>
            <span className="label d-none d-sm-block">Grille {order.toUpperCase()}</span>
            <div className="form-row mt-5 mt-sm-0 align-items-center mt-3">
                <div className="col-md-12 col-lg-12">
                    <div className="form-row mt-0">
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label htmlFor={`extra_machining_${order}_id`}>Code</label>
                                <Field
                                    name={`extra_machining_${order}_id`}
                                    placeholder="Code"
                                    component={DropdownInput}
                                    options={dropdownOptions}
                                    as="select"
                                >
                                    <option value=""></option>
                                </Field>
                            </div>
                        </div>

                        <div className="col-6 col-sm-6 col-md-2 col-lg-2">
                            <div className="form-group">
                                <label htmlFor="qty">Qty</label>
                                <Field name={`extra_machining_${order}_qty`} placeholder="Qty" component={Input}/>
                            </div>
                        </div>

                        <div className="col-6 col-sm-6 col-md-2 col-lg-2">
                            <div className="form-group">
                                <label>Cost</label>
                                <input className={'form-control'} disabled value={`$${cost}`}/>
                            </div>
                        </div>

                        <div className="col-6 col-sm-6 col-md-2 col-lg-2">
                            <div className="form-group">
                                <label>Sell</label>
                                <input className={'form-control'} disabled value={`$${sell}`}/>
                            </div>
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="form-group">
                                <label>Description</label>
                                <input className={'form-control'} disabled value={description}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
