import React, { useState } from "react";
import { toast } from "react-toastify";
import { useFormikContext } from "formik";

import useDebouncedCallback from "hooks/useDebouncedCallback";
import AddressResultsList from "components/JobForm/AddressResultsList";
import {
  getAddressSuggestions,
  getAddressDetails,
} from "utils/addy-api-client";

export function useAddressSuggestions() {
  const { values, setFieldValue } = useFormikContext();
  const [addressSuggestions, setAddressSuggestions] = useState([]);

  const populateSuggestions = useDebouncedCallback(() => {
    getAddressSuggestions({ query: buildSuggestionsQuery() }).then((result) => {
      setAddressSuggestions(result.data.addresses);
    });
  }, 1500);

  function buildSuggestionsQuery() {
    return values.address.line_1;
  }

  function handleFieldChange({ event }) {
    setFieldValue("address.line_1", event.target.value);
    if (shouldGetSuggestions(event.target.value)) {
      populateSuggestions();
    }
  }

  function shouldGetSuggestions(val) {
    return val.length > 3;
  }

  function closeAddressSuggestions() {
    setAddressSuggestions([]);
  }

  async function onSelect(selection) {
    const result = await getAddressDetails({ addressId: selection.id });
    if (result.status === 200) {
      if (isPostboxAddress(result)) {
        postboxFillFields(result);
      } else {
        defaultFillFields(result);
      }
    } else {
      toast.error("Error fetching address details");
    }
    setAddressSuggestions([]);
  }

  function postboxFillFields(result) {
    const city = getCity(result);
    setFieldValue("address.line_1", result.data.address1);
    if (result.data.boxbaglobby !== city) {
      setFieldValue("address.line_2", result.data.boxbaglobby);
    }
    setFieldValue("address.post_code", result.data.postcode);
    setFieldValue("address.city", city);
    setFieldValue("address.coords_x", result.data.x);
    setFieldValue("address.coords_y", result.data.y);
  }

  function defaultFillFields(result) {
    let line2 = result.data.address2;
    const city = getCity(result);
    const postcode = result.data.postcode;
    line2 = line2.replace(city, "");
    line2 = line2.replace(postcode, "");
    line2 = line2.replace("  ", " ");
    line2 = line2.trim();

    setFieldValue("address.line_1", result.data.address1);
    setFieldValue("address.line_2", line2);
    setFieldValue("address.post_code", postcode);
    setFieldValue("address.city", city);
    setFieldValue("address.coords_x", result.data.x);
    setFieldValue("address.coords_y", result.data.y);
  }

  function getCity(result) {
    const possibleCities = [result.data.city, result.data.mailtown];
    const nonBlankCities = possibleCities.filter((v) => {
      return v !== null && v !== "";
    });
    return nonBlankCities.length ? nonBlankCities[0] : "";
  }

  function isPostboxAddress(result) {
    return result.data.type === "PostBox";
  }

  return {
    handleFieldChange,
    addressResultsList: (
      <AddressResultsList
        results={addressSuggestions}
        onSelect={onSelect}
        closeAddressSuggestions={closeAddressSuggestions}
      />
    ),
  };
}
