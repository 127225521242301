import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Persist } from 'formik-persist';

import Input from 'components/Formik/Input';
import LoadingOverlay from 'components/LoadingOverlay';
import { useComponentPolicy } from 'policies/useComponentPolicy';

// #  alt_supplier     :string(255)
// #  cost_price_cents :integer
// #  part_description :string(255)
// #  part_no          :string(255)
// #  perm_temp        :string(255)
// #  prod_class       :string(255)
// #  supplier         :string(255)
// #  uom              :string(255)
// #  created_at       :datetime         not null
// #  updated_at       :datetime         not null

const ComponentSchema = Yup.object().shape({
  part_no: Yup.string().required('Required'),
  part_description: Yup.string().required('Required'),
  supplier: Yup.string(),
  cost_price: Yup.number().required('Required'),
});

function ComponentForm({ onSubmit, initialValues, isLoading, header, shouldPersist, formName }) {
  const { canCreate } = useComponentPolicy();

  return (
    <Formik
      validationSchema={ComponentSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      <div id="content">
        {header}
        {shouldPersist && <Persist name={formName} />}
        <LoadingOverlay isLoading={isLoading} />

        <div className="card card-top">
          <div className="card-header">
            <h2 className="card-heading">Component Details</h2>
          </div>

          <div className="card-body">
            <Form>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="part_no">Part No.</label>
                    <Field name="part_no" placeholder="Part No." component={Input} disabled={!canCreate} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="part_description">Part Description</label>
                    <Field
                      name="part_description"
                      placeholder="Part Description"
                      component={Input}
                      disabled={!canCreate}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="supplier">Supplier</label>
                    <Field name="supplier" placeholder="Supplier" component={Input} disabled={!canCreate} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="cost_price">Cost</label>
                    <Field name="cost_price" placeholder="Cost" component={Input} disabled={!canCreate} />
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Formik>
  );
}

export default ComponentForm;
