import React from 'react';
import { Link } from 'react-router-dom';

import DropdownMenu from 'components/DropdownMenu';
import DestroyDoor from 'components/DoorsList/DestroyDoor';

function DoorDropdownMenu({ door, resourceId, quoteId, setSelectedUtility, isChild, isSlider }) {
  if (typeof door === 'undefined') return null;

  let name, doorClassPrefix;
  switch (door.door_class.toLowerCase()) {
    case 'standard':
    doorClassPrefix = 'doors';
      name = 'Door';
    break;
  case 'tipco':
    doorClassPrefix = 'tipco_doors';
    name = 'Door';
    break;
  case 'fire':
    doorClassPrefix = 'fire_doors';
    name = 'Door';
      break;
  case 'misc':
    doorClassPrefix = 'standalone_items';
    name = 'Item';
    break;
  }

  function doorEditUrl() {
    return `/${doorClassPrefix}/${door.id}/edit`;
  }

  return (
    <>
      <DropdownMenu resourceId={door.id}>
        <Link to={doorEditUrl()} className="dropdown-item">
          Edit {name}
        </Link>
        {!isChild && !isSlider && (
          <Link
            className="dropdown-item"
            onClick={() => {
              setSelectedUtility('duplicateDoor');
            }}
          >
            Duplicate {name}
          </Link>
        )}
        {!isSlider && <DestroyDoor door={door} />}
      </DropdownMenu>
    </>
  );
}

export default DoorDropdownMenu;
