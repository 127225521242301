import React, { useMemo } from "react";
import { useAuth } from "contexts/auth";

export function useJobPolicy() {
  const { userRole } = useAuth();

  const canCreate = useMemo(() => {
    return typeof userRole !== "undefined";
  }, [userRole]);

  return {
    canCreate,
  };
}
