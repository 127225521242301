import React, { useState } from 'react';

function DropdownMenu({ resourceId, children }) {
  const [isMenuOpen, toggleIsMenuOpen] = useState(false);

  const childrenWithExtraProp = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { toggleIsMenuOpen });
    }
  });

  return (
    <>
      <span
        id="dotBtn"
        className={isMenuOpen ? 'hidden material-icons-outlined' : 'material-icons-outlined'}
        onClick={() => toggleIsMenuOpen(!isMenuOpen)}
      >
        more_horiz
      </span>
      <span
        id="cancelBtn"
        className={isMenuOpen ? 'material-icons' : 'hidden material-icons'}
        onClick={() => toggleIsMenuOpen(!isMenuOpen)}
      >
        cancel
      </span>
      <div className="table-menu">
        <div className={isMenuOpen ? 'show dropdown-menu' : 'dropdown-menu'} id="dropmenu">
          {childrenWithExtraProp}
        </div>
      </div>
    </>
  );
}

export default DropdownMenu;
