import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { camelCase } from 'lodash';

import Search from 'components/Search';
import SearchTooltip from 'components/SearchTooltip';
import { SerialDuplication } from './SerialDuplication';
import { SearchDuplication } from './SearchDuplication';

function DoorsTableHeader({
  searchFieldValue,
  setSearchTerm,
  searchHelperOptions,
  resourceName,
  onDataUpdate = () => {},
}) {
  const [isMenuOpen, toggleIsMenuOpen] = useState(false);
  const { id } = useParams();

  return (
    <>
      <div className="content-header" id={`${camelCase(resourceName)}-table-header`}>
        <div className="content-search">
          <div className="input-group d-flex align-items-center">
            <Search
              setSearchTerm={setSearchTerm}
              searchFieldValue={searchFieldValue}
              searchHelperOptions={searchHelperOptions}
            />
          </div>
          <div className="d-flex">
            <SearchTooltip searchHelperOptions={searchHelperOptions} />
          </div>
        </div>

        <div id="new-door-dropmenu" className="table-menu">
          <Link className="btn btn-filled btn-bordered btn-small" to="#" onClick={() => toggleIsMenuOpen(!isMenuOpen)}>
            <span className="material-icons-outlined">add</span> New {resourceName}
          </Link>
          <div className={isMenuOpen ? 'show dropdown-menu' : 'dropdown-menu'} id="dropmenu">
            <Link to={`/quotes/${id}/doors/standard/new`} className="dropdown-item">
              New Standard Door
            </Link>
            <Link to={`/quotes/${id}/doors/tipco/new`} className="dropdown-item">
              New TIPCO Door
            </Link>
            <Link to={`/quotes/${id}/doors/fire/new`} className="dropdown-item">
              New Fire Door
            </Link>
            <Link to={`/quotes/${id}/standalone_items/new`} className="dropdown-item">
              New Misc Sale
            </Link>
            <SerialDuplication
              onClick={() => toggleIsMenuOpen(false)}
              duplicateToQuoteId={id}
              afterDuplication={onDataUpdate}
            />
            <SearchDuplication
              onClick={() => toggleIsMenuOpen(false)}
              duplicateToQuoteId={id}
              afterDuplication={onDataUpdate}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default DoorsTableHeader;
