import React from "react";
import { toast } from "react-toastify";
import { useParams, Link } from "react-router-dom";

import CustomerForm from "components/CustomerForm";
import FormHeader from "components/FormHeader";

import { updateCustomer } from "utils/horizon-api-client";
import { useCustomer } from "hooks/useCustomer";
import { useCustomerPolicy } from "policies/useCustomerPolicy";

function EditCustomer() {
  const { id } = useParams();
  const { data, status, refetch } = useCustomer({ id: id });
  const isLoading = status === "loading";
  const { canCreate } = useCustomerPolicy();

  async function onSubmit(values, actions) {
    const response = await updateCustomer({
      id: id,
      values: values,
    });

    actions.setErrors(response.data.errors);
    sendNotification(response);
  }

  function sendNotification(response) {
    switch (response.status) {
      case 200:
        toast.success("Customer successfully saved!");
        refetch();
        break;
      case 403:
        toast.error(
          "You do not have permissions to update this customer. Please contact an Admin"
        );
        break;
      case 422:
        toast.error("Error saving customer.");
        break;
      case 500:
        toast.error("Internal server error.");
        break;
    }
  }

  return (
    <>
      <CustomerForm
        onSubmit={onSubmit}
        initialValues={data}
        isLoading={isLoading}
        header={
          <FormHeader
            title={
              <>
                <Link to="/customers">
                  <span className="material-icons-outlined">arrow_back</span>
                </Link>
                Edit Customer
              </>
            }
            canCreate={canCreate}
          />
        }
      />
    </>
  );
}

export default EditCustomer;
