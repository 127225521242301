import React, { useMemo } from 'react';
import { InputGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

import IndexTable from 'components/IndexTable';

import 'react-datepicker/dist/react-datepicker.css';
import 'assets/stylesheets/react-datepicker-overrides.css';

import { usePurchaseOrders } from 'hooks/usePurchaseOrders';
import { updatePurchaseOrder } from 'utils/horizon-api-client';
import { toast } from 'react-toastify';

function EditableDateCell({ value: initialValue, row: { original }, column, onDataUpdate }) {
  const [value, setValue] = React.useState(initialValue);

  const onChange = val => {
    setValue(val);
    onDataUpdate({
      rowData: original,
      newValue: val,
      column: column,
    });
  };

  return (
    <>
      <InputGroup id="date-picker-input" className="flex-nowrap">
        <DatePicker
          className={`form-control`}
          selected={(value && new Date(value)) || null}
          onChange={onChange}
          dateFormat={'yyyy-MM-dd'}
        />
        <span className="input-group-append" role="right-icon">
          <button className="btn btn-outline-secondary border-left-0" type="button">
            <i className="material-icons datepicker-icon">event</i>
          </button>
        </span>
      </InputGroup>
    </>
  );
}

function PurchaseOrderTable({ quoteId }) {
  const {
    refetch,
    purchaseOrders,
    totalDoors,
    searchOptions,
    status,
    setSearchTerm,
    searchFieldValue,
  } = usePurchaseOrders({ quoteId });

  const columns = useMemo(
    () => [
      {
        Header: 'ID ',
        accessor: 'id',
        className: 'v-sm-visible',
      },
      {
        Header: 'Supplier ',
        accessor: 'supplier',
        className: 'v-sm-visible',
      },
      {
        Header: 'Number ',
        accessor: 'number',
        className: 'v-sm-visible',
      },
      {
        Header: 'Expected On ',
        Cell: EditableDateCell,
        accessor: 'expected_on',
        className: 'v-sm-visible',
      },
    ],
    []
  );

  async function onDataUpdate({ rowData, newValue, column }) {
    let response;
    if (column.id === 'expected_on') {
      response = await updatePurchaseOrder({
        id: rowData.id,
        expectedOn: newValue,
      });
    }

    if (typeof response.status !== 'undefined') {
      refetch();
      switch (response.status) {
        case 200:
        toast.success('Updated!');
          break;
      case 403:
        toast.error('You do not have permissions to update this PO.');
        break;
      case 500:
        toast.error('Internal server error.');
          break;
      default:
          toast.error('Error updating purchase order');
          break;
      }
    }
  }

  return (
    <>
      <div className="card">
        <div className="card-header content-header">
          <h2 className="card-heading">Purchase Orders</h2>
          <a className="btn btn-filled btn-bordered btn-small" href={`/quotes/${quoteId}/purchase-orders/new`}>
            <span className="material-icons-outlined">add</span> New PO
          </a>
        </div>
        <div className="content-body">
          <IndexTable columns={columns} data={purchaseOrders} status={status} onDataUpdate={onDataUpdate} />
        </div>
      </div>
    </>
  );
}

export default PurchaseOrderTable;
