import { useQuery } from 'react-query';
import { getAllDoorSerials } from '../utils/horizon-api-client';

export function useDoorViews(queries = []) {
  const { data: allDoorSerials, status } = useQuery('allDoorSerials', fetchOptions, {
    enabled: queries.includes('allDoorSerials'),
  });

  async function fetchOptions(key) {
    return await getAllDoorSerials();
  }

  return {
    allDoorSerials: allDoorSerials ? allDoorSerials.data : [],
    status,
  };
}
