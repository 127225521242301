import React, { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import { flatten } from 'lodash';
import { useSearch } from 'hooks/useSearch';

export function useInfiniteIndexData({
  nestedResourceId = null,
  perPage,
  requestData,
  requestParams,
  searchOptions,
  mapRecordToTable,
  prefilter = '',
}) {
  const { searchFilter, searchKey, searchFieldValue, setSearchTerm } = useSearch(searchOptions);

  const { status, data = [], fetchMore, canFetchMore } = useInfiniteQuery(buildFetchCacheKey(), fetchInfiniteData, {
    getFetchMore: (lastGroup, allGroups) => lastGroup.nextPage,
  });
  const recordData = useMemo(allRecords, [data]);
  const totalCount = useMemo(() => {
    if (data.length) {
      return data[0].totalCount;
    }
  }, [data]);

  function buildFetchCacheKey() {
    if (nestedResourceId) {
      return `${requestData.name}-${nestedResourceId}-${searchKey}`;
    } else {
      return `${requestData.name}-${prefilter}-${searchKey}`;
    }
  }

  async function fetchInfiniteData(key, nextPage = 0) {
    nextPage = nextPage + 1;
    const records = await requestData(
      Object.assign({}, requestParams, {
        page: nextPage,
        perPage: perPage,
        filter: searchFilter,
        prefilter,
      })
    );

    const hasNextPage = records.total > perPage * nextPage;
    nextPage = hasNextPage ? nextPage : false;

    return {
      data: records.data,
      totalCount: records.total,
      nextPage: nextPage,
    };
  }

  function allRecords() {
    const arr = [];
    data.forEach(group => {
      group.data.forEach(record => {
        arr.push(mapRecordToTable(record));
      });
    });
    return flatten(arr);
  }

  return {
    fetchMore,
    canFetchMore,
    recordData,
    totalCount,
    searchFieldValue,
    setSearchTerm,
    searchOptions,
    status,
  };
}
