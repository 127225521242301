import React, { useMemo } from "react";
import { Prompt } from "react-router-dom";
import { useFormikContext } from "formik";
import { some } from "lodash";

function PromptIfFileChangesUnsaved({ hasFilesToPurge = false }) {
  const { values, isSubmitting } = useFormikContext();

  const hasUnsavedFileChanges = useMemo(() => {
    return (
      some(values.files, (file) => typeof file.id === "undefined") ||
      hasFilesToPurge
    );
  }, [values.files]);

  return (
    <Prompt
      when={!isSubmitting && hasUnsavedFileChanges}
      message="You have unsaved file changes. Are you sure you want to leave?"
    />
  );
}

export default PromptIfFileChangesUnsaved;
