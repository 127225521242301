import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import { cloneDoor, cloneFireDoor, cloneTipcoDoor } from '../../utils/horizon-api-client';

export function DuplicateButton({ doorType, doorData, duplicateToQuoteId, afterDuplication = () => {} }) {
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const [newDoorId, setNewDoorId] = useState(null);

  async function handleOnClick(e) {
    e.preventDefault();

    if (submitting) return;
    if (newDoorId !== null) {
      switch (doorType.toLowerCase()) {
        case 'standard':
        history.push(`/doors/${newDoorId}/edit`);
        break;
      case 'fire':
        history.push(`/fire_doors/${newDoorId}/edit`);
        break;
      case 'tipco':
        history.push(`/tipco_doors/${newDoorId}/edit`);
        break;
      }

      return;
    }

    const confirmed = window.confirm(
      `This will duplicate Door ${doorData.serial} from Quote ${doorData.quote_id} to Quote ${duplicateToQuoteId}. Continue?`
    );

    if (confirmed) {
      setSubmitting(true);

      let response, newId;
      switch (doorType.toLowerCase()) {
      case 'standard':
          response = await cloneDoor({ id: doorData.id, qty: 1, newQuoteId: duplicateToQuoteId });
        newId = response.data.doors[0].id;
          break;
      case 'fire':
          response = await cloneFireDoor({ id: doorData.id, qty: 1, newQuoteId: duplicateToQuoteId });
        newId = response.data.fire_doors[0].id;
        break;
      case 'tipco':
          response = await cloneTipcoDoor({ id: doorData.id, qty: 1, newQuoteId: duplicateToQuoteId });
        newId = response.data.tipco_doors[0].id;
        break;
      }

      setNewDoorId(newId);
      setSubmitting(false);
      afterDuplication();
    }
  }

  function getBtnColourClass() {
    if (!submitting && !newDoorId) return 'btn-danger';
    if (submitting && !newDoorId) return 'btn-pending';
    if (!submitting && newDoorId) return 'btn-success';
  }

  return (
    <a className={`table-column-btn ${getBtnColourClass()} duplicate-btn`} onClick={handleOnClick}>
      {!submitting && !newDoorId ? 'Duplicate' : ''}
      {submitting && !newDoorId ? 'Working..' : ''}
      {newDoorId ? 'Go To Door' : ''}
    </a>
  );
}
