import React, {useState} from 'react';
import {Link} from 'react-router-dom';

import {destroyDoor, destroyFireDoor, destroyStandaloneItem, destroyTipcoDoor} from 'utils/horizon-api-client';
import {toast} from 'react-toastify';
import {useDoors} from '../../hooks/useDoors';
import {useTipcoDoors} from '../../hooks/useTipcoDoors';
import {useFireDoors} from '../../hooks/useFireDoors';
import {useStandaloneItems} from '../../hooks/useStandaloneItems';

function DestroyDoor({door, toggleIsMenuOpen}) {
    const [loading, setLoading] = useState(false);
    const quoteId = door.quote_id;
    const {refetch: refetchStandard} = useDoors({quoteId});
    const {refetch: refetchTipco} = useTipcoDoors({quoteId});
    const {refetch: refetchFire} = useFireDoors({quoteId});
    const {refetch: refetchStandaloneItems} = useStandaloneItems({quoteId});

    let name;
    switch (door.door_class.toLowerCase()) {
        case 'standard':
            name = 'Door';
            break;
        case 'tipco':
            name = 'Door';
            break;
        case 'fire':
            name = 'Door';
            break;
        case 'misc':
            name = 'Item';
            break;
    }

    async function handleDelete(doorId) {
        let response;
        const confirmed = window.confirm(
            `This will remove door ${door.serial} and all associated duplicates. Continue?`
        );

        if (confirmed) {
            setLoading(true);

            const doorClass = door.door_class.toLowerCase();
            if (doorClass === 'tipco') {
                response = await destroyTipcoDoor({id: door.id});
                await refetchTipco();
            } else if (doorClass === 'standard') {
                response = await destroyDoor({id: door.id});
                await refetchStandard();
            } else if (doorClass === 'fire') {
                response = await destroyFireDoor({id: door.id});
                await refetchFire();
            } else if (doorClass === 'misc') {
                response = await destroyStandaloneItem({id: door.id});
                await refetchStandaloneItems();
            }

            try {
                if (response.status === 200) {
                    toggleIsMenuOpen(false);
                    setLoading(false);
                    // forceRefreshPage();
                } else {
                    toggleIsMenuOpen(false);
                    setLoading(false);
                }
            } catch {
                toast.error('Something went wrong. Please try again.');
                setLoading(false);
            }
        }
    }

    return (
        <Link
            className="dropdown-item"
            onClick={() => {
                handleDelete(door.id);
            }}
        >
            {loading ? 'Deleting...' : `Delete ${name}`}
        </Link>
    );
}

export default DestroyDoor;
