import React, {useCallback, useMemo, useState} from 'react';
import {useQuote} from 'hooks/useQuote';
import {useParams} from 'react-router-dom';

import {exportQuoteReport} from 'utils/horizon-api-client';

import {toast} from 'react-toastify';
import {saveAs} from 'file-saver';

import 'assets/stylesheets/financialSummary.css';

function QuoteFinancialSummary() {
    const {id} = useParams();
    const {data} = useQuote({id: id});
    const [reportsLoading, setReportsLoading] = useState([]);

    const summaryData = useMemo(() => {
        const totalSalePrice = data.total_sale_price || 0;
        const totalCost = data.total_cost || 0;
        const doorQty = data.total_doors || 0;

        return {
            discount: 0,
            grossMargin: totalSalePrice == 0 ? 0 : (100 * ((totalSalePrice - totalCost) / totalSalePrice)).toFixed(2),
            totalSalePrice: totalSalePrice.toFixed(2),
            doorQty: doorQty,
        };
    }, [data]);

    function handleReportsLoading({reportId}) {
        if (reportsLoading.includes(reportId)) {
            return;
        }

        setReportsLoading(prevReports => [...prevReports, reportId]);
    }

    async function exportQuoteAndDownload({fileName, reportId}) {
        handleReportsLoading({reportId});

        let response;
        try {
            response = await exportQuoteReport({id, fileName, reportId});
            const file = new Blob([response.data]);
            saveAs(file, `${fileName}.xlsx`);
        } catch (error) {
            toast.error('Error downloading report');
        } finally {
            removeLoadingReport(reportId);
        }
    }

    async function exportQuoteAndPreview({fileName, reportId}) {
        handleReportsLoading({reportId});

        let response;
        try {
            response = await exportQuoteReport({id, fileName, reportId});
            const file = new Blob([response.data], {type: 'application/pdf'});
            let pdfName = `${id} - ${reportId}`;
            pdfName = pdfName.replace('_', ' ');

            const newWindow = window.open('', pdfName);

            if (newWindow) {
                setTimeout(() => {
                    const dataUrl = window.URL.createObjectURL(file);
                    const title = newWindow.document.createElement('title');
                    const iframe = newWindow.document.createElement('iframe');

                    title.appendChild(document.createTextNode(pdfName));
                    newWindow.document.head.appendChild(title);

                    iframe.setAttribute('src', dataUrl);
                    iframe.setAttribute('width', '100%');
                    iframe.setAttribute('height', '100%');

                    newWindow.document.body.appendChild(iframe);

                    setTimeout(() => {
                        // For Firefox we need to delay revoking the ObjectURL
                        window.URL.revokeObjectURL(dataUrl);
                    }, 100);
                }, 100);
            } else {
                alert('To preview reports, please disable any pop-blockers for this page and try again.');
            }
        } catch (e) {
            switch (e.response.status) {
                case 403:
                    toast.error('Your user role does not have access to this report.');
                    break;
                case 500:
                    toast.error('Error loading report. Make sure all doors in this quote are valid.');
                    break;
                default:
                    toast.error('Error loading report. Make sure all doors in this quote are valid.');
                    break;
            }
        }

        removeLoadingReport(reportId);
    }

    const removeLoadingReport = useCallback(
        reportId => {
            setReportsLoading(prevReports => prevReports.filter(name => name !== reportId));
        },
        [reportsLoading]
    );

    const pdfReports = [
        {
            fileName: `Quote ${id} Overview`,
            displayName: 'Quote Overview',
            reportId: 'overview.pdf',
        },
        {
            fileName: `${id} Production Order`,
            displayName: 'Production Order',
            reportId: 'production_order.pdf',
        },
        {
            fileName: `${id} Order Confirmation`,
            displayName: 'Order Confirmation',
            reportId: 'order_confirmation.pdf',
        },
        {
            fileName: `${id} Cutting List`,
            displayName: 'Cutting List',
            reportId: 'cutting_list.pdf',
        },
    ];

    const xlsxReports = [
        {
            fileName: `Quote ${id} Overview`,
            displayName: 'Quote Overview',
            reportId: 'overview.xlsx',
        },
        {
            fileName: `${id} Production Order`,
            displayName: 'Production Order',
            reportId: 'production_order.xlsx',
        },
        {
            fileName: `${id} Order Confirmation`,
            displayName: 'Order Confirmation',
            reportId: 'order_confirmation.xlsx',
        },
    ];

    return (
        <>
            <div className="card card-top card-top-big">
                <div className="card-body text-card pad-5rem">
                    <div className="row align-items-center" style={{width: '100%'}}>
                        <div className="col-md-3 col-lg-3">
                            <h2>Door Quantity: {summaryData.doorQty}</h2>
                        </div>
                        <div className="col-md-3 col-lg-3">
                            <h2>Gross Margin: {summaryData.grossMargin}%</h2>
                        </div>
                        <div className="col-md-2 col-lg-2">
                            <h2>Discount: {summaryData.discount}%</h2>
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <h1>Total Quote: ${summaryData.totalSalePrice}</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div id="quote-reports-card" className="card">
                <div className="card-header">
                    <h2 className="card-heading">Quote Reports</h2>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-3">
                            <ul className="list-group list-group-flush">
                                {pdfReports.map(report => {
                                    return (
                                        <li
                                            className="list-group-item"
                                            onClick={() =>
                                                exportQuoteAndPreview({
                                                    reportId: report.reportId,
                                                    fileName: report.fileName,
                                                })
                                            }
                                            key={report.reportId}
                                        >
                                            <span
                                                className="material-icons-outlined picture_as_pdf">picture_as_pdf</span>{' '}
                                            <span className="text">
                        {reportsLoading.includes(report.reportId) ? 'Loading...' : `${report.displayName}.pdf`}
                      </span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>

                        <div className="col-12 col-md-6 col-lg-3">
                            <ul className="list-group list-group-flush">
                                {xlsxReports.map(report => {
                                    return (
                                        <li
                                            className="list-group-item"
                                            onClick={() =>
                                                exportQuoteAndDownload({
                                                    reportId: report.reportId,
                                                    fileName: report.fileName,
                                                })
                                            }
                                            key={report.reportId}
                                        >
                                            <span className="material-icons-outlined dataset">dataset</span>{' '}
                                            <span className="text">
                        {reportsLoading.includes(report.reportId) ? 'Loading...' : `${report.displayName}.xlsx`}
                      </span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default QuoteFinancialSummary;
