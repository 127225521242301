import React, { useRef, useState } from 'react';
import TagsInput from 'react-tagsinput';

function Search({
  setSearchTerm,
  searchFieldValue,
  onTagChange = () => {},
  searchHelperOptions = [],
  placeholder = 'Add Filter',
}) {
  const [helperOpen, setHelperOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const tagsInput = useRef();

  function toggleHelperMenu() {
    setHelperOpen(!helperOpen);
  }

  function handleChange(terms) {
    setHelperOpen(false);
    setSearchTerm(terms);
    onTagChange();
  }

  function handleChangeInput(e) {
    setHelperOpen(false);
    setInputValue(e);
  }

  function addInputHelp(e) {
    setHelperOpen(false);
    setInputValue(e.target.getAttribute('searchValue'));
    tagsInput.current.focus();
  }

  return (
    <div className="input-group">
      <TagsInput
        className="search-input"
        value={searchFieldValue}
        onChange={handleChange}
        inputProps={{ ref: tagsInput, placeholder }}
        inputValue={inputValue}
        onChangeInput={handleChangeInput}
      />
      <span onClick={toggleHelperMenu} className="material-icons-outlined search-icon">
        search
      </span>

      <div className={helperOpen ? 'show dropdown-menu search-menu' : 'dropdown-menu search-menu'} id="dropmenu">
        {searchHelperOptions
          .filter(opt => typeof opt.displayName !== 'undefined')
          .map(helper => {
            return (
              <a
                className="dropdown-item"
                key={helper.defaultSearchTerm}
                onClick={addInputHelp}
                searchvalue={helper.defaultSearchTerm}
              >
                {helper.displayName}
              </a>
            );
          })}
      </div>
    </div>
  );
}

export default Search;
