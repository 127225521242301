import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Persist } from 'formik-persist';

import Input from 'components/Formik/Input';
import AddressFields from 'components/CustomerForm/AddressFields';
import LoadingOverlay from 'components/LoadingOverlay';
import { useCustomerPolicy } from 'policies/useCustomerPolicy';

const CustomerSchema = Yup.object().shape({
  company_name: Yup.string().required('Required'),
  contacts: Yup.array().of(
    Yup.object().shape({
      email: Yup.string().email('Must be a valid email'),
      phone: Yup.string(),
    })
  ),
  address: Yup.object().shape({
    line_1: Yup.string(),
    line_2: Yup.string(),
    city: Yup.string().required(),
    post_code: Yup.string(),
  }),
});

function CustomerForm({ onSubmit, initialValues, isLoading, header, shouldPersist, formName }) {
  const { canCreate } = useCustomerPolicy();

  return (
    <Formik
      validationSchema={CustomerSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      <div id="content">
        {header}
        {shouldPersist && <Persist name={formName} />}
        <LoadingOverlay isLoading={isLoading} />

        <div className="card card-top">
          <div className="card-header">
            <h2 className="card-heading">Customer Details</h2>
          </div>

          <div className="card-body">
            <Form>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="company_name">Company Name</label>
                    <Field name="company_name" placeholder="Company Name" component={Input} disabled={!canCreate} />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="status">Status</label>
                    <div className="select-box">
                      <Field name="status" disabled={!canCreate} as="select">
                        <option>active</option>
                        <option>inactive</option>
                      </Field>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4 mt-md-3">
                  <div className="form-group">
                    <label htmlFor="contacts[0].name">Primary Contact Name</label>
                    <Field
                      name="contacts[0].name"
                      component={Input}
                      placeholder="Primary Contact Name"
                      disabled={!canCreate}
                    />
                  </div>
                </div>

                <div className="col-md-4 mt-md-3">
                  <div className="form-group">
                    <label htmlFor="contacts[0].email">Primary Contact Email Address</label>
                    <Field
                      name="contacts[0].email"
                      component={Input}
                      placeholder="Primary Contact Email Address"
                      disabled={!canCreate}
                    />
                  </div>
                </div>

                <div className="col-md-4 mt-md-3">
                  <div className="form-group">
                    <label htmlFor="contacts[0].phone">Primary Contact Phone</label>
                    <Field
                      name="contacts[0].phone"
                      component={Input}
                      placeholder="Primary Contact Phone"
                      disabled={!canCreate}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4 mt-md-3">
                  <div className="form-group">
                    <label htmlFor="contacts[1].name">Account Contact Name</label>
                    <Field
                      name="contacts[1].name"
                      component={Input}
                      placeholder="Account Contact Name"
                      disabled={!canCreate}
                    />
                  </div>
                </div>

                <div className="col-md-4 mt-md-3">
                  <div className="form-group">
                    <label htmlFor="contacts[1].email">Account Contact Email Address</label>
                    <Field
                      name="contacts[1].email"
                      component={Input}
                      placeholder="Account Contact Email Address"
                      disabled={!canCreate}
                    />
                  </div>
                </div>

                <div className="col-md-4 mt-md-3">
                  <div className="form-group">
                    <label htmlFor="contacts[1].phone">Account Contact Phone</label>
                    <Field
                      name="contacts[1].phone"
                      component={Input}
                      placeholder="Account Contact Phone"
                      disabled={!canCreate}
                    />
                  </div>
                </div>
              </div>

              <AddressFields />

              <div className="row">
                <div className="col-md-12 mt-md-3 mt-sm-0">
                  <div className="form-group">
                    <label htmlFor="notes">Notes</label>
                    <Field as="textarea" name="notes" className="form-control" disabled={!canCreate} />
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Formik>
  );
}

export default CustomerForm;
