import {useMemo} from 'react';
import {useQuery} from 'react-query';

import {getFireConfigOptions} from 'utils/horizon-api-client';

export function useFireConfigOptions() {
    const {data, status} = useQuery('fireDoorConfigOptions', fetchOptions);
    const optionsData = useMemo(parseOptionsData, [data]);

    async function fetchOptions(key) {
        const response = await getFireConfigOptions();
        return response.data;
    }

    // fire_door_jamb_timber_id
    // door_jamb_profile_id
    // fire_door_jamb_handing_id
    // non_hinge_face_gib_lining_thickness
    // hinge_face_gib_lining_thickness
    // wall_stud_width
    function parseOptionsData() {
        if (typeof data !== 'undefined') {
            return {
                fireCores: data.fire_cores,
                fireDoorJambConts: data.fire_door_jamb_conts,
                fireDoorClashingStrips: data.fire_door_clashing_strips,
                fireMeetingStiles: data.fire_meeting_stiles,
                skinCodes: data.skin_codes,
                fireSkins: data.fire_skins,
                fireGrilleOpenings: data.fire_grille_openings,
                fireVisionPanels: data.fire_vision_panels,
                fireDoorJambTimbers: data.fire_door_jamb_timbers,
                doorJambProfiles: data.door_jamb_profiles,
                fireDoorJambHandings: data.fire_door_jamb_handings,
                manufacturers: data.manufacturers,
            };
        } else {
            return {
                fireCores: [],
                fireDoorJambConts: [],
                fireDoorClashingStrips: [],
                fireMeetingStiles: [],
                skinCodes: [],
                fireSkins: [],
                fireGrilleOpenings: [],
                fireVisionPanels: [],
                fireDoorJambTimbers: [],
                doorJambProfiles: [],
                fireDoorJambHandings: [],
                manufacturers: [],
            };
        }
    }

    return {
        ...optionsData,
        status,
    };
}
