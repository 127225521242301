import { getPurchaseOrders } from 'utils/horizon-api-client';
import { useIndexData } from 'hooks/useIndexData';

export function usePurchaseOrders({ quoteId }) {
  const { refetch, recordData, totalCount, searchFieldValue, setSearchTerm, status } = useIndexData({
    requestData: getPurchaseOrders,
    requestParams: { quoteId },
    searchOptions: [],
    mapRecordToTable: mapPurchaseOrderToTable,
  });

  function mapPurchaseOrderToTable(po) {
    return {
      id: po.id,
      number: po.number,
      supplier: po.supplier,
      expected_on: po.expected_on,
    };
  }

  return {
    purchaseOrders: recordData,
    searchFieldValue: searchFieldValue,
    setSearchTerm: setSearchTerm,
    status: status,
    refetch,
  };
}
