import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Persist } from 'formik-persist';

import Input from 'components/Formik/Input';
import DropdownInput from 'components/Formik/DropdownInput';
import DatePickerInput from 'components/Formik/DatePickerInput';
import LoadingOverlay from 'components/LoadingOverlay';
import JobAddressForm from 'components/JobForm/JobAddressForm';
import { useJobPolicy } from 'policies/useJobPolicy';

const JobSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  status: Yup.string().required('Required'),
});

function JobForm({ onSubmit, initialValues, isLoading, header, shouldPersist, formName }) {
  const { canCreate } = useJobPolicy();

  const statusOptions = [
    { value: 'prospective', label: 'Prospective' },
    { value: 'active', label: 'Active' },
    { value: 'lost', label: 'Lost' },
    { value: 'completed', label: 'Completed' },
  ];

  return (
    <Formik validationSchema={JobSchema} initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true}>
      <>
        {header}
        {shouldPersist && <Persist name={formName} />}
        <LoadingOverlay isLoading={isLoading} />

        <div className="card card-top">
          <div className="card-header">
            <h2 className="card-heading">Job Details</h2>
          </div>

          <div className="card-body">
            <Form>
              <div className="row ml-2 mr-2">
                <div className="col-md-6 pl-0">
                  <div className="form-row mt-0">
                    <div className="col-md-12 col-xs-8 px-sm-0 px-0">
                      <div className="form-group">
                        <label htmlFor="name">Job Name</label>
                        <Field name="name" placeholder="Job Name" component={Input} disabled={!canCreate} />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="status">Status</label>
                        <Field name="status" options={statusOptions} component={DropdownInput} disabled={!canCreate} />
                      </div>
                    </div>

                    <div className="col-xs-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="require-date">Required Date</label>
                        <Field
                          name="required_before"
                          placeholder="Required Before"
                          component={DatePickerInput}
                          disabled={!canCreate}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 pr-0 pl-0">
                  <div className="form-row mt-0">
                    <div className="col-md-12 px-0">
                      <div className="form-group">
                        <label htmlFor="job-description">Job Description</label>
                        <Field
                          as="textarea"
                          cols="30"
                          name="description"
                          rows="5"
                          className="form-control"
                          disabled={!canCreate}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 col-xs-12">
                  <div className="form-group">
                    <label htmlFor="contact_name">Contact Name</label>
                    <Field name="contact_name" placeholder="Contact Name" component={Input} disabled={!canCreate} />
                  </div>
                </div>
                <div className="col-md-4 col-xs-12">
                  <div className="form-group">
                    <label htmlFor="contact_email">Contact Email</label>
                    <Field name="contact_email" placeholder="Contact Email" component={Input} disabled={!canCreate} />
                  </div>
                </div>
                <div className="col-md-4 col-xs-12">
                  <div className="form-group">
                    <label htmlFor="contact_phone">Contact Phone</label>
                    <Field name="contact_phone" placeholder="Contact Phone" component={Input} disabled={!canCreate} />
                  </div>
                </div>
              </div>

              <JobAddressForm canCreate={canCreate} />
            </Form>
          </div>
        </div>
      </>
    </Formik>
  );
}

export default JobForm;
