export const sortByLastUpdatedFunc = (rowA, rowB, columnId) => {
  const rowARawVal = rowA.original.updated_at || rowA.original.last_updated_at;
  const rowBRawVal = rowB.original.updated_at || rowB.original.last_updated_at;
  const rowAValue = Date.parse(rowARawVal);
  const rowBValue = Date.parse(rowBRawVal);

  if (rowAValue > rowBValue) return 1;
  if (rowAValue < rowBValue) return -1;
  return 0;
};
