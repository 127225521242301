import React, { useEffect } from "react";
import { useFormikContext } from "formik";

function SkipDoorValidation({ markSkipValidations }) {
  const { values } = useFormikContext();

  function hasExtras(values) {
    return typeof values.door_door_extras !== "undefined" && values.door_door_extras.length > 0;
  }

  function isExtraOnlyForm(values) {
    return (
      values.thickness === 0 &&
      values.height === 0 &&
      values.width === 0 &&
      values.skin_code_id === ""
    );
  }

  useEffect(() => {
    if (hasExtras(values) && isExtraOnlyForm(values)) {
      markSkipValidations(true);
    } else {
      markSkipValidations(false);
    }
  }, [values]);

  return null;
}

export default SkipDoorValidation;
