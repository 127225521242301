import React, { useState } from 'react';

import { toast } from 'react-toastify';

import { useDoors } from 'hooks/useDoors';
import { useTipcoDoors } from 'hooks/useTipcoDoors';
import { useFireDoors } from 'hooks/useFireDoors';
import { cloneDoor, cloneFireDoor, cloneStandaloneItem, cloneTipcoDoor } from 'utils/horizon-api-client';

import 'assets/stylesheets/duplicateDoor.css';
import { useStandaloneItems } from '../../hooks/useStandaloneItems';

/**
 *
 * @param {function} setSelectedUtility
 * @param {Object} door
 * @param {number} quoteId
 * @returns {JSX.Element}
 * @constructor
 */
function DuplicateDoor({ setSelectedUtility, door, quoteId }) {
  const [qty, setQty] = useState(1);
  const [submitting, setSubmitting] = useState(false);
  const { refetch: refetchStandard } = useDoors({ quoteId });
  const { refetch: refetchTipco } = useTipcoDoors({ quoteId });
  const { refetch: refetchFire } = useFireDoors({ quoteId });
  const { refetch: refetchStandaloneItems } = useStandaloneItems({ quoteId });

  let name;
  switch (door.door_class.toLowerCase()) {
    case 'standard':
    name = 'door';
      break;
  case 'tipco':
    name = 'door';
    break;
  case 'fire':
    name = 'door';
      break;
  case 'misc':
    name = 'item';
    break;
  }

  /**
   * @async
   * @param {number} id - Door ID to clone
   * @param {number} qty - Quantity of clones to create
   * @returns {Promise<any>}
   */
  const executeCloning = async ({ id, qty }) => {
    let response;
    const doorClass = door.door_class.toLowerCase();
    if (doorClass === 'standard') {
      response = await cloneDoor({ id, qty });
      refetchStandard();
    } else if (doorClass === 'tipco') {
      response = await cloneTipcoDoor({ id, qty });
      refetchTipco();
    } else if (doorClass === 'fire') {
      response = await cloneFireDoor({ id, qty });
      refetchFire();
    } else if (doorClass === 'misc') {
      response = await cloneStandaloneItem({ id, qty });
      refetchStandaloneItems();
    }
    return response;
  };

  /**
   * @async
   * @param e
   * @returns {Promise<boolean>}
   */
  async function handleConfirm(e) {
    e.preventDefault();
    if (submitting) {
      return false;
    }

    const confirmed = window.confirm(`This will duplicate the ${name} ${qty} times. Continue?`);

    if (confirmed) {
      setSubmitting(true);
      const response = await executeCloning({ id: door.id, qty: qty });
      setSubmitting(false);

      try {
        if (response.status === 200) {
          toast.success(`Created ${qty} duplicates`);
          setSelectedUtility(null);
        } else if (response.status === 403) {
          toast.error('You do not have permissions to perform this action');
        } else if (response.status === 422) {
          toast.error('Error duplicating door');
        } else if (response.status === 500) {
          toast.error('Internal server error.');
        }
      } catch {
        toast.error('Error duplicating door');
      }
    }
  }

  function handleOnInputChange(e) {
    setQty(e.target.value);
  }

  return (
    <>
      <span id="cancelBtn" className={'material-icons'} onClick={() => setSelectedUtility(null)}>
        cancel
      </span>
      <div id="duplicate-door" className="table-menu">
        <div className={'show dropdown-menu'} id="dropmenu">
          <h1>Duplicate</h1>
          <p>
            Create duplicate line items of <strong>{door.reference ? door.reference : ' this door'}</strong>
          </p>
          <div className="row">
            <div className="col col-sm-6">
              <div className="form-group">
                <input type="number" className="form-control" value={qty} onChange={handleOnInputChange} />
              </div>
            </div>
            <div className="col col-sm-6">
              <button className="btn btn-danger btn-lg" disabled={submitting} onClick={handleConfirm}>
                {submitting ? 'Duplicating..' : 'Confirm'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DuplicateDoor;
