import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { newZealandCities } from 'constants/formFieldData';
import { uniqBy } from 'lodash';

export function useDynamicAvailableCities() {
  const { values } = useFormikContext();
  const [availableCities, setAvailableCities] = useState(newZealandCities);

  useEffect(() => {
    if (
      typeof values.address !== 'undefined' &&
      typeof values.address.city === 'string' &&
      values.address.city.length > 0
    ) {
      const newCities = [...availableCities, { label: values.address.city, value: values.address.city }];
      const withoutDups = uniqBy(newCities, 'value');
      setAvailableCities(withoutDups);
    }
  }, [values.address]);

  return {
    availableCities,
  };
}
