import axios from 'axios';

import {getToken, setToken} from './auth-client';

const beRoutes = {
    purchaseOrders: id => {
        const baseUrl = '/api/v1/purchase_orders';
        if (typeof id !== 'undefined') {
            return `${baseUrl}/${id}`;
        } else {
            return baseUrl;
        }
    },
    doorExtras: id => {
        const baseUrl = '/api/v1/door_extras';
        if (typeof id !== 'undefined') {
            return `${baseUrl}/${id}`;
        } else {
            return baseUrl;
        }
    },
    tipcoDoors: quoteId => {
        return `/api/v1/quotes/${quoteId}/tipco_doors`;
    },
    tipcoDoor: id => {
        return `/api/v1/tipco_doors/${id}`;
    },
    tipcoConfigOptions: () => {
        return `/api/v1/tipco_doors/configuration_options`;
    },
    cloneTipcoDoor: id => `/api/v1/tipco_doors/${id}/clones`,
    freightItems: quoteId => `/api/v1/quotes/${quoteId}/freight_items`,
    freightItem: freightItemId => `/api/v1/freight_items/${freightItemId}`,
    auditLogs: () => `/api/v1/components/audit_logs`,
    componentAuditLogs: componentId => `/api/v1/components/${componentId}/audit_logs`,
    fireDoors: quoteId => `/api/v1/quotes/${quoteId}/fire_doors`,
    fireDoor: id => `/api/v1/fire_doors/${id}`,
    component: id => `/api/v1/components/${id}`,
    components: () => `/api/v1/components`,
    standaloneItem: id => `/api/v1/standalone_items/${id}`,
    standaloneItems: quoteId => `/api/v1/quotes/${quoteId}/standalone_items`,
    cloneStandaloneItem: id => `/api/v1/standalone_items/${id}/clones`,
    cloneFireDoor: id => `/api/v1/fire_doors/${id}/clones`,
    fireConfigOptions: () => `/api/v1/fire_doors/configuration_options`,
    doorViews: {
        allSerials: '/api/v1/doors/views/all_serials',
    },
    newXeroSession: "/api/v1/xero_sessions/new",
    createXeroSession: "/api/v1/xero_sessions",
    createXeroInvoice: (quoteId) => `/api/v1/quotes/${quoteId}/xero_invoices`
};

const authHeaders = () => {
    return getToken();
};

function setTokenFromHeaders({headers}) {
    setToken({
        client: headers.client,
        uid: headers.uid,
        accessToken: headers['access-token'],
        expiry: headers.expiry,
    });
}

async function get({url, params, options = {}}) {
    const response = await axios.get(
        `${process.env.REACT_APP_HORIZON_API_HOST}${url}`,
        Object.assign(
            {},
            {
                headers: authHeaders(),
                params: params,
            },
            options
        )
    );
    setTokenFromHeaders({headers: response.headers});
    return response;
}

async function post({data, url}) {
    try {
        const response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_HORIZON_API_HOST}${url}`,
            headers: authHeaders(),
            data: data,
        });
        setTokenFromHeaders({headers: response.headers});
        return response;
    } catch (err) {
        setTokenFromHeaders({headers: err.response.headers});
        return err.response;
    }
}

async function patch({data, url}) {
    try {
        const response = await axios({
            method: 'patch',
            url: `${process.env.REACT_APP_HORIZON_API_HOST}${url}`,
            headers: authHeaders(),
            data: data,
        });
        setTokenFromHeaders({headers: response.headers});
        return response;
    } catch (err) {
        setTokenFromHeaders({headers: err.response.headers});
        return err.response;
    }
}

async function destroy({data, url}) {
    try {
        const response = await axios({
            method: 'delete',
            url: `${process.env.REACT_APP_HORIZON_API_HOST}${url}`,
            headers: authHeaders(),
            data: data,
        });
        setTokenFromHeaders({headers: response.headers});
        return response;
    } catch (err) {
        setTokenFromHeaders({headers: err.response.headers});
        return err.response;
    }
}

const indexWithSearch = async ({page = 0, perPage = 25, filter = {}, base_url}) => {
    let response;
    if (Object.keys(filter).length) {
        response = await post({
            url: `${base_url}/search`,
            data: {
                q: filter,
                page: page,
                perPage: perPage,
            },
        });
    } else {
        response = await get({
            url: `${base_url}`,
            params: {
                page: page,
                perPage: perPage,
            },
        });
    }
    return response;
};

export const validateTokenWithApi = () => {
    return axios.get(process.env.REACT_APP_HORIZON_API_HOST + '/auth/validate_token', {
        headers: authHeaders(),
    });
};

export const getCustomers = async ({page = 0, perPage = 25, filter = {}}) => {
    let response;
    if (Object.keys(filter).length) {
        response = await post({
            url: '/api/v1/customers/search',
            data: {
                q: filter,
                page: page,
                perPage: perPage,
            },
        });
    } else {
        response = await get({
            url: '/api/v1/customers',
            params: {
                page: page,
                perPage: perPage,
            },
        });
    }

    return response.data;
};

export const getAllDoorSerials = async () => {
    const response = await get({
        url: beRoutes.doorViews.allSerials,
    });
    return response.data;
};

export const getEndCustomers = async ({page = 0, perPage = 25, filter = {}}) => {
    let response;
    if (Object.keys(filter).length) {
        response = await post({
            url: '/api/v1/end_customers/search',
            data: {
                q: filter,
                page: page,
                perPage: perPage,
            },
        });
    } else {
        response = await get({
            url: '/api/v1/end_customers',
            params: {
                page: page,
                perPage: perPage,
            },
        });
    }

    return response.data;
};

export const getComponents = async ({page = 0, perPage = 25, filter = {}}) => {
    let response;
    if (Object.keys(filter).length) {
        response = await post({
            url: '/api/v1/components/search',
            data: {
                q: filter,
                page: page,
                perPage: perPage,
            },
        });
    } else {
        response = await get({
            url: beRoutes.components(),
            params: {
                page: page,
                perPage: perPage,
            },
        });
    }

    return response.data;
};

export const createComponent = async ({values}) => {
    const response = await post({
        url: beRoutes.components(),
        data: values,
    });
    return response;
};

export const getJob = async ({id}) => {
    const response = await get({
        url: `/api/v1/jobs/${id}`,
    });

    return response;
};

export const getJobs = async ({page = 0, perPage = 25, filter = {}}) => {
    let response;
    if (Object.keys(filter).length) {
        response = await post({
            url: '/api/v1/jobs/search',
            data: {
                q: filter,
                page: page,
                perPage: perPage,
            },
        });
    } else {
        response = await get({
            url: '/api/v1/jobs',
            params: {
                page: page,
                perPage: perPage,
            },
        });
    }

    return response.data;
};

export const cloneQuote = async ({id}) => {
    const response = await post({
        url: `/api/v1/quotes/${id}/clones`,
        data: {},
    });
    return response;
};

export const updateQuote = async ({id, values}) => {
    const response = await patch({
        url: `/api/v1/quotes/${id}`,
        data: values,
    });
    return response;
};

export const getQuote = async ({id}) => {
    const response = await get({
        url: `/api/v1/quotes/${id}`,
    });

    return response;
};

export const getQuotes = async ({page = 0, perPage = 25, filter = {}, prefilter = ''}) => {
    let response;
    if (Object.keys(filter).length) {
        response = await post({
            url: '/api/v1/quotes/search',
            data: {
                q: filter,
                page: page,
                perPage: perPage,
                prefilter: prefilter,
            },
        });
    } else {
        response = await get({
            url: '/api/v1/quotes',
            params: {
                page: page,
                perPage: perPage,
                prefilter: prefilter,
            },
        });
    }

    return response.data;
};

export const getJobQuotes = async ({id}) => {
    const response = await get({
        url: `/api/v1/jobs/${id}/quotes`,
    });

    return response.data;
};

export const createQuote = async ({values}) => {
    const response = await post({
        url: '/api/v1/quotes',
        data: values,
    });
    return response;
};

export const createCustomer = async ({values}) => {
    const response = await post({
        url: '/api/v1/customers',
        data: values,
    });
    return response;
};

export const updateCustomer = async ({id, values}) => {
    const response = await patch({
        url: `/api/v1/customers/${id}`,
        data: values,
    });
    return response;
};

export const getCustomer = async ({id}) => {
    const response = await get({
        url: `/api/v1/customers/${id}`,
    });

    return response;
};

export const createEndCustomer = async ({values}) => {
    const response = await post({
        url: '/api/v1/end_customers',
        data: values,
    });
    return response;
};

export const updateEndCustomer = async ({id, values}) => {
    const response = await patch({
        url: `/api/v1/end_customers/${id}`,
        data: values,
    });
    return response;
};

export const getEndCustomer = async ({id}) => {
    const response = await get({
        url: `/api/v1/end_customers/${id}`,
    });

    return response;
};

export const createJob = async ({values}) => {
    const response = await post({
        url: '/api/v1/jobs',
        data: values,
    });
    return response;
};

export const updateJob = async ({id, values}) => {
    const response = await patch({
        url: `/api/v1/jobs/${id}`,
        data: values,
    });
    return response;
};

export const getUsers = async () => {
    const response = await get({
        url: '/api/v1/users',
    });

    return response.data;
};

export const getUser = async ({id}) => {
    const response = await get({
        url: `/api/v1/users/${id}`,
    });

    return response;
};

export const updateUser = async ({id, values}) => {
    const response = await patch({
        url: `/api/v1/users/${id}`,
        data: values,
    });
    return response;
};

export const getDoor = async ({id}) => {
    const response = await get({
        url: `/api/v1/doors/${id}`,
    });

    return response;
};

export const getDoorConfigOptions = async () => {
    const response = await get({
        url: `/api/v1/doors/configuration_options`,
    });

    return response.data;
};

export const getDoors = async ({quoteId = null, filter = {}}) => {
    let response, searchUrl;

    if (!Object.keys(filter).length && quoteId == null) {
        return {};
    }
    if (quoteId !== null) {
        searchUrl = `/api/v1/quotes/${quoteId}/doors/search`;
    } else {
        searchUrl = '/api/v1/doors/search';
    }

    if (Object.keys(filter).length) {
        response = await post({
            url: searchUrl,
            data: {
                q: filter,
            },
        });
    } else {
        response = await get({
            url: `/api/v1/quotes/${quoteId}/doors`,
        });
    }

    return response.data;
};

export const createDoor = async ({quoteId, values}) => {
    const response = await post({
        url: `/api/v1/quotes/${quoteId}/doors`,
        data: values,
    });
    return response;
};

export const updateDoor = async ({id, values}) => {
    const response = await patch({
        url: `/api/v1/doors/${id}`,
        data: values,
    });
    return response;
};

export const destroyDoor = async ({id}) => {
    const response = destroy({
        url: `/api/v1/doors/${id}`,
    });
    return response;
};

export const cloneDoor = async ({id, qty, newQuoteId = null}) => {
    const response = await post({
        url: `/api/v1/doors/${id}/clones`,
        data: {
            qty: qty,
            new_quote_id: newQuoteId,
        },
    });
    return response;
};

export const exportQuoteReport = async ({id, reportId, fileName}) => {
    const response = await get({
        url: `/api/v1/quotes/${id}/reports/${reportId}`,
        options: {responseType: 'arraybuffer'},
    });
    return response;
};

export const updateComponentCost = async ({id, newCost}) => {
    const response = await patch({
        url: `/api/v1/components/${id}`,
        data: {cost_price: newCost},
    });
    return response;
};

export const updateComponentPrices = async ({data}) => {
    const response = await post({
        url: `/api/v1/components/update_pricing`,
        data: data,
    });
    return response;
};

export const getPurchaseOrders = async ({quoteId}) => {
    const response = await get({
        url: `${beRoutes.purchaseOrders()}?quote_id=${quoteId}`,
    });
    return response.data;
};

export const createPurchaseOrder = async ({values}) => {
    const response = await post({
        url: beRoutes.purchaseOrders(),
        data: values,
    });
    return response;
};

export const updatePurchaseOrder = async ({id, expectedOn}) => {
    const response = await patch({
        url: beRoutes.purchaseOrders(id),
        data: {
            purchase_order: {
                expected_on: expectedOn,
            },
        },
    });
    return response;
};

// Door Extras
export const getDoorExtras = async ({page = 0, perPage = 25, filter = {}}) => {
    const response = await indexWithSearch({
        base_url: beRoutes.doorExtras(),
        filter,
    });
    return response.data;
};

export const getDoorExtra = async ({id}) => {
    const response = await get({
        url: beRoutes.doorExtras(id),
    });
    return response.data;
};

export const createDoorExtra = async ({values}) => {
    const response = await post({
        url: beRoutes.doorExtras(),
        data: values,
    });
    return response;
};

export const updateDoorExtra = async ({id, values}) => {
    const response = await patch({
        url: beRoutes.doorExtras(id),
        data: values,
    });
    return response;
};

// Tipco Doors
export const getTipcoDoors = async ({quoteId = null, filter = {}}) => {
    let response, searchUrl;

    if (!Object.keys(filter).length && quoteId == null) {
        return {};
    }

    if (quoteId !== null) {
        searchUrl = `/api/v1/quotes/${quoteId}/tipco_doors/search`;
    } else {
        searchUrl = '/api/v1/tipco_doors/search';
    }

    if (Object.keys(filter).length) {
        response = await post({
            url: searchUrl,
            data: {
                q: filter,
            },
        });
    } else {
        response = await get({
            url: beRoutes.tipcoDoors(quoteId),
        });
    }
    return response.data;
};

export const updateTipcoDoor = async ({id, values}) => {
    const response = await patch({
        url: beRoutes.tipcoDoor(id),
        data: values,
    });
    return response;
};

/**
 * @async
 * @param id - Door ID to clone
 * @param qty - Quantity of clones to create
 * @returns {Promise<AxiosResponse<any>|any>}
 */
export const cloneTipcoDoor = async ({id, qty, newQuoteId = null}) => {
    const response = await post({
        url: beRoutes.cloneTipcoDoor(id),
        data: {
            qty: qty,
            new_quote_id: newQuoteId,
        },
    });
    return response;
};

export const destroyTipcoDoor = async ({id}) => {
    const response = destroy({
        url: beRoutes.tipcoDoor(id),
    });
    return response;
};

export const createTipcoDoor = async ({quoteId, values}) => {
    const response = await post({
        url: beRoutes.tipcoDoors(quoteId),
        data: values,
    });
    return response;
};

export const getTipcoDoor = async ({id}) => {
    const response = await get({
        url: beRoutes.tipcoDoor(id),
    });
    return response.data;
};

export const getTipcoConfigOptions = async () => {
    const response = await get({
        url: beRoutes.tipcoConfigOptions(),
    });
    return response.data;
};

// Fire Doors
export const getFireDoors = async ({quoteId = null, filter = {}}) => {
    let response, searchUrl;

    if (!Object.keys(filter).length && quoteId == null) {
        return {};
    }

    if (quoteId !== null) {
        searchUrl = `/api/v1/quotes/${quoteId}/fire_doors/search`;
    } else {
        searchUrl = '/api/v1/fire_doors/search';
    }

    if (Object.keys(filter).length) {
        response = await post({
            url: searchUrl,
            data: {
                q: filter,
            },
        });
    } else {
        response = await get({
            url: beRoutes.fireDoors(quoteId),
        });
    }
    return response.data;
};

export const cloneFireDoor = async ({id, qty, newQuoteId = null}) => {
    const response = await post({
        url: beRoutes.cloneFireDoor(id),
        data: {
            qty: qty,
            new_quote_id: newQuoteId,
        },
    });
    return response;
};

/**
 *
 * @param {number} id - Fire Door ID to destroy
 * @returns {Promise<AxiosResponse<any>|any|undefined>}
 */
export const destroyFireDoor = async ({id}) => {
    const response = destroy({
        url: beRoutes.fireDoor(id),
    });
    return response;
};

export const getFireDoor = async ({id}) => {
    const response = await get({
        url: beRoutes.fireDoor(id),
    });
    return response.data;
};

export const getFireConfigOptions = async () => {
    const response = await get({
        url: beRoutes.fireConfigOptions(),
    });
    return response.data;
};

export const updateFireDoor = async ({id, values}) => {
    const response = await patch({
        url: beRoutes.fireDoor(id),
        data: values,
    });
    return response;
};

export const createFireDoor = async ({quoteId, values}) => {
    const response = await post({
        url: beRoutes.fireDoors(quoteId),
        data: values,
    });
    return response;
};

export const getComponentAuditLogs = async ({componentId = null}) => {
    let url;
    if (componentId !== null) {
        url = beRoutes.componentAuditLogs(componentId);
    } else {
        url = beRoutes.auditLogs();
    }

    const response = await get({url});
    return response.data;
};

export const getFreightItems = async ({quoteId}) => {
    if (quoteId === null) return {};

    const url = beRoutes.freightItems(quoteId);
    const response = await get({url});
    return response.data;
};

export const updateFreightItem = async ({freightItemId, values}) => {
    if (freightItemId === null) return {};

    const url = beRoutes.freightItem(freightItemId);
    const response = await patch({url, data: values});
    return response;
};

export const createFreightItem = async ({quoteId, values}) => {
    if (quoteId === null) return {};

    const url = beRoutes.freightItems(quoteId);
    const response = await post({url, data: values});
    return response;
};

export const createStandaloneItem = async ({quoteId, values}) => {
    if (quoteId === null) return {};

    const url = beRoutes.standaloneItems(quoteId);
    const response = await post({url, data: values});
    return response;
};

export const updateStandaloneItem = async ({standaloneItemId, values, id}) => {
    if (standaloneItemId === null || id === null) return {};
    const requestingId = standaloneItemId || id;

    const url = beRoutes.standaloneItem(requestingId);
    const response = await patch({url, data: values});
    return response;
};

export const getStandaloneItems = async ({quoteId}) => {
    if (quoteId === null) return {};

    const url = beRoutes.standaloneItems(quoteId);
    const response = await get({url});
    return response.data;
};

export const getStandaloneItem = async ({id}) => {
    const response = await get({
        url: beRoutes.standaloneItem(id),
    });
    return response.data;
};

export const cloneStandaloneItem = async ({id, qty, newQuoteId = null}) => {
    const response = await post({
        url: beRoutes.cloneStandaloneItem(id),
        data: {
            qty: qty,
            new_quote_id: newQuoteId,
        },
    });
    return response;
};

export const destroyStandaloneItem = async ({id}) => {
    const response = destroy({
        url: beRoutes.standaloneItem(id),
    });
    return response;
};

export const createXeroSession = async ({code}) => {
    return post({
        url: beRoutes.createXeroSession,
        data: {
            code
        }
    })
}

export const getXeroLoginUrl = async () => {
    return get({url: beRoutes.newXeroSession})
}

export const createXeroInvoice = async ({quoteId}) => {
    return post({url: beRoutes.createXeroInvoice(quoteId)})
}