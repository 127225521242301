import React, { useRef } from 'react';
import { debounce } from 'lodash';

function IndexTableWrapper({ onScrollToBottom, children, canFetchMore }) {
  const tableData = useRef(null);
  const onScroll = debounce(e => {
    const scrollTop = tableData.current.scrollTop + tableData.current.clientHeight;
    const scrollTopMax = tableData.current.scrollHeight;
    if (scrollTop > scrollTopMax * 0.7 && canFetchMore) {
      onScrollToBottom();
    }
  }, 100);

  return (
    <div className="content-main card-top index-table" ref={tableData}>
      <div className="contaiiner">{children}</div>
    </div>
  );
}

export default IndexTableWrapper;
