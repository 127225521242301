import React, { useMemo } from "react";
import Header from "components/Header";
import IndexTable from "components/IndexTable";
import IndexTableWrapper from "components/IndexTableWrapper";

import { useUsers } from "hooks/useUsers";
import "assets/stylesheets/usersPage.css"

function Users() {
  const { users, totalUsers, status } = useUsers();

  const columns = useMemo(
    () => [
      {
        Header: "ID ",
        accessor: "id",
        className: "v-sm-visible",
      },
      {
        Header: "Name ",
        accessor: "name",
      },
      {
        Header: "Email ",
        accessor: "email",
      },
      {
        Header: "Role ",
        accessor: "role",
        className: "v-sm-visible",
      },
      {
        Header: "",
        accessor: "extras",
        disableSortBy: true,
      },
    ],
    []
  );

  return (
    <div id="usersIndex">
      <Header pageTitle="Users" />
      <IndexTableWrapper onScrollToBottom={() => {}} canFetchMore={false}>
        <div className="content-header" id="user-table-header">
          <h2 className="content-title">
            <span className="d-none d-sm-block">Users List</span>
            <span className="d-xs-block d-sm-none heading-big">Users List</span>
          </h2>
        </div>
        <IndexTable columns={columns} data={users} status={status} />
        <span className="table-footer-label">
          Showing {users.length} of {totalUsers} users
        </span>
      </IndexTableWrapper>
    </div>
  );
}

export default Users;
