import React, { useMemo, useState } from 'react';

import { useFreightItems } from '../../hooks/useFreightItems';
import IndexTable from '../IndexTable';
import EditableCell from '../EditableCell';
import { createFreightItem, updateFreightItem } from '../../utils/horizon-api-client';
import { toast } from 'react-toastify';

import 'assets/stylesheets/freightItemsTable.css';

function FreightItemsList({ quoteId }) {
  const { freightItems, status, refetch } = useFreightItems({ quoteId });
  const [isUpdating, setIsUpdating] = useState(false);
  const freightCharges = useMemo(() => {
    if (freightItems.length === 0 || freightItems[0].freight_charges.length === 0) {
      return [
        {
          description: '',
          cost: 0,
        },
      ];
    } else {
      let charges = freightItems[0].freight_charges;
      charges = charges.map(fc => {
        return {
          ...fc,
          ...{
            utilities: (
              <span className="material-icons-outlined" onClick={() => handleDestroy(fc)}>
                delete
              </span>
            ),
          },
        };
      });

      return [
        charges,
        {
          description: '',
          cost: 0,
        },
      ].flat();
    }
  }, [status, freightItems]);

  const handleDestroy = async freightCharge => {
    if (isUpdating) return;

    setIsUpdating(true);

    const response = await updateFreightItem({
      freightItemId: freightCharge.freight_item_id,
      values: {
        freight_charges_attributes: [
          {
            id: freightCharge.id,
            _destroy: true,
          },
        ],
      },
    });

    switch (response.status) {
    case 200:
      toast.success('Charge succesfully removed');
      break;
    }

    setIsUpdating(false);
    await refetch();
  };

  const freightTotal = useMemo(() => {
    if (freightItems.length === 0 || freightItems[0].freight_charges.length === 0) {
      return 0;
    } else {
      let sum = 0;
      freightItems[0].freight_charges.forEach(fc => (sum += fc.cost));
      return Math.round(sum * 100) / 100;
    }
  }, [status, freightItems]);

  const onDataUpdate = async ({ rowData, newValue, column }) => {
    if (isUpdating) return;

    const isNewCharge = !rowData.id;
    const colName = column.id;
    const newValues = { ...rowData, ...{ [colName]: newValue } };
    delete newValues.utilities;

    let freightItemId;
    if (freightItems.length) {
      freightItemId = freightItems[0].id;
    }

    const updateValues = {
      freight_item: {
        freight_charges_attributes: [newValues],
      },
    };

    let createOrUpdateFunc;
    if (freightItemId) {
      createOrUpdateFunc = updateFreightItem;
    } else {
      createOrUpdateFunc = createFreightItem;
    }

    setIsUpdating(true);
    const response = await createOrUpdateFunc({ freightItemId: freightItemId, values: updateValues, quoteId });

    switch (response.status) {
      case 200:
      const message = isNewCharge ? 'Charge successfully created' : 'Updated';
        toast.success(message);
      break;
    case 201:
      toast.success('Charge successfully created');
        break;
    case 403:
      toast.error('You do not have permissions to update this.');
      break;
    case 500:
      toast.error('Internal server error.');
        break;
    default:
      toast.error('Error updating freight charge');
      break;
    }

    setIsUpdating(false);
    await refetch();
  };

  const columns = useMemo(
    () => [
      {
        Header: 'ID ',
        accessor: 'id',
        className: 'v-sm-visible',
      },
      {
        Header: 'Description ',
        accessor: 'description',
        Cell: EditableCell,
        className: 'v-sm-visible',
      },
      {
        Header: 'Cost ',
        accessor: 'cost',
        Cell: EditableCell,
        className: 'v-sm-visible',
      },
      {
        Header: ' ',
        accessor: 'utilities',
        className: 'v-sm-visible',
      },
    ],
    []
  );

  return (
    <>
      <div className="card freight-items-table">
        <div className="card-header content-header">
          <h2 className="card-heading">Freight Charges (Total ${freightTotal})</h2>
        </div>
        <div className="content-body">
          <IndexTable columns={columns} data={freightCharges} status={status} onDataUpdate={onDataUpdate} />
        </div>
      </div>
    </>
  );
}

export default FreightItemsList;
