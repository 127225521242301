import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import Header from 'components/Header';
import TableHeader from 'components/TableHeader';
import IndexTable from 'components/IndexTable';
import EditableCell from 'components/EditableCell';

import { updateComponentCost, updateComponentPrices } from 'utils/horizon-api-client';
import { useComponents } from 'hooks/useComponents';
import { useSearchHelperOptions } from 'hooks/useSearchHelperOptions';

import 'assets/stylesheets/componentsList.css';
import { SubHeader } from '../components/ComponentsList/SubHeader';
import ModalWrapper from '../components/ModalWrapper';
import { useAuditLogs } from '../hooks/useAuditLogs';
import { ComponentAuditLogsTable } from '../components/Components/ComponentAuditLogsTable';
import { queryCache } from 'react-query';
import { sortByLastUpdatedFunc } from '../utils/table-utils';

function ComponentAuditLogs({ onHide, componentId }) {
  const isEnabled = componentId !== null;
  const { auditLogs, fetchMore, canFetchMore, status, refetch } = useAuditLogs({
    componentId,
    isEnabled,
  });

  if (componentId == null) return null;

  return (
    <ModalWrapper
      dialogClassName={'component-audit-logs-modal'}
      onHide={onHide}
      show={true}
      title="Duplicate Via Search"
    >
      <ComponentAuditLogsTable auditLogs={auditLogs} status={status} />
    </ModalWrapper>
  );
}

function Components() {
  const {
    fetchMore,
    refetch,
    canFetchMore,
    components,
    totalComponents,
    searchOptions,
    status,
    setSearchTerm,
    searchFieldValue,
  } = useComponents();
  const { searchHelperOptions } = useSearchHelperOptions({ searchOptions });
  const sortOnLastUpdated = useCallback(sortByLastUpdatedFunc, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Cost ',
        accessor: 'cost_price',
        Cell: EditableCell,
      },
      {
        Header: 'Part No ',
        accessor: 'part_no',
      },
      {
        Header: 'Part Description ',
        accessor: 'part_description',
      },
      {
        Header: 'Supplier ',
        accessor: 'supplier',
        className: 'v-sm-visible',
      },
      {
        Header: 'Last Updated ',
        accessor: 'last_updated',
        className: 'v-sm-visible',
        sortType: sortOnLastUpdated,
      },
    ],
    []
  );

  const [filename, setFilename] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [submittingPricingUpdate, setPricingUpdateStatus] = useState(false);

  const [auditLogComponentId, setAuditLogComponentId] = useState(null);
  const [showAuditLogs, setShowAuditLogs] = useState(true);

  async function submitForm(e) {
    setPricingUpdateStatus(true);
    e.preventDefault();

    const formData = new FormData();
    formData.append('export_type', filename);
    formData.append('file', selectedFile);

    const resp = await updateComponentPrices({ data: formData });

    switch (resp.status) {
    case 200:
      toast.success(`Updated component pricing for ${filename}`);
        break;
    default:
      toast.error('Error updating pricing');
        break;
    }

    setPricingUpdateStatus(false);
  }

  async function onDataUpdate({ rowData, newValue, column }) {
    let response;
    if (column.id === 'cost_price') {
      response = await updateComponentCost({
        id: rowData.id,
        newCost: newValue,
      });
    }
    if (typeof response.status !== 'undefined') {
      queryCache.removeQueries('getComponentAuditLogs', { exact: false });
      sendNotification(response);
      refetch();
    }
  }

  function sendNotification(response) {
    switch (response.status) {
      case 200:
      toast.success('Updated!');
      break;
    case 403:
      toast.error('You do not have permissions to update this component');
      break;
    case 500:
      toast.error('Internal server error.');
      break;
    default:
      toast.error('Error updating cost');
      break;
    }
  }

  const rowProps = function (row) {
    return {
      onClick: e => {
        // skip in case we're clicking on an input box (that has a formAction)
        if (!e.target.formAction) {
          setAuditLogComponentId(row.original.id);
          setShowAuditLogs(true);
        }
      },
      style: {
        cursor: 'pointer',
      },
    };
  };

  return (
    <>
      {showAuditLogs && <ComponentAuditLogs onHide={() => setShowAuditLogs(false)} componentId={auditLogComponentId} />}
      <Header pageTitle="Components" classes="no-box-shadow" />
      <SubHeader selectedNav={'componentsList'} />
      <div className="content-main index-table">
        <div className="contaiiner">
          <TableHeader
            setSearchTerm={setSearchTerm}
            searchFieldValue={searchFieldValue}
            searchHelperOptions={searchHelperOptions}
            resourceName="Component"
            btnClasses="btn-huge"
            centerElement={
              <form>
                <div className="input-group">
                  <input type="file" onChange={e => setSelectedFile(e.target.files[0])} />
                  <select onChange={e => setFilename(e.target.value)}>
                    <option></option>
                    <option>DOITEMS</option>
                    <option>COMPONENTS</option>
                    <option>DOJPRICE</option>
                    <option>DOJBMACH</option>
                    <option>GO</option>
                    <option>RG</option>
                    <option>VP</option>
                  </select>
                  <a
                    href="#"
                    className="btn btn-secondary btn-small ml-3"
                    disabled={submittingPricingUpdate}
                    onClick={e => submitForm(e)}
                  >
                    {submittingPricingUpdate ? 'Processing' : 'Update Pricing'}
                  </a>
                </div>
              </form>
            }
          />
          <IndexTable
            columns={columns}
            data={components}
            status={status}
            onDataUpdate={onDataUpdate}
            rowProps={rowProps}
          />
          <span className="table-footer-label">
            Showing {components.length} of {totalComponents} components
          </span>
        </div>
      </div>
    </>
  );
}

export default Components;
