import { Link } from 'react-router-dom';
import NavItem from 'components/NavItem';
import React from 'react';

import { useSidebar } from 'contexts/sidebar';
import { useUserPolicy } from 'policies/useUserPolicy';
import { useComponentPolicy } from 'policies/useComponentPolicy';

import logo from 'assets/images/bd white.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Sidebar() {
  const { active, toggleActive } = useSidebar();
  const activeClass = active ? 'active' : '';
  const { canView: canViewUsers } = useUserPolicy();
  const { canView: canViewComponents } = useComponentPolicy();

  return (
    <nav id="sidebar" className={activeClass}>
      <a href="#" className="close-btn" id="sidebar-close" onClick={toggleActive}>
        <FontAwesomeIcon icon={['fas', 'times']} className="close-icon" />
      </a>
      <div className="sidebar-header">
        <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>
      </div>

      <ul className="list-unstyled sidebar__list">
        <NavItem icon="receipt" text="Quotes" to="/quotes" />
        <NavItem icon="formatted_list_bulletted" text="Orders" to="/orders-quotes" />
        <NavItem icon="send" text="Dispatch" to="/dispatch-quotes" />
        <NavItem icon="attach_money" text="Billing" to="/billing-quotes" />
        <NavItem icon="people" text="Customers" to="/customers" />
        <NavItem icon="work_outline" text="Jobs" to="/jobs" />
        {canViewUsers && <NavItem icon="people" text="Users" to="/users" />}
        {canViewComponents && <NavItem icon="settings" text="Components" to="/components" />}
        <NavItem icon="handyman" text="Extras" to="/door-extras" />
      </ul>
    </nav>
  );
}

export default Sidebar;
