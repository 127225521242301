export function serializeQuoteForm({formData, values}) {
    formData.append('order_no', values.order_no || '');
    formData.append('customer_id', values.customer_id);
    formData.append('job_id', values.job_id);
    formData.append('tagged', values.tagged);
    formData.append('status', values.status);
    formData.append('quote_date', values.quote_date);
    formData.append('reprint_on', values.reprint_on);
    formData.append('valid_til', values.valid_til);
    formData.append('public_notes', values.public_notes);
    formData.append('internal_notes', values.internal_notes);

    if (values.status === 'ordered') {
        formData.append('core_date', values.core_date);
        formData.append('added_value_date', values.added_value_date);
        formData.append('painting_date', values.painting_date);
        formData.append('press_date', values.press_date);
        formData.append('dlo_date', values.dlo_date);
        formData.append('edd_date', values.edd_date);
    }

    if (typeof values.files === 'undefined') {
        values.files = [];
    }

    values.files.forEach(file => {
        const fileIsPersistedToServer = file.id;
        if (!fileIsPersistedToServer) {
            formData.append(`files[]`, file);
        }
    });

    return formData;
}
