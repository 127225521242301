import React from 'react';
import { Field } from 'formik';

import MapWithMarker from 'components/JobForm/MapWithMarker';
import DropdownInput from 'components/Formik/DropdownInput';
import Input from 'components/Formik/Input';
import { useAddressSuggestions } from 'hooks/useAddressSuggestions';
import { useDynamicAvailableCities } from 'hooks/useDynamicAvailableCities';

function JobAddressForm({ canCreate }) {
  const { handleFieldChange, addressResultsList } = useAddressSuggestions();
  const { availableCities } = useDynamicAvailableCities();

  return (
    <>
      <p className="div-label">Site Address</p>
      <div className="label-div">
        <div className="form-row">
          <div className="col-md-5">
            <div className="form-group">
              <label htmlFor="line-1">Line 1</label>
              <Field
                name="address.line_1"
                placeholder="Line 1"
                component={Input}
                disabled={!canCreate}
                afterChange={handleFieldChange}
              />
            </div>

            {addressResultsList}

            <div className="form-group">
              <label htmlFor="line-2">Line 2</label>
              <Field name="address.line_2" placeholder="Line 2" component={Input} disabled={!canCreate} />
            </div>
            <div className="form-group">
              <label htmlFor="city">City</label>
              <Field
                name="address.city"
                placeholder="City"
                options={availableCities}
                as="select"
                component={DropdownInput}
                disabled={!canCreate}
              ></Field>
            </div>
            <div className="form-group">
              <label htmlFor="pcode">Post Code</label>
              <Field name="address.post_code" placeholder="Post Code" component={Input} disabled={!canCreate} />
            </div>
          </div>
          <div className="col-md-7">
            <MapWithMarker />
          </div>
        </div>
      </div>
    </>
  );
}

export default JobAddressForm;
