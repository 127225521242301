import { FieldArray, useFormikContext } from 'formik';
import { useFireConfigOptions } from '../../../hooks/useFireConfigOptions';
import { find, filter } from 'lodash';
import FireVisionPanel from '../Fire/FireVisionPanel';
import React from 'react';

export function FireVisionPanelsList() {
  const { values, setFieldValue } = useFormikContext();
  const { fireVisionPanels } = useFireConfigOptions();

  const panelCount = (() => {
    if (values.fire_door_vision_panels) {
      return filter(values.fire_door_vision_panels, (fdvp) => !fdvp._destroy).length;
    } else {
      return 0
    }
  })()

  function getPanelData(fdvp) {
    const data = find(fireVisionPanels, panel => panel.value === fdvp.fire_vision_panel_id);
    return data || {};
  }

  function addPanel(e, arrayHelpers) {
    e.preventDefault();
    arrayHelpers.push({
      height: 0,
      width: 0,
    });
  }

  function removePanel(e, index, arrayHelpers) {
    e.preventDefault();

    const panel = values.fire_door_vision_panels[index];

    if (panel._destroy) {
      delete panel._destroy;
      setFieldValue(`fire_door_vision_panels[${index}]`, panel);
    } else {
      setFieldValue(`fire_door_vision_panels[${index}]`, Object.assign(panel, { _destroy: true }));
    }
  }

  function duplicatePanel(e, index, arrayHelpers) {
    e.preventDefault();
    const newPanel = Object.assign({}, values.fire_door_vision_panels[index]);
    newPanel.id = null;
    arrayHelpers.push(newPanel);
  }

  return (
    <FieldArray
      name="fire_door_vision_panels"
      render={arrayHelpers => (
        <div className="card mt-0">
          <div className="card-header d-flex align-items-center">
            <h2 className="card-heading mb-0">Vision Panels ({panelCount})</h2>
            <a
              href="#"
              className="btn btn-rounded btn-red btn-shadow ml-auto"
              onClick={e => {
                addPanel(e, arrayHelpers);
              }}
            >
              <span className="material-icons-outlined">add</span>
            </a>
          </div>

          <div className="card-body">
            {values.fire_door_vision_panels && values.fire_door_vision_panels.length > 0
              ? values.fire_door_vision_panels.map((panel, index) => (
                !panel._destroy && (
                  <FireVisionPanel
                    index={index}
                    key={index}
                    onRemove={e => {
                      removePanel(e, index, arrayHelpers);
                    }}
                    onDuplicate={e => {
                      duplicatePanel(e, index, arrayHelpers);
                    }}
                    panelData={getPanelData(panel)}
                    formKey="fire_door_vision_panels"
                    panelTypeOptions={fireVisionPanels}
                  />
                )))
              : ''}
          </div>
        </div>
      )}
    />
  );
}
