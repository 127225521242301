import React from "react";
import {Link} from "react-router-dom";
import {getDoorExtras} from "utils/horizon-api-client";
import {useIndexData} from "hooks/useIndexData";

export function useDoorExtras() {
    const searchOptions = {
        id: {
            name: 'id',
            aliases: ['id'],
            predicate: 'eq',
            displayName: 'ID',
            defaultSearchTerm: 'id:',
        },
        supplier: {
            name: 'supplier',
            aliases: ['supplier'],
            predicate: 'cont_any',
            displayName: 'Supplier',
            defaultSearchTerm: 'supplier:',
        },
        code: {
            name: "code",
            aliases: ["code"],
            predicate: "cont_any",
            displayName: "Code",
            defaultSearchTerm: "code:",
        },
        full_description: {
            name: 'full_description',
            aliases: ['full_description', 'description', 'desc'],
            predicate: 'cont_any',
            displayName: 'Part Description',
            defaultSearchTerm: 'description:',
        },
        other: {
            name: "code",
            aliases: [],
            predicate: "cont_any",
        },
    };

    const {
        fetchMore,
        canFetchMore,
        recordData,
        totalCount,
        searchFieldValue,
        setSearchTerm,
        status,
        refetch,
    } = useIndexData({
        requestData: getDoorExtras,
        searchOptions: searchOptions,
        mapRecordToTable: mapDoorExtraToTable,
    });

    function mapDoorExtraToTable(obj) {
        return {
            id: obj.id,
            code: obj.code,
            cost: (obj.cost_cents / 100.0).toFixed(2),
            sell: (obj.sell_cents / 100.0).toFixed(2),
            full_description: obj.full_description,
            supplier: obj.supplier,
            extras: (
                <Link to={`/door-extras/${obj.id}/edit`}>
                    <span className="material-icons icon-grey">edit</span>
                </Link>
            ),
        };
    }

    return {
        fetchMore: fetchMore,
        canFetchMore: canFetchMore,
        doorExtras: recordData,
        totalDoorExtras: totalCount,
        searchFieldValue: searchFieldValue,
        setSearchTerm: setSearchTerm,
        searchOptions: searchOptions,
        status: status,
        refetch,
    };
}
