import React from 'react';
import {Link} from 'react-router-dom';

import {useQuote} from 'hooks/useQuote';
import QuoteStatusButton from 'components/QuoteStatusButton';
import {useFormikContext} from "formik";

function QuoteFormHeader({onNavigation, selectedNav, isSaving, headerTitle, rightNav, handleSave, quoteId}) {
    const {data: quoteData} = useQuote({id: quoteId});
    const {submitForm} = useFormikContext();

    return (
        <>
            <nav className="navbar navbar-light navbar-big">
                <div className="container-fluid">
                    <div className="row pb-5" style={{width: '100%'}}>
                        <div className="col-md-12 px-0 px-sm-3 d-flex justify-content-space-between">
                            <h1 className="navbar-heading ml-0 heading-small">
                                <Link to="/quotes">
                                    <span className="material-icons-outlined">arrow_back</span>
                                </Link>
                                {headerTitle}
                            </h1>
                            <div className="d-none d-md-block ml-auto" id="navbarSupportedContent">
                                <ul className="navigation">{rightNav}</ul>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{width: '100%'}}>
                        <ul className="navigation navigation-bottom nav nav-tabs" id="nav-tab" role="tablist">
                            <li className="nav-item">
                                <a
                                    className={`nav-item nav-link ${selectedNav === 'quoteOverview' ? 'active' : ''}`}
                                    id="nav-overview-tab"
                                    href=""
                                    data-name="quoteOverview"
                                    onClick={onNavigation}
                                >
                                    Quote Overview
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className={`nav-item nav-link ${selectedNav === 'quoteDoors' ? 'active' : ''}`}
                                    id="nav-doors-tab"
                                    href=""
                                    data-name="quoteDoors"
                                    onClick={onNavigation}
                                >
                                    Doors
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className={`nav-item nav-link ${selectedNav === 'quoteNotes' ? 'active' : ''}`}
                                    id="nav-notes-tab"
                                    data-name="quoteNotes"
                                    href=""
                                    onClick={onNavigation}
                                >
                                    Notes
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className={`nav-item nav-link ${selectedNav === 'quoteFinancialSummary' ? 'active' : ''}`}
                                    id="nav-summary-tab"
                                    data-name="quoteFinancialSummary"
                                    href=""
                                    onClick={onNavigation}
                                >
                                    Financial Summary
                                </a>
                            </li>
                            <li className="ml-2 mb-2 nav-item">
                                <QuoteStatusButton status={quoteData.status}/>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="button-group d-sm-flex d-md-none d-lg-none card-fixed-nav nav-big">
                <a href="#" className="btn btn-rounded btn-red ml-auto" onClick={submitForm}>
                    <span className="material-icons-outlined">{isSaving ? 'cloud_upload' : 'save'}</span>
                </a>
            </div>
        </>
    );
}

export default QuoteFormHeader;
