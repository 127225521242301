import React, { useMemo, useState } from 'react';
import ModalWrapper from '../ModalWrapper';
import IndexTable from '../IndexTable';
import { DuplicateButton } from './DuplicateButton';
import Search from '../Search';
import SearchTooltip from '../SearchTooltip';
import { useDoors } from '../../hooks/useDoors';
import { useSearchHelperOptions } from '../../hooks/useSearchHelperOptions';
import { useTipcoDoors } from '../../hooks/useTipcoDoors';
import { useFireDoors } from '../../hooks/useFireDoors';

export function SearchDuplication({ onClick, duplicateToQuoteId, afterDuplication = () => {} }) {
  const additionalSearchOptions = {
    quote_id: {
      name: 'quote_id',
      description: 'Quote number contains value',
      aliases: ['id', 'quote', 'number', 'no'],
      predicate: 'eq',
      displayName: 'Quote No.',
      defaultSearchTerm: 'quote:',
    },
  };

  const {
    doors: standardDoors,
    setSearchTerm: setStandardDoorsSearchTerm,
    searchOptions,
    status,
    searchFieldValue,
  } = useDoors({ quoteId: null, additionalSearchOptions });
  const { doors: tipcoDoors, setSearchTerm: setTipcoDoorsSearchTerm } = useTipcoDoors({
    quoteId: null,
    additionalSearchOptions,
  });
  const { doors: fireDoors, setSearchTerm: setFireDoorsSearchTerm } = useFireDoors({
    quoteId: null,
    additionalSearchOptions,
  });

  const { searchHelperOptions } = useSearchHelperOptions({
    searchOptions: {
      ...additionalSearchOptions,
      ...searchOptions,
    },
  });

  const setSearchTerm = useMemo(
    () => searchTerm => {
      setStandardDoorsSearchTerm(searchTerm);
      setTipcoDoorsSearchTerm(searchTerm);
      setFireDoorsSearchTerm(searchTerm);
    },
    [setStandardDoorsSearchTerm, setTipcoDoorsSearchTerm, setFireDoorsSearchTerm]
  );
  const doors = useMemo(() => {
    const sdoors = standardDoors.map(door => {
      return {
        ...door,
        duplicate: (
          <DuplicateButton
            doorType="standard"
            doorData={door}
            duplicateToQuoteId={duplicateToQuoteId}
            afterDuplication={afterDuplication}
          />
        ),
      };
    });
    const fdoors = fireDoors.map(door => {
      return {
        ...door,
        duplicate: (
          <DuplicateButton
            doorType="fire"
            doorData={door}
            duplicateToQuoteId={duplicateToQuoteId}
            afterDuplication={afterDuplication}
          />
        ),
      };
    });
    const tdoors = tipcoDoors.map(door => {
      return {
        ...door,
        duplicate: (
          <DuplicateButton
            doorType="tipco"
            doorData={door}
            duplicateToQuoteId={duplicateToQuoteId}
            afterDuplication={afterDuplication}
          />
        ),
      };
    });

    return [...sdoors, ...tdoors, ...fdoors];
  }, [standardDoors, tipcoDoors, fireDoors]);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  function handleOnClick() {
    onClick();
    setModalIsOpen(true);
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Class ',
        accessor: 'door_class',
        className: 'v-sm-visible',
      },
      {
        Header: 'Quote ',
        accessor: 'quote_id',
        className: 'v-sm-visible',
      },
      {
        Header: 'Serial ',
        accessor: 'serial',
        className: 'v-sm-visible',
      },
      {
        Header: 'Door Tp ',
        accessor: 'leaf_type',
        className: 'v-sm-visible',
      },
      {
        Header: 'Jamb Tp ',
        accessor: 'jamb_type',
        className: 'v-sm-visible',
      },
      {
        Header: 'Hgt ',
        accessor: 'height',
        className: 'v-sm-visible',
      },
      {
        Header: 'Wth ',
        accessor: 'width',
        className: 'v-sm-visible',
      },
      {
        Header: 'Wth2 ',
        accessor: 'width_pair',
        className: 'v-sm-visible',
      },
      {
        Header: 'Thk ',
        accessor: 'thickness',
        className: 'v-sm-visible',
      },
      {
        Header: 'Skn ',
        accessor: 'skin_shortcode',
        className: 'v-sm-visible',
      },
      {
        Header: 'Core ',
        accessor: 'core_shortcode',
        className: 'v-sm-visible',
      },

      {
        Header: 'PH ',
        accessor: 'ph',
        className: 'v-sm-visible ph',
      },
      {
        Header: 'Clash Detail ',
        accessor: 'clashing_detail',
        className: 'v-sm-visible',
      },
      {
        Header: 'FRR ',
        accessor: 'frr',
        className: 'v-sm-visible',
      },
      {
        Header: 'Ref. ',
        accessor: 'reference',
      },
      {
        Header: 'Sell ',
        accessor: 'sell_price_with_phang',
      },
      {
        Header: '',
        accessor: 'duplicate',
        disableSortBy: true,
      },
    ],
    []
  );

  return (
    <>
      <a className="dropdown-item" onClick={handleOnClick}>
        Duplicate Via Search
      </a>
      <ModalWrapper
        dialogClassName={'duplicate-via-search-modal'}
        onHide={() => setModalIsOpen(false)}
        show={modalIsOpen}
        title="Duplicate Via Search"
      >
        <div className="row mb-4 search-header-row">
          <div className="col-md-6 ml-2">
            <div className="content-search">
              <div className="input-group d-flex align-items-center">
                <Search
                  setSearchTerm={setSearchTerm}
                  searchFieldValue={searchFieldValue}
                  searchHelperOptions={searchHelperOptions}
                />
              </div>
              <div className="d-flex">
                <SearchTooltip searchHelperOptions={searchHelperOptions} />
              </div>
            </div>
          </div>
        </div>
        <div id="doors-list" className="">
          <IndexTable columns={columns} data={searchFieldValue.length === 0 ? [] : doors} status={status} />
        </div>
      </ModalWrapper>
    </>
  );
}
