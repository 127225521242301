import React, {useMemo, useState} from 'react';
import ModalWrapper from '../ModalWrapper';
import {useDoorViews} from '../../hooks/useDoorViews';
import {Field} from 'formik';
import DropdownInput from '../Formik/DropdownInput';
import IndexTable from '../IndexTable';
import {useDoor} from '../../hooks/useDoor';
import {getDoor, getFireDoor, getTipcoDoor} from '../../utils/horizon-api-client';
import {useFireDoor} from '../../hooks/useFireDoor';
import {useTipcoDoor} from '../../hooks/useTipcoDoor';
import {DuplicateButton} from './DuplicateButton';

export function SerialDuplication({
                                      onClick, duplicateToQuoteId, afterDuplication = () => {
    }
                                  }) {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const {allDoorSerials} = useDoorViews(['allDoorSerials']);

    const {mapDoorData} = useDoor({});
    const {mapFireDoorData} = useFireDoor({});
    const {mapTipcoDoorData} = useTipcoDoor({});
    const [doorData, setDoorData] = useState({});

    const serialOptions = useMemo(() => {
        return allDoorSerials.map(ds => {
            return {
                value: ds.value,
                label: ds.label,
            };
        });
    }, [allDoorSerials]);

    function handleOnClick() {
        onClick();
        setModalIsOpen(true);
    }

    async function handleSerialSelection(valueObj) {
        const serial = valueObj.value;
        if (typeof serial === 'undefined' || serial === '') return;

        if (serial.startsWith('STD')) {
            const id = serial.slice(3);
            const response = await getDoor({id: id});
            let doorData = mapDoorData(response.data);
            doorData = {
                ...doorData,
                duplicate: (
                    <DuplicateButton
                        doorType="standard"
                        doorData={doorData}
                        duplicateToQuoteId={duplicateToQuoteId}
                        afterDuplication={afterDuplication}
                    />
                ),
            };
            setDoorData(doorData);
        } else if (serial.startsWith('FI')) {
            const id = serial.slice(2);
            const response = await getFireDoor({id: id});
            let doorData = mapFireDoorData(response);
            doorData = {
                ...doorData,
                duplicate: (
                    <DuplicateButton
                        doorType="fire"
                        doorData={doorData}
                        duplicateToQuoteId={duplicateToQuoteId}
                        afterDuplication={afterDuplication}
                    />
                ),
            };
            setDoorData(doorData);
        } else if (serial.startsWith('TIP')) {
            const id = serial.slice(3);
            const response = await getTipcoDoor({id: id});

            let doorData = mapTipcoDoorData(response);
            doorData = {
                ...doorData,
                duplicate: (
                    <DuplicateButton
                        doorType="tipco"
                        doorData={doorData}
                        duplicateToQuoteId={duplicateToQuoteId}
                        afterDuplication={afterDuplication}
                    />
                ),
            };

            setDoorData(doorData);
        }
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Class ',
                accessor: 'door_class',
                className: 'v-sm-visible',
            },
            {
                Header: 'Quote ',
                accessor: 'quote_id',
                className: 'v-sm-visible',
            },
            {
                Header: 'Serial ',
                accessor: 'serial',
                className: 'v-sm-visible',
            },
            {
                Header: 'Door Tp ',
                accessor: 'leaf_type',
                className: 'v-sm-visible',
            },
            {
                Header: 'Jamb Tp ',
                accessor: 'jamb_type',
                className: 'v-sm-visible',
            },
            {
                Header: 'Hgt ',
                accessor: 'height',
                className: 'v-sm-visible',
            },
            {
                Header: 'Wth ',
                accessor: 'width',
                className: 'v-sm-visible',
            },
            {
                Header: 'Wth2 ',
                accessor: 'width_pair',
                className: 'v-sm-visible',
            },
            {
                Header: 'Thk ',
                accessor: 'thickness',
                className: 'v-sm-visible',
            },
            {
                Header: 'Skn ',
                accessor: 'skin_shortcode',
                className: 'v-sm-visible',
            },
            {
                Header: 'Core ',
                accessor: 'core_shortcode',
                className: 'v-sm-visible',
            },

            {
                Header: 'PH ',
                accessor: 'ph',
                className: 'v-sm-visible ph',
            },
            {
                Header: 'Clash Detail ',
                accessor: 'clashing_detail',
                className: 'v-sm-visible',
            },
            {
                Header: 'FRR ',
                accessor: 'frr',
                className: 'v-sm-visible',
            },
            {
                Header: 'Ref. ',
                accessor: 'reference',
            },
            {
                Header: 'Sell ',
                accessor: 'sell_price_with_phang',
            },
            {
                Header: '',
                accessor: 'duplicate',
                disableSortBy: true,
            },
        ],
        []
    );

    return (
        <>
            <a className="dropdown-item" onClick={handleOnClick}>
                Duplicate Via Serial
            </a>
            <ModalWrapper
                dialogClassName={'duplicate-via-serial-modal'}
                onHide={() => setModalIsOpen(false)}
                show={modalIsOpen}
                title="Duplicate Via Serial"
            >
                <div className="row">
                    <div className="col-md-3 ml-2">
                        <div className="form-group">
                            <Field
                                name="serial"
                                placeholder="Serial"
                                component={DropdownInput}
                                options={serialOptions}
                                afterChange={handleSerialSelection}
                                as="select"
                            >
                                <option value=""></option>
                                {allDoorSerials.map(job => {
                                    return (
                                        <option value={job.id} key={job.id}>
                                            {job.name}
                                        </option>
                                    );
                                })}
                            </Field>
                        </div>
                    </div>
                </div>
                <div id="doors-list" className="">
                    <IndexTable columns={columns} data={[doorData]} status={'status'}/>
                </div>
            </ModalWrapper>
        </>
    );
}
