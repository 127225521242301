import React from "react";
import Quotes from "pages/Quotes";

function BillingQuotes() {
  return (
    <>
      <Quotes prefilter="billing" />
    </>
  );
}

export default BillingQuotes;
