import { getComponents } from 'utils/horizon-api-client';
import { useIndexData } from 'hooks/useIndexData';
import moment from 'moment/moment';

export function useComponents() {
  const searchOptions = {
    id: {
      name: 'id',
      aliases: ['id'],
      predicate: 'eq',
      displayName: 'ID',
      defaultSearchTerm: 'id:',
    },
    supplier: {
      name: 'supplier',
      aliases: ['supplier'],
      predicate: 'cont_any',
      displayName: 'Supplier',
      defaultSearchTerm: 'supplier:',
    },
    part_no: {
      name: 'part_no',
      aliases: ['part_no'],
      predicate: 'cont_any',
      displayName: 'Part No',
      defaultSearchTerm: 'part_no:',
    },
    part_description: {
      name: 'part_description',
      aliases: ['part_description'],
      predicate: 'cont_any',
      displayName: 'Part Description',
      defaultSearchTerm: 'part_description:',
    },
    other: {
      name: 'part_no',
      aliases: [],
      predicate: 'cont_any',
    },
  };
  const {
    fetchMore,
    canFetchMore,
    recordData,
    totalCount,
    searchFieldValue,
    setSearchTerm,
    status,
    refetch,
  } = useIndexData({
    requestData: getComponents,
    searchOptions: searchOptions,
    mapRecordToTable: mapComponentToTable,
  });

  function mapComponentToTable(component) {
    return {
      id: component.id,
      cost_price: component.cost_price,
      part_no: component.part_no,
      part_description: component.part_description,
      supplier: component.supplier,
      updated_at: component.updated_at,
      created_at: component.created_at,
      last_updated: moment(component.updated_at).fromNow(),
    };
  }

  return {
    fetchMore: fetchMore,
    canFetchMore: canFetchMore,
    components: recordData,
    totalComponents: totalCount,
    searchFieldValue: searchFieldValue,
    setSearchTerm: setSearchTerm,
    searchOptions: searchOptions,
    status: status,
    refetch,
  };
}
