import React, { useEffect } from "react";
import { ErrorMessage, useField, useFormikContext } from "formik";
import { InputGroup, FormControl } from "react-bootstrap";
import moment from "moment";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "assets/stylesheets/react-datepicker-overrides.css";

const DatePickerInput = ({ field, form, options, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const meta = form.getFieldMeta(field.name);
  const hasError = typeof meta.error !== "undefined";
  const hasBeenTouched = meta.touched;
  const shouldDisplayError = hasError && hasBeenTouched;

  useEffect(() => {
    if (field.value && field.value !== "") {
      const date = new Date(field.value);
      const formattedDate = moment(date).format("YYYY-MM-DD");
      if (field.value !== formattedDate) {
        setFieldValue(field.name, formattedDate);
      }
    }
  }, [field.value]);

  return (
    <>
      <InputGroup id="date-picker-input" className="flex-nowrap">
        <DatePicker
          {...field}
          {...props}
          selected={(field.value && new Date(field.value)) || null}
          onChange={(val) => {
            setFieldValue(field.name, val);
          }}
          className={`form-control ${shouldDisplayError ? "invalid" : ""}`}
        />
        <span className="input-group-append" role="right-icon">
          <button
            className="btn btn-outline-secondary border-left-0"
            type="button"
          >
            <i className="material-icons datepicker-icon">event</i>
          </button>
        </span>
      </InputGroup>
      {shouldDisplayError && (
        <span className="input-error">
          <ErrorMessage name={field.name} />
        </span>
      )}
    </>
  );
};

export default DatePickerInput;
