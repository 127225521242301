import React from 'react';
import { toast } from 'react-toastify';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import Input from 'components/Formik/Input';
import DatePickerInput from 'components/Formik/DatePickerInput';
import FormHeader from 'components/FormHeader';
import { createPurchaseOrder } from 'utils/horizon-api-client';

const PurchaseOrderSchema = Yup.object().shape({
  number: Yup.string().required('Required'),
  expected_on: Yup.date().required('Required'),
});

function NewPurchaseOrder() {
  const history = useHistory();
  const { quoteId } = useParams();

  const initialValues = {
    number: '',
  };

  async function onSubmit(values, actions) {
    values.quote_id = quoteId;
    const response = await createPurchaseOrder({
      values: values,
    });

    actions.setErrors(response.data.errors);
    sendNotification(response);

    if (response.status === 204) {
      redirectToNotesPage();
    }
  }

  function redirectToNotesPage() {
    history.push(`/quotes/${quoteId}/edit?tab=quoteNotes`);
  }

  function sendNotification(response) {
    switch (response.status) {
    case 200:
        toast.success('Purchase Order successfully saved!');
      break;
    case 403:
        toast.error('You do not have permissions to create a purchase order. Please contact an Admin');
      break;
    case 422:
        toast.error('Error saving purchase order.');
        break;
    case 500:
        toast.error('Internal server error.');
        break;
    }
  }

  return (
    <>
      <Formik
        validationSchema={PurchaseOrderSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        <>
          <FormHeader
            canCreate={true}
            title={
              <>
                <Link to={`/quotes/${quoteId}/edit?tab=quoteNotes`}>
                  <span className="material-icons-outlined">arrow_back</span>
                </Link>
                New Purchase Order
              </>
            }
          />
          <div id="content">
            <div className="card card-top">
              <div className="card-header">
                <h2 className="card-heading">New Purchase Order</h2>
              </div>

              <div className="card-body">
                <Form>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="number">Number</label>
                        <Field name="number" placeholder="Purchase Order Number" component={Input} />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="number">Supplier</label>
                        <Field name="supplier" placeholder="Supplier" component={Input} />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="expected_on">Expected On Date</label>
                        <Field name="expected_on" component={DatePickerInput} placeholder="Expected On Date" />
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </>
      </Formik>
    </>
  );
}

export default NewPurchaseOrder;
