import React, { useCallback } from "react";
import { useFormikContext } from "formik";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { find } from "lodash";

function FileUploads({ afterFileDelete, hasUnsavedFileChanges }) {
  const { setFieldValue, values } = useFormikContext();

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (!duplicateFile(acceptedFiles[0])) {
        setFieldValue("files", values.files.concat(acceptedFiles));
      } else {
        toast.warning("Duplicate file exists");
      }
    },
    [values.files]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  function handleFileDelete(file) {
    const attachments = values.files.filter((obj) => obj.uuid !== file.uuid);
    afterFileDelete(file);
    setFieldValue("files", attachments);
  }

  function duplicateFile(acceptedFiles) {
    const duplicates = find(values.files, (file) => {
      return file.path === acceptedFiles.path;
    });
    if (typeof duplicates === "undefined") return false;
    return true;
  }

  return (
    <div className="form-row pb-0 pb-sm-5">
      <div
        className="col-sm-6 col-md-6 col-lg-6 mb-5 mb-sm-0"
        {...getRootProps()}
      >
        <div className="dropzone dz-clickable">
          <div className="dz-message needsclick">
            <input {...getInputProps()} />
            <span className="material-icons icon">cloud_upload</span>
            <h3>Drag & Drop or browse files to upload</h3>
          </div>
        </div>
      </div>

      <div className="col-sm-6 col-md-6 col-lg-6">
        <div className="files-container">
          <span className="label">
            Files
            {hasUnsavedFileChanges && (
              <span className="font-italic font-weight-light ml-1">
                - save Quote to persist file changes
              </span>
            )}
          </span>
          <ul className="files-list">
            {values.files &&
              values.files.map((file) => {
                return (
                  <li className="list-item" key={file.uuid}>
                    <a href={file.download_url}>
                      <div className="file">
                        <span className="material-icons file-icon">
                          file_copy
                        </span>
                        <span className="file-name">{file.name}</span>
                      </div>
                    </a>
                    <span
                      className="material-icons delete-icon"
                      onClick={() => {
                        handleFileDelete(file);
                      }}
                    >
                      delete
                    </span>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default FileUploads;
