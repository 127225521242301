import React, { useMemo } from "react";
import { useAuth } from "contexts/auth";

export function useUserPolicy() {
  const { userRole } = useAuth();

  const canView = useMemo(() => {
    return userRole === "admin";
  }, [userRole]);

  const canUpdate = useMemo(() => {
    return userRole === "admin";
  }, [userRole]);

  return {
    canView,
    canUpdate,
  };
}
