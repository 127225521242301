import axios from "axios";

const ADDY_BASE_URL = "https://api.addy.co.nz";

async function get({ url, params, options = {} }) {
  const response = await axios.get(
    `${ADDY_BASE_URL}${url}`,
    Object.assign(
      {},
      {
        params: Object.assign(
          { key: process.env.REACT_APP_ADDY_API_KEY },
          params
        ),
      },
      options
    )
  );
  return response;
}

export async function getAddressSuggestions({ query }) {
  const response = await get({
    url: `/search`,
    params: {
      s: query,
    },
  });

  return response;
}

export async function getAddressDetails({ addressId }) {
  const response = await get({
    url: `/address`,
    params: {
      id: addressId,
    },
  });

  return response;
}
