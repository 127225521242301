import React from 'react';
import { Field, useFormikContext } from 'formik';
import { find } from 'lodash';
import { matchAnywhere } from 'utils/filter-utils';

import DropdownInput from 'components/Formik/DropdownInput';
import Input from 'components/Formik/Input';
import { useComponents } from '../../hooks/useComponents';

function StandaloneComponent({ index, onRemove, onDuplicate, standaloneItemComponent, dropdownOptions }) {
  const { setFieldValue, values } = useFormikContext();
  const { components } = useComponents();

  function handleCodeChange({ value }) {
    resetCostSellToDefault({ value: value });
  }

  function getItemProps({ extraId }) {
    return find(components, comp => comp.id === extraId);
  }

  function calculateSell(cost) {
    return (cost * values.contribution_factor * 1.03).toFixed(2);
  }

  function resetCostSellToDefault({ value }) {
    const chosenExtra = getItemProps({ extraId: value });
    const cost = chosenExtra.cost_price;
    const sell = calculateSell(cost);

    setFieldValue(`standalone_item_components[${index}].cost`, cost);
    setFieldValue(`standalone_item_components[${index}].sell`, sell);
  }

  function afterCostUpdate({ value }) {
    setFieldValue(`standalone_item_components[${index}].sell`, calculateSell(value));
  }

  function afterCostBlur(e) {
    const value = e.target.value;
    if (value === '') {
      const original = getItemProps({ extraId: standaloneItemComponent.component_id });
      setFieldValue(`standalone_item_components[${index}].cost`, original.cost_price);
      setFieldValue(`standalone_item_components[${index}].sell`, calculateSell(original.cost_price));
    }
  }

  function costIsOverridden() {
    const original = getItemProps({ extraId: standaloneItemComponent.component_id });
    if (!original) return false;

    return Number(original.cost_price) !== Number(standaloneItemComponent.cost);
  }

  if (standaloneItemComponent._destroy) return null;

  return (
    <div className="form-group-bordered">
      <div className="card-header card-header-mobile d-flex d-sm-none">
        <span className="label">Item {index + 1}</span>
        <div className="form-icons-group ml-auto">
          <a href="#">
            <span className="material-icons-outlined">delete</span>
          </a>
        </div>
      </div>
      <span className="label d-none d-sm-block">Item {index + 1}</span>

      <div
        className={`form-row mt-5 mt-sm-0 align-items-center mt-3 ${
          standaloneItemComponent._destroy ? 'for-destroy' : ''
        }`}
      >
        <div className="col-md-10 col-lg-10">
          <div className="form-row mt-0">
            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
              <div className="form-group">
                <label htmlFor={`standalone_item_components[${index}].component_id`}>Code</label>
                <Field
                  name={`standalone_item_components[${index}].component_id`}
                  placeholder="Code"
                  component={DropdownInput}
                  options={dropdownOptions}
                  customFilter={matchAnywhere}
                  afterChange={handleCodeChange}
                  as="select"
                >
                  <option value=""></option>
                </Field>
              </div>
            </div>

            <div className="col-2 col-sm-2 col-md-2 col-lg-2">
              <div className="form-group">
                <label htmlFor={`standalone_item_components[${index}].qty`}>Qty</label>
                <Field name={`standalone_item_components[${index}].qty`} placeholder={1} component={Input} />
              </div>
            </div>

            <div className="col-2 col-sm-2 col-md-2 col-lg-2">
              <div className={`form-group ${costIsOverridden() ? 'overridden-field' : ''}`}>
                <label htmlFor={`standalone_item_components[${index}].cost`}>Unit Cost</label>
                <Field
                  name={`standalone_item_components[${index}].cost`}
                  component={Input}
                  afterChange={afterCostUpdate}
                  onBlur={afterCostBlur}
                />
              </div>
            </div>

            <div className="col-2 col-sm-2 col-md-2 col-lg-2">
              <div className="form-group">
                <label htmlFor={`standalone_item_components[${index}].sell`}>Unit Sell</label>
                <Field name={`standalone_item_components[${index}].sell`} component={Input} />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-1 col-lg-1 d-sm-block d-none">
          <div className="form-icons-group">
            <a href="" onClick={onDuplicate}>
              <span className="material-icons">file_copy</span>
            </a>
            <a href="" onClick={onRemove}>
              <span className="material-icons-outlined">delete</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StandaloneComponent;
