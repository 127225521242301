import React, { useCallback, useMemo } from 'react';
import moment from 'moment/moment';
import IndexTable from '../IndexTable';
import { sortByLastUpdatedFunc } from '../../utils/table-utils';

export function ComponentAuditLogsTable({ auditLogs, status, header = null }) {
  const transformedData = useMemo(() => {
    return auditLogs.map(log => {
      const date = new Date(log.updated_at);

      return {
        ...log,
        component: log && log.record.part_no,
        was_value: log && Number(log.was_value) / 100,
        new_value: log && Number(log.new_value) / 100,
        user: log && log.user.email,
        updated_at: log && moment(date).format('YYYY-MM-DD hh:mm'),
      };
    });
  }, [auditLogs]);

  const sortOnLastUpdated = useCallback(sortByLastUpdatedFunc, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Component ',
        accessor: 'component',
      },
      {
        Header: 'Was Value ',
        accessor: 'was_value',
      },
      {
        Header: 'New Value ',
        accessor: 'new_value',
        className: 'v-sm-visible',
      },
      {
        Header: 'Updated By User ',
        accessor: 'user',
        className: 'v-sm-visible',
      },
      {
        Header: 'Updated At ',
        accessor: 'updated_at',
        className: 'v-sm-visible',
        sortType: sortOnLastUpdated,
      },
    ],
    []
  );

  const initialTableState = {
    sortBy: [
      {
        id: 'created_at',
        desc: true,
      },
    ],
  };

  return (
    <div className="audit-logs-table">
      {header}
      <div className="content-main index-table">
        <div className="contaiiner">
          <IndexTable columns={columns} data={transformedData} status={status} initialState={initialTableState} />
        </div>
      </div>
    </div>
  );
}
