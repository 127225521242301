import { Link } from 'react-router-dom';
import React from 'react';

export function SubHeader({ selectedNav }) {
  return (
    <div className="componentTabList">
      <ul className="navigation nav nav-tabs" id="nav-tab" role="tablist">
        <li className="nav-item">
          <Link
            className={`nav-item nav-link ${selectedNav === 'componentsList' ? 'active' : ''}`}
            id="nav-components-list-tab"
            to="/components"
            data-name="componentsList"
          >
            Components
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to={`/components/audit_logs`}
            className={`nav-item nav-link ${selectedNav === 'auditLogs' ? 'active' : ''}`}
            id="nav-audit-logs-tab"
            data-name="auditLogs"
          >
            Audit Logs
          </Link>
        </li>
      </ul>
    </div>
  );
}
