import React from 'react';
import { Field } from 'formik';
import { useDoorConfigOptions } from 'hooks/useDoorConfigOptions';

import DropdownInput from 'components/Formik/DropdownInput';
import Input from 'components/Formik/Input';

/**
 * @param {number} index
 * @param {func} onRemove
 * @param {func} onDuplicate
 * @param {object} panelData
 * @param {string} formKey String representing the key of the formik array
 * @param {array} panelTypeOptions
 * @returns {JSX.Element}
 * @constructor
 */
function FireVisionPanel({ index, onRemove, onDuplicate, panelData, formKey = 'fire_door_vision_panels', panelTypeOptions }) {
  if (panelData._destroy) return null;

  const panelCost = panelData.cost_cents ? panelData.cost_cents / 100 : 0
  const panelSell = panelData.sell_cents ? panelData.sell_cents / 100 : 0

  return (
    <div className="form-group-bordered">
      <div className="card-header card-header-mobile d-flex d-sm-none">
        <span className="label">Panel {index + 1}</span>
        <div className="form-icons-group ml-auto">
          <a href="" onClick={onDuplicate}>
            <span className="material-icons">file_copy</span>
          </a>
          <a href="" onClick={onRemove}>
            <span className="material-icons-outlined">delete</span>
          </a>
        </div>
      </div>

      <span className="label d-none d-sm-block">Panel {index + 1}</span>
      <div className="form-row mt-5 mt-sm-0 align-items-center mt-3">
        <div className="col-md-10 col-lg-10">
          <div className="form-row mt-0">
            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
              <div className="form-group">
                <label htmlFor={`${formKey}[${index}].panel_type`}>Code</label>
                <Field
                  name={`${formKey}[${index}].fire_vision_panel_id`}
                  placeholder="Code"
                  component={DropdownInput}
                  options={panelTypeOptions}
                  as="select"
                >
                  <option value=""></option>
                </Field>
              </div>
            </div>

            <div className="col-6 col-sm-6 col-md-3 col-lg-3">
              <div className="form-group">
                <label>Cost</label>
                <input className={'form-control'} disabled value={`$${panelCost}`} />
              </div>
            </div>

            <div className="col-6 col-sm-6 col-md-3 col-lg-3">
              <div className="form-group">
                <label>Sell</label>
                <input className={'form-control'} disabled value={`$${panelSell}`} />
              </div>
            </div>

            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
              <div className="form-group">
                <label>Height</label>
                <Field name={`fire_door_vision_panels[${index}].height`} placeholder={0} component={Input} />
              </div>
            </div>

            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
              <div className="form-group">
                <label>Width</label>
                <Field name={`fire_door_vision_panels[${index}].width`} placeholder={0} component={Input} />
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <div className="form-group">
                <label>Description</label>
                <input className={'form-control'} disabled value={panelData.description} />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-2 col-lg-2 d-sm-block d-none">
          <div className="form-icons-group">
            <a href="" onClick={onDuplicate}>
              <span className="material-icons">file_copy</span>
            </a>
            <a href="" onClick={onRemove}>
              <span className="material-icons-outlined">delete</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FireVisionPanel;
