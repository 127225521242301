import React, { useEffect, useState } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { find } from 'lodash';

import PrehangExtra from 'components/DoorForm/PrehangExtra';
import { useDoorConfigOptions } from 'hooks/useDoorConfigOptions';

function PrehangExtrasList({ onDelete }) {
  const { setFieldValue, values } = useFormikContext();
  const { doorExtras } = useDoorConfigOptions();
  const [extrasCount, setExtrasCount] = useState(0);

  useEffect(() => {
    setExtrasCount(values.door_prehang_extras ? values.door_prehang_extras.length : 0);
  }, [values.door_prehang_extras]);

  function getObjData(id) {
    const data = find(doorExtras, obj => obj.value === id);
    return data || {};
  }

  function addPanel(e, arrayHelpers) {
    e.preventDefault();
    arrayHelpers.push({
      qty: 1,
    });
  }

  function removeObj(e, index, arrayHelpers) {
    e.preventDefault();
    if (typeof onDelete === 'function') {
      onDelete(values.door_prehang_extras[index]);
    }
    const extra = values.door_prehang_extras[index];

    if (extra._destroy) {
      delete extra._destroy;
      setFieldValue(`door_prehang_extras[${index}]`, extra);
    } else if (typeof extra.id !== 'undefined') {
      setFieldValue(`door_prehang_extras[${index}]`, Object.assign(extra, { _destroy: true }));
    } else {
      arrayHelpers.remove(index);
    }
  }

  function duplicateObj(e, index, arrayHelpers) {
    e.preventDefault();
    const newPanel = Object.assign({}, values.door_prehang_extras[index]);
    newPanel.id = null;
    arrayHelpers.push(newPanel);
  }

  if (values.is_slider) {
    return null;
  }

  return (
    <FieldArray
      name="door_prehang_extras"
      render={arrayHelpers => (
        <div className="card mt-0">
          <div className="card-header d-flex align-items-center">
            <h2 className="card-heading mb-0">Prehang Extras ({extrasCount})</h2>
            <a
              href="#"
              className="btn btn-rounded btn-red btn-shadow ml-auto"
              onClick={e => {
                addPanel(e, arrayHelpers);
              }}
            >
              <span className="material-icons-outlined">add</span>
            </a>
          </div>

          <div className="card-body">
            {values.door_prehang_extras && values.door_prehang_extras.length > 0
              ? values.door_prehang_extras.map((obj, index) => (
                  <PrehangExtra
                  index={index}
                  key={index}
                  doorPrehangExtra={obj}
                  onRemove={e => {
                    removeObj(e, index, arrayHelpers);
                    }}
                  onDuplicate={e => {
                    duplicateObj(e, index, arrayHelpers);
                    }}
                  objectData={getObjData(obj.door_extra_id)}
                  />
              ))
              : ''}
          </div>
        </div>
      )}
    />
  );
}

export default PrehangExtrasList;
