import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/stylesheets/style.css";
import App from "App";
import * as serviceWorker from "serviceWorker";

import { BrowserRouter as Router } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faTimes,
  faSortDown,
  faSortUp,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

library.add(faBars, faTimes, faSortDown, faSortUp, faSearch);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
