import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import DoorFormHeaderNav from 'components/DoorForm/DoorFormHeaderNav';
import StandaloneItemForm from '../components/StandaloneItemForm/StandaloneItemForm';
import { createStandaloneItem } from '../utils/horizon-api-client';
import { toast } from 'react-toastify';

function NewStandaloneItem() {
  const history = useHistory();
  const { id } = useParams();

  async function onSubmit(values, actions) {
    const response = await createStandaloneItem({
      quoteId: id,
      values: values,
    });

    actions.setErrors(response.data.errors);
    sendNotification(response);

    if (response.status === 200) {
      // Needs to be called twice to clear form persistence.
      // Bug in formik-persist?
      actions.resetForm();
      actions.resetForm();

      history.push(`/standalone_items/${response.data.id}/edit`);
    }
  }

  function sendNotification(response) {
    switch (response.status) {
    case 200:
      toast.success('Misc Item successfully saved!');
        break;
    case 403:
      toast.error('You do not have permissions to create this. Please contact an Admin');
      break;
    case 422:
      toast.error('Error saving.');
      break;
    case 500:
      toast.error('Internal server error.');
        break;
    }
  }

  const initialValues = {
    reference: '',
    notes: '',
    contribution_factor: 1.0,
    standalone_item_components: [],
  };

  return (
    <StandaloneItemForm
      onSubmit={onSubmit}
      headerRightNav={<DoorFormHeaderNav isSaving={false} handleSave={onSubmit} />}
      quoteId={id}
      initialValues={initialValues}
      isLoading={false}
      shouldPersist={true}
      formName={`quote-${id}-new-standalone-item-form`}
    />
  );
}

export default NewStandaloneItem;
