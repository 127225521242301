import React, {useMemo, useState} from 'react';
import {toast} from 'react-toastify';
import {useParams} from 'react-router-dom';

import QuoteForm from 'components/QuoteForm/QuoteForm';
import EditQuoteHeaderNav from 'components/QuoteForm/EditQuoteHeaderNav';
import {updateQuote} from 'utils/horizon-api-client';
import {serializeQuoteForm} from 'utils/quote-form';
import {useQuote} from 'hooks/useQuote';
import {useQuotePolicy} from 'policies/useQuotePolicy';

function EditQuote() {
    const {id} = useParams();
    const {data, status, refetch} = useQuote({id: id});
    const [filesToPurge, setFilesToPurge] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const isLoading = status === 'loading';
    const {permittedAttributesForUpdate} = useQuotePolicy({quote: data});

    async function onSubmit(values, actions) {
        setIsSaving(true);
        const formData = new FormData();
        serializeQuoteForm({formData, values});

        filesToPurge.forEach(id => {
            formData.append('files_to_purge[]', id);
        });

        const response = await updateQuote({
            id: id,
            values: formData,
        });

        setIsSaving(false);
        actions.setErrors(response.data.errors);
        sendNotification(response);

        if (response.status === 200) {
            setFilesToPurge(prevState => []);
            refetch();
        }
    }

    const hasFilesToPurge = useMemo(() => {
        return filesToPurge.length > 0;
    }, [filesToPurge]);

    function handleFileDelete(file) {
        if (file.id) {
            setFilesToPurge(prevState => [...prevState, file.id]);
        }
    }

    function sendNotification(response) {
        switch (response.status) {
            case 200:
                toast.success('Quote successfully saved!');
                break;
            case 403:
                toast.error('You do not have permissions to update this quote. Please contact an Admin');
                break;
            case 422:
                toast.error('Error saving quote.');
                break;
            case 500:
                toast.error('Internal server error.');
                break;
        }
    }

    return (
        <div id="edit-quote">
            <QuoteForm
                quoteId={id}
                onSubmit={onSubmit}
                initialValues={data}
                isLoading={isLoading}
                isSaving={isSaving}
                hasFilesToPurge={hasFilesToPurge}
                afterFileDelete={handleFileDelete}
                headerRightNav={<EditQuoteHeaderNav isSaving={isSaving} handleSave={onSubmit}/>}
                headerTitle={`Quote No: ${data.quote_no ? data.quote_no : ''}`}
                editableFields={permittedAttributesForUpdate}
            />
        </div>
    );
}

export default EditQuote;
