import { getStandaloneItems } from 'utils/horizon-api-client';
import { useIndexData } from 'hooks/useIndexData';
import DoorUtilities from '../components/DoorsList/DoorUtilities';
import React from 'react';

export function useStandaloneItems({ quoteId = null, additionalSearchOptions = {} }) {
  const { refetch, recordData, totalCount, searchFieldValue, setSearchTerm, status } = useIndexData({
    requestData: getStandaloneItems,
    requestParams: { quoteId },
    searchOptions: {},
    mapRecordToTable: mapRecordToTable,
    isEnabled: quoteId !== null,
  });

  function mapRecordToTable(item) {
    return {
      id: item.id,
      door_class: 'Misc',
      serial: item.serial,
      contribution_factor: item.contribution_factor,
      deleted_at: item.deleted_at,
      notes: item.notes,
      position: item.position,
      reference: item.reference,
      cost_price_cents: item.cost_price_cents || 0,
      cost: (item.cost_price_cents / 100).toFixed(2),
      sell_price_cents: item.sell_price_cents || 0,
      sell: (item.sell_price_cents / 100).toFixed(2),
      sell_price_with_phang: (item.sell_price_cents / 100).toFixed(2),
      created_at: item.created_at,
      updated_at: item.updated_at,
      quote_id: item.quote_id,
      dropdown: <DoorUtilities door={{ door_class: 'Misc', ...item }} />,
      standalone_item_components: item.standalone_item_components.map(c => {
        return {
          ...c,
          cost: (c.cost_cents / 100).toFixed(2),
          sell: (c.sell_cents / 100).toFixed(2),
        };
      }),
      door_door_extras: item.door_door_extras.map(c => {
        return {
          ...c,
          cost: (c.cost_cents / 100).toFixed(2),
          sell: (c.sell_cents / 100).toFixed(2),
        };
      }),
    };
  }

  return {
    standaloneItems: recordData,
    totalStandaloneItems: totalCount,
    searchFieldValue: searchFieldValue,
    setSearchTerm: setSearchTerm,
    searchOptions: {},
    status: status,
    refetch,
  };
}
