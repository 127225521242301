import { getComponentAuditLogs } from 'utils/horizon-api-client';
import { useIndexData } from 'hooks/useIndexData';

export function useAuditLogs({ componentId = null, isEnabled = true, shouldCache = true }) {
  const searchOptions = {
    id: {
      name: 'id',
      aliases: ['id'],
      predicate: 'eq',
      displayName: 'ID',
      defaultSearchTerm: 'id:',
    },
  };

  const { fetchMore, canFetchMore, recordData, status, refetch } = useIndexData({
    requestData: getComponentAuditLogs,
    mapRecordToTable: mapAuditLogToTable,
    searchOptions,
    isEnabled,
    requestParams: {
      componentId,
    },
  });

  function mapAuditLogToTable(log) {
    return {
      id: log.id,
      auditable_type: log.auditable_type,
      auditable_id: log.auditable_id,
      new_value: log.new_value,
      uuid: log.uuid,
      was_value: log.was_value,
      created_at: log.created_at,
      updated_at: log.updated_at,
      user: {
        id: log.user.id,
        email: log.user.email,
        name: log.user.name,
      },
      record: JSON.parse(log.record),
    };
  }

  return {
    auditLogs: recordData,
    fetchMore,
    canFetchMore,
    status,
    refetch,
  };
}
