import { getFreightItems } from 'utils/horizon-api-client';
import { useIndexData } from 'hooks/useIndexData';

export function useFreightItems({ quoteId }) {
  const { refetch, recordData, totalCount, searchFieldValue, setSearchTerm, status } = useIndexData({
    requestData: getFreightItems,
    requestParams: { quoteId },
    searchOptions: [],
    mapRecordToTable: mapFreightItem,
  });

  function mapFreightItem(fi) {
    return {
      id: fi.id,
      freight_charges: fi.freight_charges.map(fc => {
        return {
          ...fc,
          cost: Number(fc.cost_cents / 100),
        };
      }),
    };
  }

  return {
    freightItems: recordData,
    searchFieldValue,
    setSearchTerm,
    status,
    totalCount,
    refetch,
  };
}
