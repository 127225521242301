import React from 'react';
import {Route, Switch} from 'react-router-dom';

import PrivateRoute from 'utils/PrivateRoute';

import LoginCallback from 'utils/LoginCallback';

import Home from 'pages/Home';

import NewStandardDoor from 'pages/NewStandardDoor';
import EditDoor from 'pages/EditDoor';

import NewStandaloneItem from 'pages/NewStandaloneItem';
import EditStandaloneItem from 'pages/EditStandaloneItem';

import NewFireDoor from 'pages/NewFireDoor';
import EditFireDoor from 'pages/EditFireDoor';

import NewTipcoDoor from 'pages/NewTipcoDoor';
import EditTipcoDoor from 'pages/EditTipcoDoor';

import Users from 'pages/Users';
import EditUser from 'pages/EditUser';

import Customers from 'pages/Customers';
import NewCustomer from 'pages/NewCustomer';
import EditCustomer from 'pages/EditCustomer';

import Jobs from 'pages/Jobs';
import NewJob from 'pages/NewJob';
import EditJob from 'pages/EditJob';

import EditQuote from 'pages/EditQuote';
import NewQuote from 'pages/NewQuote';
import DefaultQuotes from 'pages/DefaultQuotes';
import DispatchedQuotes from 'pages/DispatchedQuotes';
import OrderedQuotes from 'pages/OrderedQuotes';
import BillingQuotes from 'pages/BillingQuotes';

import NewPurchaseOrder from 'pages/NewPurchaseOrder';

import ComponentAuditLogs from 'pages/ComponentAuditLogs';
import Components from 'pages/Components';

import DoorExtras from 'pages/DoorExtras';
import NewDoorExtra from 'pages/NewDoorExtra';
import EditDoorExtra from 'pages/EditDoorExtra';
import NewComponent from './pages/NewComponent';
import XeroLoginCallback from "./utils/XeroLoginCallback";

const ROUTES = [
    {
        key: 'LOGIN_CALLBACK',
        path: '/login/callback',
        isExact: true,
        isPrivate: false,
        component: LoginCallback,
    },
    {
        key: 'XERO_LOGIN_CALLBACK',
        path: '/login/xero/callback',
        isExact: true,
        isPrivate: true,
        component: XeroLoginCallback,
    },
    {
        key: 'APP',
        path: '/',
        isPrivate: true,
        isExact: false,
        component: RenderRoutes,
        routes: [
            {
                path: '/quotes/:quoteId/purchase-orders/new',
                key: 'NEW_PURCHASE_ORDER',
                isExact: true,
                isPrivate: true,
                component: NewPurchaseOrder,
            },
            {
                path: '/standalone_items/:standaloneItemId/edit',
                key: 'EDIT_STANDALONE_ITEM',
                isExact: true,
                isPrivate: true,
                component: EditStandaloneItem,
            },
            {
                path: '/quotes/:id/standalone_items/new',
                key: 'NEW_STANDALONE_ITEM',
                isExact: true,
                isPrivate: true,
                component: NewStandaloneItem,
            },
            {
                path: '/customers/new',
                key: 'NEW_CUSTOMER',
                isExact: true,
                isPrivate: true,
                component: NewCustomer,
            },
            {
                path: '/customers/:id/edit',
                key: 'EDIT_CUSTOMER',
                isExact: true,
                isPrivate: true,
                component: EditCustomer,
            },
            {
                path: '/customers',
                key: 'CUSTOMERS',
                isExact: true,
                isPrivate: true,
                component: Customers,
            },
            {
                path: '/jobs/:id/edit',
                key: 'EDIT_JOB',
                isExact: true,
                isPrivate: true,
                component: EditJob,
            },
            {
                path: '/jobs/new',
                key: 'NEW_JOB',
                isExact: true,
                isPrivate: true,
                component: NewJob,
            },
            {
                path: '/jobs',
                key: 'JOBS',
                isExact: true,
                isPrivate: true,
                component: Jobs,
            },
            {
                path: '/doors/:doorId/edit',
                key: 'EDIT_DOOR',
                isExact: true,
                isPrivate: true,
                component: EditDoor,
            },
            {
                path: '/fire_doors/:doorId/edit',
                key: 'EDIT_FIRE_DOOR',
                isExact: true,
                isPrivate: true,
                component: EditFireDoor,
            },
            {
                path: '/tipco_doors/:doorId/edit',
                key: 'EDIT_TIPCO_DOOR',
                isExact: true,
                isPrivate: true,
                component: EditTipcoDoor,
            },
            {
                path: '/quotes/:id/doors/standard/new',
                key: 'NEW_DOOR',
                isExact: true,
                isPrivate: true,
                component: NewStandardDoor,
            },
            {
                path: '/quotes/:id/doors/fire/new',
                key: 'NEW_FIRE_DOOR',
                isExact: true,
                isPrivate: true,
                component: NewFireDoor,
            },
            {
                path: '/quotes/:id/doors/tipco/new',
                key: 'NEW_DOOR',
                isExact: true,
                isPrivate: true,
                component: NewTipcoDoor,
            },
            {
                path: '/quotes/:id/edit',
                key: 'EDIT_QUOTE',
                isExact: true,
                isPrivate: true,
                component: EditQuote,
            },
            {
                path: '/quotes/new',
                key: 'NEW_QUOTE',
                isExact: true,
                isPrivate: true,
                component: NewQuote,
            },
            {
                path: '/orders-quotes',
                key: 'ORDERS-QUOTES',
                isExact: true,
                isPrivate: true,
                component: OrderedQuotes,
            },
            {
                path: '/dispatch-quotes',
                key: 'DISPATCH-QUOTES',
                isExact: true,
                isPrivate: true,
                component: DispatchedQuotes,
            },
            {
                path: '/billing-quotes',
                key: 'BILLING-QUOTES',
                isExact: true,
                isPrivate: true,
                component: BillingQuotes,
            },
            {
                path: '/quotes',
                key: 'QUOTES',
                isExact: true,
                isPrivate: true,
                component: DefaultQuotes,
            },
            {
                path: '/users/:id/edit',
                key: 'EDIT_USER',
                isExact: true,
                isPrivate: true,
                component: EditUser,
            },
            {
                path: '/users',
                key: 'USERS',
                isExact: true,
                isPrivate: true,
                component: Users,
            },
            {
                path: '/components/new',
                key: 'NEW_COMPONENTS',
                isExact: true,
                isPrivate: true,
                component: NewComponent,
            },
            {
                path: '/components/audit_logs',
                key: 'COMPONENT_AUDIT_LOGS',
                isExact: true,
                isPrivate: true,
                component: ComponentAuditLogs,
            },
            {
                path: '/components',
                key: 'COMPONENTS',
                isExact: true,
                isPrivate: true,
                component: Components,
            },
            {
                path: '/door-extras/:id/edit',
                key: 'EDIT_DOOREXTRA',
                isExact: true,
                isPrivate: true,
                component: EditDoorExtra,
            },
            {
                path: '/door-extras/new',
                key: 'NEW_DOOREXTRA',
                isExact: true,
                isPrivate: true,
                component: NewDoorExtra,
            },
            {
                path: '/door-extras',
                key: 'DOOREXTRAS',
                isExact: true,
                isPrivate: true,
                component: DoorExtras,
            },
            {
                path: '/',
                key: 'HOME',
                isExact: true,
                isPrivate: true,
                component: Home,
            },
        ],
    },
];

function RouteWithSubRoutes(route) {
    if (route.isPrivate) {
        return <PrivateRoute path={route.path} component={route.component} exact={route.isExact}
                             routes={route.routes}/>;
    } else {
        return (
            <Route
                path={route.path}
                exact={route.isExact}
                render={props => <route.component {...props} routes={route.routes}/>}
            />
        );
    }
}

export function RenderRoutes({routes}) {
    return (
        <Switch>
            {routes.map((route, i) => {
                return <RouteWithSubRoutes key={route.key} {...route} />;
            })}
        </Switch>
    );
}

export default ROUTES;
