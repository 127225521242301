import React, { useMemo } from "react";
import { useQuery } from "react-query";

import { getUser } from "utils/horizon-api-client";

export function useUser({ id }) {
  const { data, status, refetch } = useQuery(["user", id], fetchUserById);
  const userData = useMemo(mapUserData, [data]);

  async function fetchUserById(key, id) {
    const response = await getUser({ id: id });
    return response.data;
  }

  function mapUserData() {
    if (typeof data === "undefined") return {};

    return {
      name: data.name,
      email: data.email,
      role: data.role,
    };
  }

  return {
    data: userData,
    status,
    refetch,
  };
}
