import React from 'react';
import Search from 'components/Search';
import SearchTooltip from 'components/SearchTooltip';
import { Link } from 'react-router-dom';
import { camelCase, kebabCase } from 'lodash';

function TableHeader({
  searchFieldValue,
  setSearchTerm,
  searchHelperOptions,
  resourceName,
  btnClasses = '',
  centerElement = null,
}) {
  return (
    <div className="content-header" id={`${camelCase(resourceName)}-table-header`}>
      <h2 className="content-title">
        <span className="d-none d-sm-block">{resourceName} List</span>
        <span className="d-xs-block d-sm-none heading-big">{resourceName} List</span>
      </h2>

      {centerElement}

      <div className="content-search">
        <div className="d-flex mr-2">
          <SearchTooltip searchHelperOptions={searchHelperOptions} />
        </div>
        <Search
          setSearchTerm={setSearchTerm}
          searchFieldValue={searchFieldValue}
          searchHelperOptions={searchHelperOptions}
        />
        <Link
          className={`btn btn-filled btn-rounded btn-red btn-big ${btnClasses}`}
          to={`${kebabCase(resourceName)}s/new`}
        >
          <span className="material-icons-outlined">add</span> New {resourceName}
        </Link>
      </div>
    </div>
  );
}

export default TableHeader;
