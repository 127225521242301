import React, { useMemo } from 'react';

import Header from 'components/Header';
import TableHeader from 'components/TableHeader';
import IndexTable from 'components/IndexTable';
import IndexTableWrapper from 'components/IndexTableWrapper';

import { useDoorExtras } from 'hooks/useDoorExtras';
import { useSearchHelperOptions } from 'hooks/useSearchHelperOptions';

function DoorExtras() {
  const {
    fetchMore,
    canFetchMore,
    doorExtras,
    totalDoorExtras,
    searchOptions,
    status,
    setSearchTerm,
    searchFieldValue,
  } = useDoorExtras();
  const { searchHelperOptions } = useSearchHelperOptions({ searchOptions });

  const columns = useMemo(
    () => [
      {
        Header: 'Code ',
        accessor: 'code',
        className: 'v-sm-visible',
      },
      {
        Header: 'Cost ',
        accessor: 'cost',
        className: 'v-sm-visible',
      },
      {
        Header: 'Sell Price ',
        accessor: 'sell',
        className: 'v-sm-visible',
      },
      {
        Header: 'Description ',
        accessor: 'full_description',
        className: 'v-sm-visible',
      },
      {
        Header: 'Supplier ',
        accessor: 'supplier',
        className: 'v-sm-visible',
      },
      {
        Header: '',
        accessor: 'extras',
        disableSortBy: true,
      },
    ],
    []
  );

  return (
    <>
      <Header pageTitle="Door Extras" />
      <IndexTableWrapper
        onScrollToBottom={fetchMore}
        canFetchMore={typeof canFetchMore === 'undefined' ? false : canFetchMore}
      >
        <TableHeader
          setSearchTerm={setSearchTerm}
          searchFieldValue={searchFieldValue}
          searchHelperOptions={searchHelperOptions}
          resourceName="Door Extra"
          btnClasses="btn-huge"
        />
        <IndexTable columns={columns} data={doorExtras} status={status} />
        <span className="table-footer-label">Showing {totalDoorExtras} extras</span>
      </IndexTableWrapper>
    </>
  );
}

export default DoorExtras;
