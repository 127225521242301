import React from "react";

import { ReactQueryDevtools } from "react-query-devtools";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ROUTES, { RenderRoutes } from "routes";
import { AuthProvider } from "contexts/auth";
import { SidebarProvider } from "contexts/sidebar";
import Sidebar from "components/Sidebar";

function App() {
  return (
    <AuthProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <SidebarProvider>
        <div className="wrapper">
          <Sidebar />
          <div id="content">
            <RenderRoutes routes={ROUTES} />
          </div>
        </div>
      </SidebarProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </AuthProvider>
  );
}

export default App;
