import {useMemo} from 'react';
import {useQuery} from 'react-query';
import {flatten} from 'lodash';
import {useSearch} from 'hooks/useSearch';

// Non-paginated version of useInfiniteIndexData

/**
 *
 * @param nestedResourceId
 * @param requestData
 * @param requestParams
 * @param searchOptions
 * @param mapRecordToTable
 * @param {boolean} isEnabled
 * @returns {{searchOptions, setSearchTerm: (value: (((prevState: []) => []) | [])) => void, refetch: ({throwOnError}?: {throwOnError?: boolean}) => Promise<unknown>, totalCount: number, recordData: [], searchFieldValue: [], status: "idle" | "loading" | "error" | "success"}}
 */
export function useIndexData({
                                 nestedResourceId = null,
                                 requestData,
                                 requestParams,
                                 searchOptions,
                                 mapRecordToTable,
                                 isEnabled = true,
                             }) {
    const {searchFilter, searchKey, searchFieldValue, setSearchTerm} = useSearch(searchOptions);

    const {status, data = [], refetch} = useQuery(buildFetchCacheKey(), fetchData, {enabled: isEnabled});
    const recordData = useMemo(allRecords, [data]);
    const totalCount = useMemo(() => {
        if (typeof data !== 'undefined' && data.totalCount) {
            return data.totalCount;
        } else {
            return 0;
        }
    }, [data]);

    function buildFetchCacheKey() {
        // Workaround for IDs being set to null at times and not properly caching
        // TODO: Refactor this...
        let reqParamsCacheKey = '';
        if (requestParams) {
            if (requestParams.quoteId) {
                reqParamsCacheKey = reqParamsCacheKey.concat(`quoteId${requestParams.quoteId}`);
            } else if (requestParams.componentId) {
                reqParamsCacheKey = reqParamsCacheKey.concat(`componentId${requestParams.componentId}`);
            }
        }

        if (nestedResourceId) {
            return `${requestData.name}-${reqParamsCacheKey}-${nestedResourceId}-${searchKey}`;
        } else {
            return `${requestData.name}-${reqParamsCacheKey}-${searchKey}`;
        }
    }

    const cacheKey = useMemo(() => {
        return buildFetchCacheKey();
    }, [searchKey]);

    async function fetchData(key) {
        const records = await requestData(
            Object.assign({}, requestParams, {
                filter: searchFilter,
            })
        );

        return {
            data: records.data,
            totalCount: records.total,
        };
    }

    function allRecords() {
        if (typeof data.data === 'undefined') return [];
        const arr = [];
        data.data.forEach(record => {
            arr.push(mapRecordToTable(record));
        });
        return flatten(arr);
    }

    return {
        rawData: data,
        refetch,
        recordData,
        totalCount,
        searchFieldValue,
        setSearchTerm,
        searchOptions,
        status,
        cacheKey,
    };
}
