import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { setToken } from "utils/auth-client";
import { useAuth } from "contexts/auth";
import { AUTH_FAILURE } from "constants/auth";

const LoginCallback = () => {
  const { authValidationStatus } = useAuth();
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const authParams = {
    client: query.get("client_id"),
    accessToken: query.get("auth_token"),
    uid: query.get("uid"),
    expiry: query.get("expiry"),
  };

  if (setToken(authParams)) {
    history.push("/");
  }

  if (authValidationStatus === AUTH_FAILURE) {
    return <h2>Oops, something went wrong. Please try again.</h2>;
  }

  return null;
};

export default LoginCallback;
