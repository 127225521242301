import React from 'react';
import { Route } from 'react-router-dom';
import { useAuth } from 'contexts/auth';
import { AUTH_PENDING } from 'constants/auth';

function PrivateRoute({ routes, component: Component, ...other }) {
  const { authValidationStatus, isLoggedIn } = useAuth();

  if (authValidationStatus === AUTH_PENDING) {
    return null;
  }

  if (!isLoggedIn()) {
    window.location.assign(
      process.env.REACT_APP_HORIZON_API_HOST +
        '/auth/saml?origin=' +
        process.env.REACT_APP_FRONTEND_HOST +
        '/login/callback'
    );
  }

  return <Route {...other} render={props => (isLoggedIn() ? <Component {...props} routes={routes} /> : null)} />;
}

export default PrivateRoute;
