import React from 'react';

function LoadingOverlay({ isLoading }) {
  return (
    <>
      {isLoading && (
        <div className="spinner-grow text-primary center-overlay" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}
    </>
  );
}

export default LoadingOverlay;
