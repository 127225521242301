import React, {useMemo} from 'react';

import {getFireDoors} from 'utils/horizon-api-client';
import {useIndexData} from 'hooks/useIndexData';
import {searchOptions} from 'utils/base-door-search-options';

import DoorUtilities from 'components/DoorsList/DoorUtilities';
import {find} from 'lodash';
import {useFireConfigOptions} from './useFireConfigOptions';

/**
 * @param {number} quoteId - ID of the quote to fetch doors for
 * @returns {object} - Object containing doors, totalDoors, searchFieldValue, setSearchTerm, status, refetch
 */
export function useFireDoors({quoteId = null, additionalSearchOptions = {}}) {
    const {
        fireCores,
        fireSkins,
        skinCodes,
        doorJambProfiles,
        fireDoorClashingStrips,
        fireMeetingStiles
    } = useFireConfigOptions();

    const shouldFetchFireDoors = useMemo(() => {
        return fireCores.length > 0 && fireSkins.length > 0;
    }, [fireCores, fireSkins]);

    const {refetch, cacheKey, recordData, totalCount, searchFieldValue, setSearchTerm, status} = useIndexData({
        requestData: getFireDoors,
        requestParams: {quoteId},
        searchOptions: {...additionalSearchOptions, ...searchOptions},
        mapRecordToTable: mapDoorToTable,
        isEnabled: shouldFetchFireDoors,
    });

    function mapDoorToTable(door) {
        const skinCode = find(skinCodes, skinCode => door.skin_code_id === skinCode.value);
        const coreType = find(fireCores, core => door.fire_core_id === core.value);
        const clashing = find(fireDoorClashingStrips, clash => door.fire_door_clashing_strip_id === clash.value);
        const stile = find(fireMeetingStiles, stile => door.fire_meeting_stile_id === stile.value);
        const jambType = find(doorJambProfiles, djp => {
            return door.fire_prehang && door.fire_prehang.door_jamb_profile_id === djp.value;
        });

        const clashingDetail = `${stile?.shortcode || ""} ${clashing?.shortcode || ""}`;

        const vpCount = door.fire_door_vision_panels.length;
        const grCount = door.extra_machining_count;
        const deCount = door.door_door_extras.length;

        let attachedItemCounts = "";
        if (vpCount) {
            attachedItemCounts = attachedItemCounts.concat(`VP:${vpCount} `)
        }
        if (grCount) {
            attachedItemCounts = attachedItemCounts.concat(`GR:${grCount} `)
        }
        if (deCount) {
            attachedItemCounts = attachedItemCounts.concat(`Ex:${deCount} `)
        }

        return {
            id: door.id,
            door_class: 'FIRE',
            quote_id: door.quote_id,
            leaf_type: door.door_type,
            subRows: door.children.map(child => mapDoorToTable(child)),
            height: door.height,
            width: door.width,
            width_pair: door.width_pair,
            thickness: door.thickness,
            reference: door.reference,
            jamb_type: jambType && jambType.label, // fire_prehang.door_jamb_profile_id
            ph: door.is_dlo ? 'D.L.O.' : door.fire_prehang.details_overview,
            frr: door.frr,
            clashing_detail: clashingDetail,
            skin_shortcode: skinCode ? skinCode.shortcode : '',
            core_shortcode: coreType ? coreType.shortcode : '',
            parent_id: door.parent_id,
            position: door.position,
            serial: door.serial,
            attached_item_counts: attachedItemCounts,
            sell_price_with_phang: (Number(door.sell_price) + Number(door.phang_sell_price)).toFixed(2) || 0,
            dropdown: <DoorUtilities door={{door_class: 'FIRE', ...door}}/>,
        };
    }

    return {
        doors: recordData,
        totalDoors: totalCount,
        searchFieldValue: searchFieldValue,
        setSearchTerm: setSearchTerm,
        status: status,
        refetch,
    };
}
