import React, { useState, useRef, useMemo } from 'react';
import { useFormikContext } from 'formik';

function OverridableField({ formFieldAccessor, fieldType, calculatedValue, overrideValue, label }) {
  const inputField = useRef();
  const { setFieldValue } = useFormikContext();
  const [overrideEnabled, toggleOverride] = useState(false);

  const fieldOverridden = useMemo(() => {
    switch (fieldType) {
      case 'number':
        return Number(overrideValue) > 0;
      case 'string':
        return String(overrideValue).length > 0;
    }
  }, [overrideValue, calculatedValue]);

  const fieldValue = useMemo(() => {
    if (fieldOverridden) {
      return overrideValue;
    } else {
      return calculatedValue;
    }
  }, [overrideValue, calculatedValue]);

  const manualOverrideActive = useMemo(() => {
    if (fieldOverridden) {
      return true;
    } else {
      return false;
    }
  }, [overrideValue]);

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  }

  function enableManualSellPrice() {
    toggleOverride(true);
  }

  function onChangeField(e) {
    const val = e.target.value;
    setFieldValue(formFieldAccessor, val);

    if (val === '') {
      toggleOverride(false);
      setFieldValue(formFieldAccessor, '');
    }
  }

  function clearManualPricing(e) {
    toggleOverride(false);

    let blankVal;
    switch (fieldType) {
      case 'number':
        blankVal = 0;
        break;
      case 'string':
        blankVal = '';
        break;
    }

    setFieldValue(formFieldAccessor, blankVal);
  }

  return (
    <span className={`editable-field ${manualOverrideActive ? 'edited' : ''}`}>
      {overrideEnabled && (
        <div>
          {label}
          <input
            type={fieldType}
            id={formFieldAccessor}
            className="editable-field-input"
            ref={inputField}
            value={overrideValue}
            onChange={onChangeField}
            onBlur={() => toggleOverride(false)}
            onKeyDown={handleKeyDown}
          />
          <span className="material-icons-outlined" onClick={clearManualPricing}>
            clear
          </span>
        </div>
      )}

      {!overrideEnabled && (
        <>
          <div className="blank-override-label" onClick={enableManualSellPrice}>
            {label}
            {fieldValue}
          </div>
        </>
      )}
    </span>
  );
}

export default OverridableField;
