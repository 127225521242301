import React, {useMemo, useRef} from 'react';
import {Field, useFormikContext} from 'formik';

import DropdownInput from 'components/Formik/DropdownInput';
import Input from 'components/Formik/Input';

import PricingOverview from 'components/DoorForm/Tipco/PricingOverview';

import {withBlankOption} from 'utils/form-utils';

import {useTipcoConfigOptions} from 'hooks/useTipcoConfigOptions';
import {useTipcoDoor} from 'hooks/useTipcoDoor';
import {useParams} from 'react-router-dom';

function DoorDetails() {
    const {doorJambHandings, tipcoHungTypes, tipcoJambs, tipcoLeaves, tipcoHumesDesignations, tipcoCroppingDimensions} =
        useTipcoConfigOptions();

    const {values, setFieldValue} = useFormikContext();

    const pairWidth = useRef();
    const {doorId} = useParams();
    const {data} = useTipcoDoor({id: doorId});

    const tipcoHungTypesSelectOptions = useMemo(() => withBlankOption(tipcoHungTypes), [tipcoHungTypes]);
    const doorJambHandingsSelectOptions = useMemo(() => withBlankOption(doorJambHandings), [doorJambHandings]);
    const tipcoJambsSelectOptions = useMemo(() => withBlankOption(tipcoJambs), [tipcoJambs]);
    const tipcoLeavesSelectOptions = useMemo(() => withBlankOption(tipcoLeaves), [tipcoLeaves]);
    const tipcoHumesDesignationsSelectOptions = useMemo(
        () => withBlankOption(tipcoHumesDesignations),
        [tipcoHumesDesignations]
    );
    const tipcoCroppingDimensionsSelectOptions = useMemo(
        () => withBlankOption(tipcoCroppingDimensions),
        [tipcoCroppingDimensions]
    );

    function getUniqueObjectsByKey(arr, key) {
        const uniqueValues = new Set();
        const uniqueObjects = [];

        for (let i = 0; i < arr.length; i++) {
            const value = arr[i][key];
            if (!uniqueValues.has(value)) {
                uniqueValues.add(value);
                uniqueObjects.push(arr[i]);
            }
        }

        return uniqueObjects;
    }

    return (
        <div className="card card-top card-top-big">
            <div className="card-header">
                <h2 className="card-heading">Door Details</h2>
                <hr/>
                <div className="form-row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <PricingOverview doorData={data}/>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                            <Field name="notes" cols="30" rows="4" placeholder="Door Notes" className="form-control"
                                   as="textarea"/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card-body py-3 pb-5">
                <div className="form-row">
                    <div className="col-12">
                        <label htmlFor="part_number">Part Number</label>
                        <Field name="part_number" placeholder="Part Number" component={Input} disabled/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-6 col-sm-6 col-md-4 col-lg-4">
                        <div className="form-group">
                            <label htmlFor="serial">Serial</label>
                            <Field name="serial" placeholder="Serial" component={Input} disabled/>
                        </div>
                    </div>

                    <div className="col-6 col-sm-6 col-md-4 col-lg-4">
                        <div className="form-group">
                            <label htmlFor="reference">Reference</label>
                            <Field name="reference" placeholder="Reference" component={Input}/>
                        </div>
                    </div>

                    <div className="col-6 col-sm-6 col-md-4 col-lg-4">
                        <div className="form-group">
                            <label htmlFor="actual_door_height">Actual Door Height</label>
                            <Field name="actual_door_height" placeholder="Actual Door Height" component={Input}
                                   disabled/>
                        </div>
                    </div>
                </div>

                {/* ROW 2 */}
                <div className="form-row">
                    <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                        <div className="form-group">
                            <label htmlFor="serial">Door</label>
                            <Field
                                name="tipco_leaf_id"
                                placeholder="Door"
                                component={DropdownInput}
                                options={tipcoLeavesSelectOptions}
                                as="select"
                            ></Field>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                        <div className="form-group">
                            <label htmlFor="width">Width</label>
                            <Field name="width" placeholder="Width" component={Input}/>
                        </div>
                    </div>

                    <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                        <div className="form-group">
                            <label htmlFor="tipco_hung_type_id">Hung Type</label>
                            <Field
                                name="tipco_hung_type_id"
                                placeholder="Hung Type"
                                component={DropdownInput}
                                options={tipcoHungTypes}
                                as="select"
                            ></Field>
                        </div>
                    </div>

                    <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                        <div className="form-group">
                            <label htmlFor="serial">Jamb</label>
                            <Field
                                name="tipco_jamb_id"
                                placeholder="Jamb"
                                component={DropdownInput}
                                options={tipcoJambsSelectOptions}
                                as="select"
                            ></Field>
                        </div>
                    </div>
                </div>

                {/* ROW 3 */}
                <div className="form-row">
                    <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                        <div className="form-group">
                            <label htmlFor="serial">TypCode</label>
                            <Field
                                name="tipco_humes_designation_id"
                                placeholder="TypCode"
                                component={DropdownInput}
                                options={tipcoHumesDesignationsSelectOptions}
                                as="select"
                            ></Field>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                        <div className="form-group">
                            <label htmlFor="serial">Open Top</label>
                            <Field
                                name="open_top"
                                placeholder="Open Top"
                                component={DropdownInput}
                                options={[
                                    {value: null, label: ' '},
                                    {value: 'OT', label: 'OT'},
                                    {value: 'PT', label: 'PT'},
                                ]}
                                as="select"
                            ></Field>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                        <div className="form-group">
                            <label htmlFor="tipco_cropping_dimension_id">Crop Size</label>
                            <Field
                                name="tipco_cropping_dimension_id"
                                placeholder="Crop Size"
                                component={DropdownInput}
                                options={tipcoCroppingDimensionsSelectOptions}
                                as="select"
                            ></Field>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                        <div className="form-group">
                            <label htmlFor="door_jamb_handing_id">Handing</label>
                            <Field
                                name="door_jamb_handing_id"
                                placeholder="Handing"
                                component={DropdownInput}
                                options={getUniqueObjectsByKey(doorJambHandingsSelectOptions, 'label')}
                                as="select"
                            ></Field>
                        </div>
                    </div>
                </div>
                {/* ROW 4 : Simple Description */}
                <div className="form-row">
                    <Field name="simple_description" placeholder="Simple Description" component={Input} disabled/>
                </div>
                {/* ROW 5 : Full Description */}
                <div className="form-row">
                    <Field name="full_description" placeholder="Full Description" component={Input} disabled/>
                </div>
            </div>
        </div>
    );
}

export default DoorDetails;
