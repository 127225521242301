import {useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {createXeroSession} from "./horizon-api-client";
import {toast} from "react-toastify";
import {syncWithXero} from "./xero";

const XeroLoginCallback = () => {
    const query = new URLSearchParams(useLocation().search);
    const history = useHistory();

    useEffect(() => {
        async function setupXeroSession(code, quoteId) {
            let sessionResponse = await createXeroSession({code: code})
            history.push(`/quotes/${quoteId}/edit`)
            toast.success("Xero login successful")
            toast.success("Syncing quote with Xero, please wait..")
            await syncWithXero(quoteId, (resp) => {
                toast.error(resp.data.error || "Something went wrong")
            })
        }

        let code = query.get("code");
        let quoteId = query.get("state");
        setupXeroSession(code, quoteId)
    }, []);

    return null;
};

export default XeroLoginCallback;
