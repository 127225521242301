import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import useDebouncedCallback from 'hooks/useDebouncedCallback';

import MapDisplay from 'components/JobForm/MapDisplay';

function MapWithMarker() {
  const newZealandGeoLat = -41.5000831;
  const newZealandGeoLong = 172.8344077;
  const defaultCooardinates = {
    lat: newZealandGeoLat,
    lng: newZealandGeoLong,
  };

  const { values } = useFormikContext();
  const [coords, setCoords] = useState(defaultCooardinates);
  const [shouldShowMarker, setShouldShowMarker] = useState(false);

  useEffect(() => {
    updateMap();
  }, [values.address]);

  const updateMap = useDebouncedCallback(() => {
    getGeoCooardinates().then(result => setCoords(result));
  }, 1500);

  async function getGeoCooardinates() {
    if (shouldGeocodeAddress()) {
      setShouldShowMarker(true);
      return {
        lng: Number(values.address.coords_x),
        lat: Number(values.address.coords_y),
      };
    } else {
      setShouldShowMarker(false);
      return defaultCooardinates;
    }
  }

  function shouldGeocodeAddress() {
    if (
      typeof values.address === 'undefined' &&
      typeof values.address.coords_x === 'undefined' &&
      typeof values.address.coords_y === 'undefined'
    ) {
      return false;
    } else {
      return true;
    }
  }

  return (
    <>
      <MapDisplay coords={coords} shouldShowMarker={shouldShowMarker} />
    </>
  );
}

export default MapWithMarker;
