import React, {useEffect, useState} from 'react';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import {useLocation} from 'react-router-dom';
import {Persist} from 'formik-persist';

import 'assets/stylesheets/quoteForm.css';

import LoadingOverlay from 'components/LoadingOverlay';
import PromptIfFileChangesUnsaved from 'utils/PromptIfFileChangesUnsaved';

import DoorsList from 'components/DoorsList/DoorsList';

import QuoteFormHeader from 'components/QuoteForm/QuoteFormHeader';
import QuoteOverview from 'components/QuoteForm/QuoteOverview';
import QuoteNotes from 'components/QuoteForm/QuoteNotes';
import QuoteFinancialSummary from 'components/QuoteForm/QuoteFinancialSummary';

const QuoteSchema = Yup.object().shape({
    job_id: Yup.string().required('Required'),
    order_no: Yup.string(),
    customer_id: Yup.string().required('Required'),
    status: Yup.string().required('Required'),
    tagged: Yup.boolean().required('Required'),
    public_notes: Yup.string().nullable(),
    internal_notes: Yup.string().nullable(),
    quote_date: Yup.string().nullable(),
    core_date: Yup.string().nullable(),
    added_value_date: Yup.string().nullable(),
    painting_date: Yup.string().nullable(),
    press_date: Yup.string().nullable(),
    dlo_date: Yup.string().nullable(),
    edd_date: Yup.string().nullable(),
});

function QuoteForm({
                       quoteId,
                       onSubmit,
                       initialValues,
                       isLoading,
                       isSaving,
                       headerTitle,
                       editableFields,
                       hasFilesToPurge,
                       shouldPersist,
                       formName,
                       headerRightNav,
                       afterFileDelete = () => {
                       },
                   }) {
    const [selectedNav, setNav] = useState('');
    const [currentPage, setPage] = useState('');
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const tab = searchParams.get('tab');
        if (tab) {
            setNav(tab);
        } else {
            setNav('quoteOverview');
        }
    }, []);

    useEffect(() => {
        switch (selectedNav) {
            case 'quoteOverview':
                setPage(
                    <QuoteOverview
                        afterFileDelete={afterFileDelete}
                        validateFieldIsEditable={validateFieldIsEditable}
                        hasFilesToPurge={hasFilesToPurge}
                    />
                );
                break;
            case 'quoteNotes':
                setPage(<QuoteNotes validateFieldIsEditable={validateFieldIsEditable}/>);
                break;
            case 'quoteDoors':
                if (quoteId) {
                    setPage(<DoorsList quoteId={quoteId}/>);
                } else {
                    setNav('quoteOverview');
                    alert('Please save quote before adding doors.');
                }
                break;
            case 'quoteFinancialSummary':
                setPage(<QuoteFinancialSummary/>);
                break;
        }
    }, [selectedNav, editableFields, hasFilesToPurge]);

    function validateFieldIsEditable(fieldName) {
        return editableFields.includes(fieldName);
    }

    function handleNavigation(e) {
        e.preventDefault();
        const selected = e.target.dataset.name;
        setNav(selected);
    }

    return (
        <Formik validationSchema={QuoteSchema} initialValues={initialValues} onSubmit={onSubmit}
                enableReinitialize={true}>
            <div id="content" className="quote-form">
                {shouldPersist && <Persist name={formName}/>}
                <QuoteFormHeader
                    quoteId={quoteId}
                    handleSave={onSubmit}
                    onNavigation={handleNavigation}
                    selectedNav={selectedNav}
                    isSaving={isSaving}
                    headerTitle={headerTitle}
                    rightNav={headerRightNav}
                />
                <LoadingOverlay isLoading={isLoading || isSaving}/>
                <Form>
                    {currentPage}
                    <PromptIfFileChangesUnsaved hasFilesToPurge={hasFilesToPurge}/>
                </Form>
            </div>
        </Formik>
    );
}

export default QuoteForm;
