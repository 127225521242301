import React, { useState } from 'react';

function ChildToggleCell({ row }) {
  const childrenQty = row.subRows.length;

  return (
    <span {...row.getToggleRowExpandedProps()}>
      {row.subRows.length > 0 &&
        !row.isGrouped &&
        (row.isExpanded ? (
          <>
            ({childrenQty}) <span className="material-icons-outlined">expand_more</span>
          </>
        ) : (
          <>
            ({childrenQty}) <span className="material-icons-outlined">chevron_right</span>
          </>
        ))}
    </span>
  );
}

export default ChildToggleCell;
