import React, {useState} from 'react';
import {withBlankOption} from '../../../utils/form-utils';
import {FireGrilleOpening} from './FireGrilleOpening';
import {useFormikContext} from 'formik';
import {useFireConfigOptions} from "../../../hooks/useFireConfigOptions";

export function FireGrilleOpenings() {
    const {values} = useFormikContext();
    const {fireGrilleOpenings} = useFireConfigOptions();
    const [grillesActive, setGrillesActive] = useState(false);
    const options = withBlankOption(fireGrilleOpenings);

    const handleGrillesToggle = e => {
        e.preventDefault();
        setGrillesActive(!grillesActive);
    };

    if (!grillesActive && (values.extra_machining_a_id || values.extra_machining_b_id || values.extra_machining_c_id)) {
        setGrillesActive(true);
    }

    console.log(options);

    return (
        <div className="card mt-0">
            <div className="card-header d-flex align-items-center">
                <h2 className="card-heading mb-0">Grilles & Options</h2>
                <a href="#" className="btn btn-rounded btn-red btn-shadow ml-auto" onClick={handleGrillesToggle}>
                    <span className="material-icons-outlined">{grillesActive ? 'remove' : 'add'}</span>
                </a>
            </div>
            {grillesActive && (
                <div className="card-body">
                    <FireGrilleOpening dropdownOptions={options} order="a"/>
                    <FireGrilleOpening dropdownOptions={options} order="b"/>
                    <FireGrilleOpening dropdownOptions={options} order="c"/>
                </div>
            )}
        </div>
    );
}
