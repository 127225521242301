import React from 'react';
import { useFormikContext } from 'formik';

import OverridableField from 'components/DoorForm/OverridableField';

function PricingOverview({ doorData }) {
  const { values } = useFormikContext();

  return (
    <p>
      <span>Door Cost: ${doorData.cost_price}</span>
      <br />
      <OverridableField
        label="Door Sell Price: $"
        formFieldAccessor={'tipco_calculator_override.sell_price'}
        fieldType={'number'}
        overrideValue={values.tipco_calculator_override.sell_price}
        calculatedValue={doorData.calculated_sell_price}
      />
      <span>Prehang Cost: ${doorData.phang_cost_price || '0'}</span>
      <br />
      <OverridableField
        label="Prehang Sell Price: $"
        formFieldAccessor={'prehang.prehang_override.sell_price'}
        fieldType={'number'}
        overrideValue={values?.prehang?.prehang_override.sell_price ?? 0}
        calculatedValue={doorData.phang_sell_price}
      />
    </p>
  );
}

export default PricingOverview;
