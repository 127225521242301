import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { find } from 'lodash';

import { useDoorConfigOptions } from 'hooks/useDoorConfigOptions';
import PrehangSectionFields from 'components/DoorForm/PrehangSectionFields';

function PrehangSection({ prehangActive, toggleShowPrehangActive, markHingesNeeded }) {
  const { values, setFieldValue } = useFormikContext();
  const { doorJambHandings } = useDoorConfigOptions();

  const handlePrehangToggle = e => {
    let confirmed;
    e.preventDefault();

    if (prehangActive) {
      confirmed = window.confirm(`This will clear the prehang from the door. Continue?`);
    }

    if (confirmed || !prehangActive) {
      const newVal = !prehangActive;
      setFieldValue('prehang_enabled', String(newVal));
      toggleShowPrehangActive(newVal);
    }
  };

  useEffect(() => {
    if (typeof values.prehang === 'undefined') return;

    const jambHanding = find(doorJambHandings, v => v.value == values.prehang.door_jamb_handing_id);

    if (typeof jambHanding === 'undefined') {
      markHingesNeeded(true);
    } else {
      markHingesNeeded(!!jambHanding.doorstop_hinges);
    }
  }, [values.prehang, doorJambHandings]);

  if (values.is_slider) {
    return null;
  }

  return (
    <div className="card mt-0">
      <div className="card-header d-flex align-items-center">
        <h2 className="card-heading mb-0">Prehang Details</h2>
        <a href="#" className="btn btn-rounded btn-red btn-shadow ml-auto" onClick={handlePrehangToggle}>
          <span className="material-icons-outlined">{prehangActive ? 'remove' : 'add'}</span>
        </a>
      </div>
      {prehangActive && <PrehangSectionFields />}
    </div>
  );
}

export default PrehangSection;
