import React from 'react';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import FormHeader from 'components/FormHeader';

import { updateStandaloneItem } from 'utils/horizon-api-client';
import StandaloneItemForm from '../components/StandaloneItemForm/StandaloneItemForm';
import { useStandaloneItem } from '../hooks/useStandaloneItem';
import DoorFormHeaderNav from '../components/DoorForm/DoorFormHeaderNav';

function EditStandaloneItem() {
  const { standaloneItemId } = useParams();
  const { data, status, refetch } = useStandaloneItem({ id: standaloneItemId });
  const isLoading = status === 'loading';

  async function onSubmit(values, actions) {
    const response = await updateStandaloneItem({
      standaloneItemId,
      values,
    });

    actions.setErrors(response.data.errors);
    sendNotification(response);
  }

  function sendNotification(response) {
    switch (response.status) {
    case 200:
      toast.success('Item successfully saved!');
        refetch();
      break;
    case 403:
      toast.error('You do not have permissions to update this item. Please contact an Admin');
        break;
    case 422:
      toast.error('Error saving item.');
      break;
    case 500:
      toast.error('Internal server error.');
        break;
    }
  }

  return (
    <>
      <StandaloneItemForm
        onSubmit={onSubmit}
        initialValues={data}
        isLoading={isLoading}
        quoteId={data.quote_id}
        headerRightNav={<DoorFormHeaderNav isSaving={false} handleSave={onSubmit} />}
        header={
          <FormHeader
            title={
              <>
                <Link to="/standalone_items">
                  <span className="material-icons-outlined">arrow_back</span>
                </Link>
                Edit Item
              </>
            }
          />
        }
      />
    </>
  );
}

export default EditStandaloneItem;
