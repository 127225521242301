import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import OverridableField from 'components/DoorForm/OverridableField';

import useCalculatorOverrideValidations from 'hooks/useCalculatorOverrideValidations';

function PartsTable() {
  const { setFieldValue, values } = useFormikContext();
  const { canOverrideExtraSkins, canOverrideSkins } = useCalculatorOverrideValidations(values);

  useEffect(() => {
    if (!canOverrideExtraSkins) {
      setFieldValue('calculator_override.extra_skins_part_price', 0);
      setFieldValue('calculator_override.extra_skins_quantity', 0);
    }
  }, [canOverrideExtraSkins]);

  useEffect(() => {
    if (!canOverrideSkins) {
      setFieldValue('calculator_override.skins_part_code', '');
      setFieldValue('calculator_override.skins_part_price', 0);
      setFieldValue('calculator_override.skins_quantity', 0);
    }
  }, [canOverrideSkins]);

  return (
    <div id="parts-table" className="col-12 col-sm-12">
      <table className="table table-fixed table-hover">
        <thead>
          <th></th>
          <th>Code</th>
          <th>Unit Price</th>
          <th>Quantity</th>
        </thead>
        <tbody>
          <tr>
            <td>Framing Timber</td>
            <td>{values.calculator && values.calculator.framing_timber.part_code}</td>
            <td>{values.calculator && values.calculator.framing_timber.part_price}</td>
            <td>{values.calculator && values.calculator.framing_timber.quantity}</td>
          </tr>
          <tr>
            <td>Core</td>
            <td>{values.calculator && values.calculator.core.part_code}</td>
            <td>{values.calculator && values.calculator.core.part_price}</td>
            <td>{values.calculator && values.calculator.core.quantity}</td>
          </tr>
          <tr>
            <td>Lockblocks</td>
            <td>{values.calculator && values.calculator.lock_blocks.part_code}</td>
            <td>{values.calculator && values.calculator.lock_blocks.part_price}</td>
            <td>{values.calculator && values.calculator.lock_blocks.quantity}</td>
          </tr>
          <tr className={canOverrideSkins ? 'overridable' : ''}>
            <td>Skin</td>
            <td>
              {canOverrideSkins ? (
                <OverridableField
                  formFieldAccessor={'calculator_override.skins_part_code'}
                  fieldType={'string'}
                  overrideValue={values.calculator_override.skins_part_code}
                  calculatedValue={values.calculator.skins.part_code}
                />
              ) : (
                values.calculator.skins.part_code
              )}
            </td>
            <td>
              {canOverrideSkins ? (
                <OverridableField
                  formFieldAccessor={'calculator_override.skins_part_price'}
                  fieldType={'number'}
                  overrideValue={values.calculator_override.skins_part_price}
                  calculatedValue={values.calculator.skins.part_price}
                />
              ) : (
                values.calculator.skins.part_price
              )}
            </td>
            <td>
              {canOverrideSkins ? (
                <OverridableField
                  formFieldAccessor={'calculator_override.skins_quantity'}
                  fieldType={'number'}
                  overrideValue={values.calculator_override.skins_quantity}
                  calculatedValue={values.calculator.skins.quantity}
                />
              ) : (
                values.calculator.skins.quantity
              )}
            </td>
          </tr>
          <tr className={canOverrideExtraSkins ? 'overridable' : ''}>
            <td>Extra Skin</td>
            <td>{values.calculator && values.calculator.extra_skins.part_code}</td>
            <td>
              {canOverrideExtraSkins ? (
                <OverridableField
                  formFieldAccessor={'calculator_override.extra_skins_part_price'}
                  fieldType={'number'}
                  overrideValue={values.calculator_override.extra_skins_part_price}
                  calculatedValue={values.calculator.extra_skins.part_price}
                />
              ) : (
                values.calculator.extra_skins.part_price
              )}
            </td>
            <td>
              {canOverrideExtraSkins ? (
                <OverridableField
                  formFieldAccessor={'calculator_override.extra_skins_quantity'}
                  fieldType={'number'}
                  overrideValue={values.calculator_override.extra_skins_quantity}
                  calculatedValue={values.calculator.extra_skins.quantity}
                />
              ) : (
                values.calculator.extra_skins.quantity
              )}
            </td>
          </tr>
          <tr>
            <td>Hinge</td>
            <td>
              <OverridableField
                formFieldAccessor={'prehang.prehang_override.hinge_part_code'}
                fieldType={'string'}
                overrideValue={values.prehang.prehang_override.hinge_part_code}
                calculatedValue={values.prehang.hinge_part_code}
              />
            </td>
            <td>
              <OverridableField
                formFieldAccessor={'prehang.prehang_override.hinge_part_price'}
                fieldType={'number'}
                overrideValue={values.prehang.prehang_override.hinge_part_price}
                calculatedValue={values.prehang.hinge_part_price}
              />
            </td>
            <td>
              <OverridableField
                formFieldAccessor={'prehang.prehang_override.hinge_quantity'}
                fieldType={'number'}
                overrideValue={values.prehang.prehang_override.hinge_quantity}
                calculatedValue={values.prehang.hinge_quantity}
              />
            </td>
          </tr>
          <tr>
            <td>Clashing Strip 1</td>
            <td>{values.calculator && values.calculator.clashing_strip_1.part_code}</td>
            <td>{values.calculator && values.calculator.clashing_strip_1.part_price}</td>
            <td>{values.calculator && values.calculator.clashing_strip_1.quantity}</td>
          </tr>
          <tr>
            <td>Clashing Strip 2</td>
            <td>{values.calculator && values.calculator.clashing_strip_2.part_code}</td>
            <td>{values.calculator && values.calculator.clashing_strip_2.part_price}</td>
            <td>{values.calculator && values.calculator.clashing_strip_2.quantity}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default PartsTable;
