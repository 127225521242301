import React, { useMemo, useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';

import { withBlankOption } from 'utils/form-utils';
import { useDoorConfigOptions } from 'hooks/useDoorConfigOptions';
import EditableDropdownInput from 'components/Formik/EditableDropdownInput';
import DropdownInput from 'components/Formik/DropdownInput';
import Input from 'components/Formik/Input';
import Tooltip from 'components/Tooltip';
import { find } from 'lodash';

function CleanFieldOnUnmount({ ...props }) {
  const { setFieldValue } = useFormikContext();
  useEffect(() => () => setFieldValue(props.name, undefined), []);
  return <Field {...props} />;
}

function PrehangSectionFields() {
  const { setFieldValue, values } = useFormikContext();
  const {
    doorJambTimbers,
    doorJambFacings,
    doorJambHandings,
    doorJambProfiles,
    doorJambSills,
    doorJambMachiningCodes,
    doorStops,
    doorDetails,
    hinges,
  } = useDoorConfigOptions();

  const doorJambTimberSelectOptions = useMemo(() => withBlankOption(doorJambTimbers), [doorJambTimbers]);
  const hingeSelectOptions = useMemo(() => withBlankOption(hinges), [hinges]);
  const doorJambFacingSelectOptions = useMemo(() => withBlankOption(doorJambFacings), [doorJambFacings]);
  const doorJambHandingSelectOptions = useMemo(() => {
    const availableHandings = doorJambHandings.filter(handing => handing.door_type === values.door_type);
    return withBlankOption(availableHandings);
  }, [doorJambHandings, values.door_type]);

  const doorJambProfileSelectOptions = useMemo(() => withBlankOption(doorJambProfiles), [doorJambProfiles]);
  const doorJambSillSelectOptions = useMemo(() => withBlankOption(doorJambSills), [doorJambSills]);
  const doorJambMachiningCodeSelectOptions = useMemo(() => withBlankOption(doorJambMachiningCodes), [
    doorJambMachiningCodes,
  ]);
  const doorStopOptions = useMemo(() => withBlankOption(doorStops), [doorStops]);
  const booleanDropdown = [
    { label: 'True', value: 'true' },
    { label: 'False', value: 'false' },
  ];
  const fjclOptions = [
    { label: '', value: '' },
    { label: 'FJ', value: 'FJ' },
    { label: 'CL', value: 'CL' },
  ];

  const [fjclEnabled, setFjClStatus] = useState(false);
  useEffect(() => {
    const timber = find(doorJambTimbers, v => v.value === values.door_jamb_timber_id);

    if (typeof timber !== 'undefined') {
      if (timber.fj_cl === 'A') {
        setFjClStatus(true);
        if (typeof values.prehang !== 'undefined' && values.prehang.manual_fj_cl === '') {
          setFieldValue('prehang.manual_fj_cl', '');
        }
      } else if (timber.fj_cl === 'B') {
        setFjClStatus(false);
        setFieldValue('prehang.manual_fj_cl', 'CL');
      } else if (timber.fj_cl === 'C') {
        setFjClStatus(false);
        setFieldValue('prehang.manual_fj_cl', 'FJ');
      }
    }
  }, [doorJambTimbers, values.door_jamb_timber_id]);

  const [hingeFaceEnabled, setHingeFaceStatus] = useState(false);
  useEffect(() => {
    const jambProfile = find(doorJambProfiles, v => v.value === values.prehang.door_jamb_profile_id);

    if (typeof jambProfile !== 'undefined') {
      if (jambProfile.code === 'SLM') {
        setHingeFaceStatus(false);
        setFieldValue('prehang.manual_reveal_hinge_face', 11);
        setFieldValue('prehang.manual_reveal_non_hinge_face', 11);
      } else {
        setHingeFaceStatus(true);
      }
    }
  }, [doorJambProfiles, values.prehang.door_jamb_profile_id]);

  function setDefaultPrehangDetails(e) {
    if (values.prehang.prehang_details === '') {
      setFieldValue('prehang.prehang_details', values.door_details);
    }
  }

  return (
    <div className="card-body">
      <div className="form-row">
        <div className="col-md-3 col-sm-6">
          <label htmlFor="prehang.standard_jamb">Standard Jamb</label>
          <CleanFieldOnUnmount
            name="prehang.standard_jamb"
            component={DropdownInput}
            options={booleanDropdown}
            as="select"
          >
            <option value=""></option>
          </CleanFieldOnUnmount>
        </div>
        <div className="col-md-3 col-sm-6">
          <label htmlFor="prehang.ph_galv_steel_frame">PH Galv Steel Frame</label>
          <CleanFieldOnUnmount
            name="prehang.ph_galv_steel_frame"
            component={DropdownInput}
            options={booleanDropdown}
            as="select"
          >
            <option value=""></option>
          </CleanFieldOnUnmount>
        </div>
        <div className="col-md-3 col-sm-6">
          <label htmlFor="prehang.number_of_sides">Number of Sides</label>
          <CleanFieldOnUnmount name="prehang.number_of_sides" component={Input} />
        </div>
        <div className="col-md-3 col-sm-6">
          <label htmlFor="prehang.number_of_hinges">Number of Hinges</label>
          <CleanFieldOnUnmount name="prehang.number_of_hinges" component={Input} />
        </div>
      </div>

      <div className="form-row">
        <div className="col-md-4 col-sm-6 clear-placeholder">
          <label htmlFor="prehang.prehang_override.hinge_part_code">Manual Hinge Or Track Part Code</label>
          <Field
              name="prehang.prehang_override.hinge_part_code"
              placeholder={values.prehang.hinge_part_code}
              component={EditableDropdownInput}
              options={hingeSelectOptions}
              as="select"
          ></Field>
        </div>

        <div className="col-md-4 col-sm-6 clear-placeholder">
          <label htmlFor="prehang.prehang_override.hinge_part_price">Hinge Unit Price</label>
          <CleanFieldOnUnmount
            name="prehang.prehang_override.hinge_part_price"
            component={Input}
            placeholder={values.prehang.hinge_part_price}
          />
        </div>

        <div className="col-md-4 col-sm-6 clear-placeholder">
          <label htmlFor="prehang.prehang_override.hinge_quantity">Hinge Qty</label>
          <CleanFieldOnUnmount
            name="prehang.prehang_override.hinge_quantity"
            component={Input}
            placeholder={values.prehang.hinge_quantity}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col-md-3 col-sm-6">
          <div className="form-group">
            <label htmlFor="prehang.door_jamb_facing_id">Jamb Handing</label>
            <CleanFieldOnUnmount
              name="prehang.door_jamb_handing_id"
              component={DropdownInput}
              options={doorJambHandingSelectOptions}
              as="select"
            >
              <option value=""></option>
            </CleanFieldOnUnmount>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <label htmlFor="prehang.jamb_thickness">Jamb Thickness</label>
          <CleanFieldOnUnmount name="prehang.jamb_thickness" component={Input} />
        </div>
        <div className="col-md-3 col-sm-6">
          <label htmlFor="prehang.discount_factor_phang">Discount Factor PHang</label>
          <CleanFieldOnUnmount name="prehang.discount_factor_phang" component={Input} />
        </div>
        <div className="col-md-3 col-sm-6">
          <label htmlFor="prehang.manual_machining_code">Manual Machining Code</label>
          <CleanFieldOnUnmount
            name="prehang.manual_machining_code"
            component={DropdownInput}
            options={doorJambMachiningCodeSelectOptions}
            as="select"
          >
            <option value=""></option>
          </CleanFieldOnUnmount>
        </div>
      </div>

      <div className="form-row">
        <div className="col-md-3 col-sm-6">
          <label htmlFor="prehang.wall_stud_width">Wall Stud Width</label>
          <CleanFieldOnUnmount name="prehang.wall_stud_width" component={Input} />
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="form-group">
            <label htmlFor="prehang.door_jamb_timber_id">Jamb Timber</label>
            <CleanFieldOnUnmount
              name="prehang.door_jamb_timber_id"
              component={DropdownInput}
              options={doorJambTimberSelectOptions}
              as="select"
            >
              <option value=""></option>
            </CleanFieldOnUnmount>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <label htmlFor="prehang.manual_fj_cl">FJ/CL</label>
          <CleanFieldOnUnmount
            name="prehang.manual_fj_cl"
            component={DropdownInput}
            options={fjclOptions}
            disabled={!fjclEnabled}
            as="select"
          >
            <option value=""></option>
          </CleanFieldOnUnmount>
        </div>

        <div className="col-md-3 col-sm-6">
          <label htmlFor="prehang.manual_door_stop_part_code">Manual Door Stop</label>
          <CleanFieldOnUnmount
            name="prehang.manual_door_stop_part_code"
            component={DropdownInput}
            options={doorStopOptions}
            placeholder={values.door_stop_part_code}
            as="select"
          ></CleanFieldOnUnmount>
        </div>
      </div>

      <div className="form-row mt-5">
        <div className="col-md-6 col-sm-12">
          <div className="form-group">
            <label htmlFor="prehang.hinge_face_gib_lining_thickness">Hinge Face Gib Lining Thickness</label>
            <CleanFieldOnUnmount name="prehang.hinge_face_gib_lining_thickness" component={Input} />
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="form-group">
            <label htmlFor="prehang.non_hinge_face_gib_lining_thickness">Non-Hinge Face Gib Lining Thickness</label>
            <CleanFieldOnUnmount name="prehang.non_hinge_face_gib_lining_thickness" component={Input} />
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="col-md-6 col-sm-6">
          <label htmlFor="prehang.manual_reveal_hinge_face">Reveal Hinge Face</label>
          <CleanFieldOnUnmount name="prehang.manual_reveal_hinge_face" component={Input} disabled={!hingeFaceEnabled} />
        </div>
        <div className="col-md-6 col-sm-6">
          <label htmlFor="prehang.manual_reveal_hinge_face">Reveal Non-Hinge Face</label>
          <CleanFieldOnUnmount
            name="prehang.manual_reveal_non_hinge_face"
            component={Input}
            disabled={!hingeFaceEnabled}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col-md-3 col-sm-6">
          <div className="form-group">
            <label htmlFor="prehang.door_jamb_facing_id">Jamb Facing</label>
            <CleanFieldOnUnmount
              name="prehang.door_jamb_facing_id"
              component={DropdownInput}
              options={doorJambFacingSelectOptions}
              as="select"
            >
              <option value=""></option>
            </CleanFieldOnUnmount>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="form-group">
            <label htmlFor="prehang.door_jamb_profile_id">Jamb Profile</label>
            <CleanFieldOnUnmount
              name="prehang.door_jamb_profile_id"
              component={DropdownInput}
              options={doorJambProfileSelectOptions}
              as="select"
            >
              <option value=""></option>
            </CleanFieldOnUnmount>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="form-group">
            <label htmlFor="prehang.door_jamb_sill_id">Jamb Sill</label>
            <CleanFieldOnUnmount
              name="prehang.door_jamb_sill_id"
              component={DropdownInput}
              options={doorJambSillSelectOptions}
              as="select"
            >
              <option value=""></option>
            </CleanFieldOnUnmount>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="form-group">
            <label htmlFor="prehang.door_jamb_floor_clearance">
              Jamb Floor Clearance{' '}
              <Tooltip
                text=<div>
                  <strong>0</strong> - 20MM F/C <br />
                  <strong>990</strong> - NIL F/C <br />
                  <strong>994</strong> - [NOTE:4 SIDED] <br />
                  <strong>995</strong> - [WITH SILL] <br />
                  <strong>997</strong> - [_?_MM F/C] <br />
                  <strong>998</strong> - F/C AS LISTED <br />
                  <strong>999</strong> - BLANK FIELD IN REPORTS
                </div>
              />
            </label>
            <CleanFieldOnUnmount name="prehang.door_jamb_floor_clearance" component={Input} />
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="col-md-12 col-sm-12">
          <div className="form-group">
            <label htmlFor="prehang.prehang_details">Prehang Details</label>
            <CleanFieldOnUnmount
              name="prehang.prehang_details"
              component={EditableDropdownInput}
              placeholder={`${values.door_details || ""} [PLACEHOLDER - CLICK TO SET]`}
              onFocus={setDefaultPrehangDetails}
              options={doorDetails}
              as="textarea"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrehangSectionFields;
