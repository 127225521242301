import React, { useEffect, useState } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { find } from 'lodash';

import DoorExtra from 'components/DoorForm/DoorExtra';
import { useDoorConfigOptions } from 'hooks/useDoorConfigOptions';

function DoorExtrasList({ onDelete }) {
  const { setFieldValue, values } = useFormikContext();
  const { doorExtras } = useDoorConfigOptions();
  const [extrasCount, setExtrasCount] = useState(0);

  useEffect(() => {
    setExtrasCount(values.door_door_extras ? values.door_door_extras.length : 0);
  }, [values.door_door_extras]);

  function getObjData(id) {
    const data = find(doorExtras, obj => obj.value === id);
    return data || {};
  }

  function addPanel(e, arrayHelpers) {
    e.preventDefault();
    arrayHelpers.push({
      qty: 1,
    });
  }

  function removeObj(e, index, arrayHelpers) {
    e.preventDefault();
    if (typeof onDelete === 'function') {
      onDelete(values.door_door_extras[index]);
    }
    const extra = values.door_door_extras[index];

    if (extra._destroy) {
      delete extra._destroy;
      setFieldValue(`door_door_extras[${index}]`, extra);
    } else if (typeof extra.id !== 'undefined') {
      setFieldValue(`door_door_extras[${index}]`, Object.assign(extra, { _destroy: true }));
    } else {
      arrayHelpers.remove(index);
    }

    setExtrasCount(extrasCount - 1);
  }

  function duplicateObj(e, index, arrayHelpers) {
    e.preventDefault();
    const newPanel = Object.assign({}, values.door_door_extras[index]);
    newPanel.id = null;
    arrayHelpers.push(newPanel);
  }

  function hasPendingRemovals() {
    if (typeof values.door_door_extras === 'undefined') return false;
    const keys = values.door_door_extras.map(dde => Object.keys(dde));
    return keys.flat().includes('_destroy');
  }

  return (
    <FieldArray
      name="door_door_extras"
      render={arrayHelpers => (
        <div className="card mt-0">
          <div className="card-header d-flex align-items-center">
            <h2 className="card-heading mb-0">
              Door Extras ({extrasCount})
              {hasPendingRemovals() && (
                <span className="pending-removals pl-2"> has pending extra removals. Save to update</span>
              )}
            </h2>
            <a
              href="#"
              className="btn btn-rounded btn-red btn-shadow ml-auto"
              onClick={e => {
                addPanel(e, arrayHelpers);
              }}
            >
              <span className="material-icons-outlined">add</span>
            </a>
          </div>

          <div className="card-body">
            {values.door_door_extras && values.door_door_extras.length > 0
              ? values.door_door_extras.map((obj, index) => (
                  <DoorExtra
                  index={index}
                  key={index}
                  doorDoorExtra={obj}
                  onRemove={e => {
                    removeObj(e, index, arrayHelpers);
                  }}
                  onDuplicate={e => {
                    duplicateObj(e, index, arrayHelpers);
                  }}
                  objectData={getObjData(obj.door_extra_id)}
                  />
              ))
              : ''}
          </div>
        </div>
      )}
    />
  );
}

export default DoorExtrasList;
