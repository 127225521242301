import React, {useState} from "react"
import {getXeroLoginUrl} from "../../utils/horizon-api-client";
import {syncWithXero} from "../../utils/xero";

function XeroSendButton({quoteId}) {
    const [loading, setLoading] = useState(false)

    async function triggerLogin() {
        const redirectUrl = await getXeroLoginUrl()
        window.location = `${redirectUrl.data.url}&state=${quoteId}`
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        if (loading) return

        setLoading(true);
        const confirmed = window.confirm(
            `This will send Quote ${quoteId} to Xero. Continue?`
        );

        if (confirmed) {
            await syncWithXero(quoteId, triggerLogin);
        }

        setLoading(false)
    }

    return <>
        <li className="nav-item">
            <a href="" className="nav-btn btn-fill" onClick={onSubmit}>
                <>
                    <span className="material-icons-outlined">receipt</span>
                    <span>{loading ? "Sending" : "Send To Xero"}</span>
                </>

            </a>
        </li>
    </>
}

export default XeroSendButton