import React, {useEffect, useMemo, useState} from 'react';
import {Field, useFormikContext} from 'formik';
import {find, some} from 'lodash';
import {useLocation, useParams} from 'react-router-dom';

import {useJobs} from 'hooks/useJobs';
import {useQuote} from 'hooks/useQuote';
import {useCustomers} from 'hooks/useCustomers';

import Input from 'components/Formik/Input';
import DropdownInput from 'components/Formik/DropdownInput';
import DatePickerInput from 'components/Formik/DatePickerInput';
import FileUploads from 'components/FileUploads';

function QuoteOverview({afterFileDelete, validateFieldIsEditable, hasFilesToPurge}) {
    const {jobs, status: jobsFetchStatus} = useJobs();
    const [showExpiredError, setShowExpiredError] = useState(false);

    const {customers} = useCustomers();
    const location = useLocation();
    const {id} = useParams();
    const {data: quoteData} = useQuote({id: id});

    function findContactByKind(contactable, kind) {
        if (typeof contactable === 'undefined') return null;
        return contactable.contacts.find(contact => contact.kind === kind);
    }

    function formattedContact(contact) {
        if (!contact) return null;
        return contact.email;
    }

    const primaryCustomerContact = useMemo(() => {
        if (quoteData) {
            return findContactByKind(quoteData.customer, 'primary');
        }
        return null;
    }, [quoteData]);
    const accountCustomerContact = useMemo(() => {
        if (quoteData) {
            return findContactByKind(quoteData.customer, 'account');
        }
        return null;
    }, [quoteData]);

    const statusOptions = [
        {value: 'draft', label: 'Draft'},
        {value: 'sent', label: 'Sent'},
        {value: 'ordered', label: 'Ordered'},
        {value: 'ready_for_dispatch', label: 'Ready for Dispatch'},
        {value: 'dispatched', label: 'Dispatched'},
        {value: 'invoiced', label: 'Invoiced'},
        {value: 'cancelled', label: 'Cancelled'},
    ];

    const freightChargeOptions = [
        {
            label: 'Delivery - $110',
            value: {description: 'Delivery', cost: '110.0'},
        },
        {
            label: 'Customer Pickup',
            value: {description: 'Customer Pickup', cost: '0.0'},
        },
    ];

    const jobOptions = useMemo(() => {
        return jobs.map(job => {
            return {
                value: job.id,
                label: job.name,
                address_line_1: job.address.line_1,
            };
        });
    }, [jobs]);

    const customerOptions = useMemo(() => {
        return customers.map(customer => {
            return {value: customer.id, label: customer.company_name};
        });
    }, [customers]);

    const {
        values: {job_id, files, status: quoteStatus},
        setFieldValue,
    } = useFormikContext();

    const hasUnsavedFileChanges = useMemo(() => {
        return some(files, file => typeof file.id === 'undefined') || hasFilesToPurge;
    }, [files, hasFilesToPurge]);

    function filterJobsWithAddress(candidate, input) {
        const label = candidate.data.label.toLowerCase();
        const filterableAddress =
            typeof candidate.data.address_line_1 === 'undefined' ? '' : candidate.data.address_line_1.toLowerCase();
        const filterableInput = input.toLowerCase();

        if (input === '') return true;
        if (label.startsWith(filterableInput)) return true;
        if (filterableAddress.includes(filterableInput)) return true;
        return false;
    }

    function getJobAddressById(id) {
        const job = find(jobs, job => job.id === id);
        if (typeof job === 'undefined') return '';
        if (typeof job.address === 'undefined') return 'No address specified';

        return `${job.address.line_1 || ''}, ${job.address.line_2 || ''}, ${job.address.city || ''}, ${
            job.address.post_code || ''
        }`;
    }

    useEffect(() => {
        const expired = Date.parse(quoteData.valid_til) < Date.now();

        if (expired && (quoteData.status === 'draft' || quoteData.status === 'cancelled')) {
            setShowExpiredError(true);
        } else {
            setShowExpiredError(false);
        }
    }, [quoteData]);

    useEffect(() => {
        if (jobsFetchStatus !== 'loading') {
            setFieldValue('_jobAddress', getJobAddressById(job_id));
        }
    }, [job_id, jobsFetchStatus]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const jobId = searchParams.get('jobId');
        if (jobId) {
            setFieldValue('job_id', Number(jobId));
        }
    }, []);

    return (
        <div className="card card-top card-top-big px-5 py-5">
            <div className="form-row">
                <div className="col-md-3 col-lg-3 col-sm-3">
                    <div className="form-group">
                        <label htmlFor="order_no">Order No</label>

                        <Field
                            name="order_no"
                            className="form-control"
                            placeholder="Order Number"
                            component={Input}
                            disabled={!validateFieldIsEditable('order_no')}
                        />
                    </div>
                </div>
                <div className="col-md-3 col-lg-3 col-sm-3">
                    <div className="form-group">
                        <label htmlFor="job_id">Job Name</label>

                        <Field
                            name="job_id"
                            placeholder="Job Name"
                            component={DropdownInput}
                            options={jobOptions}
                            customFilter={filterJobsWithAddress}
                            as="select"
                            disabled={!validateFieldIsEditable('job_id')}
                        >
                            <option value=""></option>
                            {jobs.map(job => {
                                return (
                                    <option value={job.id} key={job.id}>
                                        {job.name}
                                    </option>
                                );
                            })}
                        </Field>
                    </div>
                </div>
                <div className="col-md-6 col-lg-6 col-sm-6">
                    <div className="form-group">
                        <label htmlFor="job-address">Job Address</label>
                        <Field name="_jobAddress" className="form-control" component={Input} disabled/>
                    </div>
                </div>
            </div>
            <div className="form-row">
                <div className="col-sm-12 col-md-6 col-lg-6 px-0">
                    <div className="form-row mt-0">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label htmlFor="quote_no">Quote No</label>
                                <Field name="quote_no" options={statusOptions} component={Input} disabled/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label htmlFor="quote_date">Date</label>
                                <Field
                                    name="quote_date"
                                    placeholder="Date"
                                    component={DatePickerInput}
                                    disabled={!validateFieldIsEditable('quote_date')}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6 px-0">
                    <div className="form-row mt-0">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="form-group">
                                <label htmlFor="status">Status</label>
                                <Field
                                    name="status"
                                    options={statusOptions}
                                    component={DropdownInput}
                                    disabled={!validateFieldIsEditable('status')}
                                />
                                {showExpiredError && (
                                    <span className="input-error">Quote is expired. Re-check pricing and update expiry date</span>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <div className="form-group">
                                <label htmlFor="sales-person">Sales Person</label>
                                <Field name="_salesPerson" className="form-control" component={Input} disabled/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-row">
                <div className="col-md-3 col-lg-3">
                    <div className="form-group">
                        <label htmlFor="customer_id">Customer</label>
                        <Field
                            name="customer_id"
                            placeholder="Customer"
                            component={DropdownInput}
                            options={customerOptions}
                            as="select"
                            disabled={!validateFieldIsEditable('customer_id')}
                        >
                            <option value=""></option>
                            {customers.map(customer => {
                                return (
                                    <option value={customer.id} key={customer.id}>
                                        {customer.company_name}
                                    </option>
                                );
                            })}
                        </Field>
                    </div>
                </div>
                <div className="col-md-3 col-lg-3">
                    <div className="form-group">
                        <label htmlFor="tagged">Tagged Quote</label>
                        <div className="select-box">
                            <Field name="tagged" as="select" disabled={!validateFieldIsEditable('tagged')}>
                                <option value="true">True</option>
                                <option value="false">False</option>
                            </Field>
                        </div>
                    </div>
                </div>

                <div className="col-md-3 col-lg-3">
                    <div className="form-group">
                        <label htmlFor="reprint_on">Printed On</label>
                        <Field
                            name="reprint_on"
                            placeholder="Date"
                            component={DatePickerInput}
                            disabled={!validateFieldIsEditable('reprint_on')}
                        />
                    </div>
                </div>
                <div className="col-md-3 col-lg-3">
                    <div className={`form-group ${showExpiredError ? 'invalid-input' : ''}`}>
                        <label htmlFor="">Quote Valid Til</label>
                        <Field
                            name="valid_til"
                            placeholder="Date"
                            component={DatePickerInput}
                            disabled={!validateFieldIsEditable('valid_til')}
                        />
                    </div>
                </div>
            </div>

            <div className="form-row">
                <div className="col-md-3 col-lg-3">
                    <div className="form-group">
                        <label htmlFor="reprint_on">Customer Primary Contact</label>
                        <Field name="reprint_on" value={formattedContact(primaryCustomerContact)} disabled
                               component={Input}/>
                    </div>
                </div>
                <div className="col-md-3 col-lg-3">
                    <div className="form-group">
                        <label htmlFor="reprint_on">Customer Account Contact</label>
                        <Field name="reprint_on" value={formattedContact(accountCustomerContact)} disabled
                               component={Input}/>
                    </div>
                </div>
            </div>

            {quoteStatus === 'ordered' && (
                <>
                    <div className="form-row">
                        <div className="col-md-3 col-lg-3">
                            <div className="form-group">
                                <label htmlFor="core_date">Core Date</label>
                                <Field
                                    name="core_date"
                                    placeholder="Date"
                                    component={DatePickerInput}
                                    disabled={!validateFieldIsEditable('core_date')}
                                />
                            </div>
                        </div>
                        <div className="col-md-3 col-lg-3">
                            <div className="form-group">
                                <label htmlFor="">Press Date</label>
                                <Field
                                    name="press_date"
                                    placeholder="Date"
                                    component={DatePickerInput}
                                    disabled={!validateFieldIsEditable('press_date')}
                                />
                            </div>
                        </div>
                        <div className="col-md-3 col-lg-3">
                            <div className="form-group">
                                <label htmlFor="">DLO Date</label>
                                <Field
                                    name="dlo_date"
                                    placeholder="Date"
                                    component={DatePickerInput}
                                    disabled={!validateFieldIsEditable('dlo_date')}
                                />
                            </div>
                        </div>
                        <div className="col-md-3 col-lg-3">
                            <div className="form-group">
                                <label htmlFor="">Painting Date</label>
                                <Field
                                    name="painting_date"
                                    placeholder="Date"
                                    component={DatePickerInput}
                                    disabled={!validateFieldIsEditable('painting_date')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-6 col-lg-6">
                            <div className="form-group">
                                <label htmlFor="">Added Value Date</label>
                                <Field
                                    name="added_value_date"
                                    placeholder="Date"
                                    component={DatePickerInput}
                                    disabled={!validateFieldIsEditable('added_value_date')}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <div className="form-group">
                                <label htmlFor="">EDD Date</label>
                                <Field
                                    name="edd_date"
                                    placeholder="Date"
                                    component={DatePickerInput}
                                    disabled={!validateFieldIsEditable('edd_date')}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}

            <FileUploads afterFileDelete={afterFileDelete} hasUnsavedFileChanges={hasUnsavedFileChanges}/>
        </div>
    );
}

export default QuoteOverview;
