import React from 'react';
import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import FormHeader from 'components/FormHeader';
import { createComponent } from 'utils/horizon-api-client';
import { useComponentPolicy } from '../policies/useComponentPolicy';
import ComponentForm from '../components/ComponentForm';

function NewComponent() {
  const history = useHistory();
  const { canCreate } = useComponentPolicy();

  const initialValues = {
    part_no: '',
    part_description: '',
    supplier: '',
    cost_price: 0,
  };

  async function onSubmit(values, actions) {
    const response = await createComponent({
      values: values,
    });

    actions.setErrors(response.data.errors);
    sendNotification(response);

    if (response.status === 200) {
      actions.resetForm();
      redirectToComponentsList(response.data.id);
    }
  }

  function redirectToComponentsList() {
    history.push('/components');
  }

  function sendNotification(response) {
    switch (response.status) {
      case 200:
      toast.success('Component successfully saved!');
        break;
    case 403:
      toast.error('You do not have permissions to create a component. Please contact an Admin');
      break;
    case 422:
        toast.error('Error saving component.');
      break;
    case 500:
      toast.error('Internal server error.');
        break;
    }
  }

  return (
    <>
      <ComponentForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        isLoading={false}
        shouldPersist={true}
        formName="new-component-form"
        header={
          <FormHeader
            title={
              <>
                <Link to="/components">
                  <span className="material-icons-outlined">arrow_back</span>
                </Link>
                New Component
              </>
            }
            canCreate={canCreate}
          />
        }
      />
    </>
  );
}

export default NewComponent;
