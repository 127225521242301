import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { getStandaloneItem } from 'utils/horizon-api-client';

export function useStandaloneItem({ id }) {
  const { data, status, refetch } = useQuery(['standaloneItem', id], fetchStandaloneItemById, { enabled: id !== null });
  const standaloneItemData = useMemo(mapData, [data]);

  async function fetchStandaloneItemById(key, id) {
    const response = await getStandaloneItem({ id: id });
    return response;
  }

  function mapData() {
    if (typeof data === 'undefined') return {};

    return {
      id: data.id,
      serial: data.serial,
      contribution_factor: data.contribution_factor,
      deleted_at: data.deleted_at,
      notes: data.notes,
      position: data.position,
      reference: data.reference,
      cost_price_cents: data.cost_price_cents || 0,
      cost: (data.cost_price_cents / 100).toFixed(2),
      sell_price_cents: data.sell_price_cents || 0,
      sell: (data.sell_price_cents / 100).toFixed(2),
      created_at: data.created_at,
      updated_at: data.updated_at,
      quote_id: data.quote_id,
      standalone_item_components: data.standalone_item_components.map(c => {
        return {
          ...c,
          cost: (c.cost_cents / 100).toFixed(2),
          sell: (c.sell_cents / 100).toFixed(2),
        };
      }),
      door_door_extras: data.door_door_extras.map(c => {
        return {
          ...c,
          cost: (c.cost_cents / 100).toFixed(2),
          sell: (c.sell_cents / 100).toFixed(2),
        };
      }),
    };
  }

  return {
    data: standaloneItemData,
    status,
    refetch,
  };
}
