import React from 'react';
import {useInfiniteIndexData} from 'hooks/useInfiniteIndexData';

import QuoteUtilities from 'components/QuotesList/QuoteUtilities';
import QuoteStatusButton from 'components/QuoteStatusButton';

import moment from 'moment';

export function useQuotes({requestData, nestedResourceId, prefilter = ''}) {
    const searchOptions = {
        quote_no: {
            name: 'quote_no',
            description: 'Quote number contains value',
            aliases: ['id', 'quote', 'number', 'no'],
            predicate: 'cont_any',
            displayName: 'Quote No.',
            defaultSearchTerm: 'quote:',
        },
        company_name: {
            name: 'customer_company_name',
            description: 'Customer company name contains value',
            aliases: ['customer', 'cust', 'company_name', 'customer_name'],
            predicate: 'cont_any',
            displayName: 'Customer Name',
            defaultSearchTerm: 'customer:',
        },
        sales_person: {
            name: 'user_name',
            description: 'Sales Person name contains value',
            aliases: ['sales', 'sales person', 'salesperson', 'sales_person', 'user'],
            predicate: 'cont_any',
            displayName: 'Sales Person',
            defaultSearchTerm: 'sales person:',
        },
        status: {
            name: 'status',
            aliases: ['st', 'stat', 'status'],
            predicate: 'cont_any',
            displayName: 'Status',
            defaultSearchTerm: 'status:',
        },
        job_name: {
            name: 'job_name',
            description: 'Find quotes associated with job name',
            aliases: ['job', 'job_name'],
            predicate: 'cont_any',
            displayName: 'Job Name',
            defaultSearchTerm: 'job:',
        },
        last_updated_gt: {
            name: 'updated_at',
            aliases: ['updated', 'last_updated'],
            predicate: 'gteq',
            displayName: 'Last Updated (after)',
            defaultSearchTerm: 'updated>',
        },
        last_updated_lt: {
            name: 'updated_at',
            aliases: ['updated', 'last_updated'],
            predicate: 'lteq',
            displayName: 'Last Updated (before)',
            defaultSearchTerm: 'updated<',
        },
        other: {
            name: 'customer_company_name',
            aliases: [],
            predicate: 'cont_any',
        },
    };

    const {
        fetchMore,
        canFetchMore,
        recordData,
        totalCount,
        searchFieldValue,
        setSearchTerm,
        status,
    } = useInfiniteIndexData({
        requestData,
        searchOptions,
        nestedResourceId,
        perPage: 25,
        mapRecordToTable: mapQuoteToTable,
        prefilter,
    });

    function mapQuoteToTable(quote) {
        return {
            quote_no: quote.quote_no,
            description: quote.description,
            order_no: quote.order_no || '',
            customer_name: quote.customer.company_name,
            total_doors: quote.total_doors,
            total_cost: (quote.total_cost_cents / 100).toFixed(2),
            total_sale_price: (quote.total_sale_price_cents / 100).toFixed(2),
            sales_person: quote.user.name,
            core_date: quote.core_date,
            job_link: <a href={`/jobs/${quote.job.id}/edit`}>{quote.job.name}</a>,
            added_value_date: quote.added_value_date,
            painting_date: quote.painting_date,
            press_date: quote.press_date,
            dlo_date: quote.dlo_date,
            edd_date: quote.edd_date,
            valid_til: quote.valid_til,
            is_expired: quote.is_expired,

            last_updated: moment(quote.updated_at).fromNow(),
            last_updated_at: quote.updated_at,
            status: <QuoteStatusButton status={quote.status}/>,
            dropdown: <QuoteUtilities quote={quote}/>,
        };
    }

    return {
        fetchMore: fetchMore,
        canFetchMore: canFetchMore,
        quotes: recordData,
        totalQuotes: totalCount,
        status: status,
        searchOptions: searchOptions,
        searchFieldValue: searchFieldValue,
        setSearchTerm: setSearchTerm,
    };
}
