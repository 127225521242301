import React, { useRef } from 'react';

function EditableCell({ value: initialValue, row: { original }, column, onDataUpdate }) {
  const [value, setValue] = React.useState(initialValue);
  const [prevValue, setPrevValue] = React.useState(initialValue);
  const cellInput = useRef();

  const onChange = e => {
    setValue(e.target.value);
  };

  const onKeyDown = e => {
    if (e.keyCode === 13) {
      cellInput.current.blur();
    }
  };

  const onBlur = () => {
    handleDataUpdate();
  };

  const handleDataUpdate = () => {
    if (value !== prevValue) {
      onDataUpdate({
        rowData: original,
        newValue: value,
        column: column,
      });
      setPrevValue(value);
    }
  };

  React.useEffect(() => {
    setValue(initialValue);
    setPrevValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    setValue(initialValue);
    setPrevValue(initialValue);
  }, [original]);

  // return null if row is a group header
  if (typeof original === 'undefined' || original.isGrouped) {
    return null;
  }

  return (
    <input
      className={`${column.id}-editable`}
      ref={cellInput}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      style={{ border: 0, width: '30px' }}
    />
  );
}

export default EditableCell;
