import React, { createContext, useContext, useEffect, useState } from 'react';
import { clearToken, getToken, validateToken } from 'utils/auth-client';
import { AUTH_FAILURE, AUTH_PENDING, AUTH_SUCCESS } from 'constants/auth';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setUser] = useState();
  const [authValidationStatus, setAuthValidationStatus] = useState(AUTH_PENDING);

  function onSuccessfulValidation(user) {
    setUser(user);
    setAuthValidationStatus(AUTH_SUCCESS);
  }

  function onFailedValidation() {
    setAuthValidationStatus(AUTH_FAILURE);
    clearToken();
  }

  function isLoggedIn() {
    if (typeof currentUser === 'undefined') {
      return false;
    } else {
      return true;
    }
  }

  function logout() {
    setUser();
    clearToken();
    return true;
  }

  useEffect(() => {
    if (getToken()) {
      validateToken({
        onSuccessfulValidation: onSuccessfulValidation,
        onFailedValidation: onFailedValidation,
      });
    } else {
      setAuthValidationStatus(AUTH_FAILURE);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: currentUser,
        userRole: currentUser ? currentUser.role : '',
        authValidationStatus: authValidationStatus,
        isLoggedIn: isLoggedIn,
        logout: logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}
