import React from 'react';
import { ErrorMessage } from 'formik';
import Select, { createFilter } from 'react-select';
import { isEqual } from 'lodash';

const Input = ({ field, form, options, afterChange, customFilter, ...props }) => {
  const meta = form.getFieldMeta(field.name);
  const hasError = typeof meta.error !== 'undefined';
  const shouldDisplayError = hasError;

  function filterFunction(candidate, input) {
    let filter;
    if (typeof customFilter === 'function') {
      filter = customFilter;
    } else {
      filter = createFilter({ matchFrom: 'start' });
    }
    return filter(candidate, input);
  }

  function onChange(option) {
    form.setFieldValue(field.name, option.value);

    if (typeof afterChange === 'function') {
      afterChange({ value: option.value });
    }
  }

  return (
    <>
      <Select
        options={options}
        name={field.name}
        placeholder={props.placeholder ? props.placeholder : 'Select...'}
        value={options ? options.find(option => isEqual(option.value, field.value)) : ''}
        filterOption={filterFunction}
        onChange={option => onChange(option)}
        onBlur={field.onBlur}
        isDisabled={props.disabled}
        className={shouldDisplayError ? 'dropdownInput-wrapper invalid-input' : 'dropdownInput-wrapper'}
      />
      {shouldDisplayError && (
        <span className="input-error">
          <ErrorMessage name={field.name} />
        </span>
      )}
    </>
  );
};

export default Input;
