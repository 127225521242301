import React, { useMemo } from "react";

export function useSearchHelperOptions({ searchOptions }) {
  const searchHelperOptions = useMemo(() => {
    const opts = [];
    Object.keys(searchOptions).forEach((optionName) => {
      const option = searchOptions[optionName];
      opts.push({
        optionKey: optionName,
        name: option.name,
        description: option.description,
        aliases: option.aliases,
        predicate: option.predicate,
        displayName: option.displayName,
        defaultSearchTerm: option.defaultSearchTerm,
      });
    });
    return opts;
  }, []);

  return {
    searchHelperOptions,
  };
}
