import React from "react";
import { Link } from "react-router-dom";
import { getUsers } from "utils/horizon-api-client";
import { useInfiniteIndexData } from "hooks/useInfiniteIndexData";

export function useUsers() {
  const { recordData, totalCount, status } = useInfiniteIndexData({
    perPage: 50,
    requestData: getUsers,
    searchOptions: [],
    mapRecordToTable: mapUserToTable,
  });

  function mapUserToTable(user) {
    return {
      id: user.id,
      name: user.name,
      email: user.email,
      role: user.role,
      extras: (
        <Link to={`/users/${user.id}/edit`}>
          <span className="material-icons icon-grey">edit</span>
        </Link>
      ),
    };
  }

  return {
    users: recordData,
    totalUsers: totalCount,
    status: status,
  };
}
