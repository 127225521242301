import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { getDoorExtra } from 'utils/horizon-api-client';

export function useDoorExtra({ id }) {
  const { data, status, refetch } = useQuery(['door_extra', id], fetchDoorExtraById);
  const doorExtraData = useMemo(mapDoorExtraData, [data]);

  async function fetchDoorExtraById(key, id) {
    const response = await getDoorExtra({ id: id });
    return response;
  }

  function mapDoorExtraData() {
    if (typeof data === 'undefined') return {};

    return {
      id: data.id,
      code: data.code,
      supplier: data.code,
      full_description: data.full_description,
      cost: (data.cost_cents / 100).toFixed(2),
      sell: (data.sell_cents / 100).toFixed(2),
    };
  }

  return {
    data: doorExtraData,
    status,
    refetch,
  };
}
