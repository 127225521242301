import React from 'react';
import Modal from 'react-bootstrap/Modal';

function ModalWrapper({ title, show, onHide, onShow, size = 'xl', ...props }) {
  return (
    <Modal {...props} size={size} show={show} onHide={onHide} onShow={onShow} enforceFocus={false} fullscreen={false}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
    </Modal>
  );
}

export default ModalWrapper;
