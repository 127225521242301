import React, {useMemo} from 'react';

import DoorsTableHeader from 'components/DoorsList/DoorsTableHeader';
import IndexTable from 'components/IndexTable';
import IndexTableWrapper from 'components/IndexTableWrapper';
import EditableCell from 'components/EditableCell';
import ChildToggleCell from 'components/DoorsList/ChildToggleCell';

import {updateDoor, updateFireDoor, updateStandaloneItem, updateTipcoDoor} from 'utils/horizon-api-client';
import {toast} from 'react-toastify';

import 'assets/stylesheets/doorsList.css';

import {useDoors} from 'hooks/useDoors';
import {useTipcoDoors} from 'hooks/useTipcoDoors';
import {useFireDoors} from 'hooks/useFireDoors';
import {useSearchHelperOptions} from 'hooks/useSearchHelperOptions';
import FreightItemsList from '../FreightItemsList/FreightItemsList';
import {useStandaloneItems} from '../../hooks/useStandaloneItems';

/**
 *
 * @param {number} quoteId
 * @returns {JSX.Element}
 * @constructor
 */
function DoorsList({quoteId}) {
    const {
        refetch: refetchStandardDoors,
        doors: standardDoors,
        totalDoors: totalStandardDoors,
        setSearchTerm: setStandardDoorsSearchTerm,
        searchOptions,
        status,
        searchFieldValue,
    } = useDoors({quoteId});
    const {
        doors: tipcoDoors,
        totalDoors: totalTipcoDoors,
        refetch: refetchTipcoDoors,
        setSearchTerm: setTipcoDoorsSearchTerm,
    } = useTipcoDoors({quoteId});
    const {
        doors: fireDoors,
        totalDoors: totalFireDoors,
        refetch: refetchFireDoors,
        setSearchTerm: setFireDoorsSearchTerm,
    } = useFireDoors({quoteId});
    const {standaloneItems, totalStandaloneItems, refetch: refetchStandaloneItems} = useStandaloneItems({quoteId});

    const {searchHelperOptions} = useSearchHelperOptions({searchOptions});

    const doors = useMemo(() => [...standardDoors, ...tipcoDoors, ...fireDoors, ...standaloneItems], [
        standardDoors,
        tipcoDoors,
        fireDoors,
        standaloneItems,
    ]);

    const setSearchTerm = useMemo(
        () => searchTerm => {
            setStandardDoorsSearchTerm(searchTerm);
            setTipcoDoorsSearchTerm(searchTerm);
            setFireDoorsSearchTerm(searchTerm);
        },
        [setStandardDoorsSearchTerm, setTipcoDoorsSearchTerm, setFireDoorsSearchTerm]
    );

    const refetchAllDoors = async () => {
        refetchStandardDoors();
        refetchTipcoDoors();
        refetchFireDoors();
        refetchStandaloneItems();
    };

    const initialTableState = {
        sortBy: [
            {
                id: 'position',
                desc: false,
            },
        ],
        groupBy: ['door_class'],
        expanded: {
            'door_class:FIRE': true,
            'door_class:TIPCO': true,
            'door_class:Standard': true,
            'door_class:Misc': true,
        },
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Duplicates ',
                id: 'expander',
                Cell: ChildToggleCell,
            },
            {
                Header: 'Class ',
                accessor: 'door_class',
                className: 'v-sm-visible',
            },
            {
                Header: 'Sort ',
                Cell: EditableCell,
                accessor: 'position',
                className: 'v-sm-visible',
            },
            {
                Header: 'Serial ',
                accessor: 'serial',
                className: 'v-sm-visible',
            },
            {
                Header: 'Door Tp ',
                accessor: 'leaf_type',
                className: 'v-sm-visible',
            },
            {
                Header: 'Jamb Tp ',
                accessor: 'jamb_type',
                className: 'v-sm-visible',
            },
            {
                Header: 'Hgt ',
                accessor: 'height',
                className: 'v-sm-visible',
            },
            {
                Header: 'Wth ',
                accessor: 'width',
                className: 'v-sm-visible',
            },
            {
                Header: 'Wth2 ',
                accessor: 'width_pair',
                className: 'v-sm-visible',
            },
            {
                Header: 'Thk ',
                accessor: 'thickness',
                className: 'v-sm-visible',
            },
            {
                Header: 'Skn ',
                accessor: 'skin_shortcode',
                className: 'v-sm-visible',
            },
            {
                Header: 'Core ',
                accessor: 'core_shortcode',
                className: 'v-sm-visible',
            },
            {
                Header: 'PH ',
                accessor: 'ph',
                className: 'v-sm-visible ph',
            },
            {
                Header: 'Extras ',
                accessor: 'attached_item_counts',
                className: 'v-sm-visible ph',
            },
            {
                Header: 'Clash Detail ',
                accessor: 'clashing_detail',
                className: 'v-sm-visible',
            },
            {
                Header: 'FRR ',
                accessor: 'frr',
                className: 'v-sm-visible',
            },
            {
                Header: 'Ref. ',
                Cell: EditableCell,
                accessor: 'reference',
            },
            {
                Header: 'Sell ',
                accessor: 'sell_price_with_phang',
            },
            {
                Header: '',
                accessor: 'dropdown',
                disableSortBy: true,
            },
        ],
        []
    );

    async function onDataUpdate({rowData, newValue, column}) {
        let response, updateFunction;
        if (rowData.door_class === 'Standard') {
            updateFunction = updateDoor;
        } else if (rowData.door_class === 'TIPCO') {
            updateFunction = updateTipcoDoor;
        } else if (rowData.door_class === 'FIRE') {
            updateFunction = updateFireDoor;
        } else if (rowData.door_class === 'Misc') {
            updateFunction = updateStandaloneItem;
        }

        if (column.id === 'position') {
            response = await updateFunction({id: rowData.id, values: {position: newValue}});
        } else if (column.id === 'reference') {
            response = await updateFunction({id: rowData.id, values: {reference: newValue}});
        }

        if (typeof response.status !== 'undefined') {
            sendNotification(response);
            refetchAllDoors();
        }
    }

    function sendNotification(response) {
        if (response.status === 200) {
            toast.success('Updated!');
        } else if (response.status === 403) {
            toast.error('You do not have permissions to update this door');
        } else if (response.status === 500) {
            toast.error('Internal server error');
        } else {
            toast.error('Error updating door');
        }
    }

    function isSubRow(row) {
        return !row.isGrouped && typeof row.original.parent_id !== 'undefined' && row.original.parent_id !== null;
    }

    function getRowClassName(row) {
        let className = '';
        if (isSubRow(row)) {
            className += 'sub-row';
        }
        if (row.isGrouped) {
            className += ' group-header';
        }
        return className;
    }

    return (
        <>
            <div id="doors-list" className="card-top-big content-main-small">
                <IndexTableWrapper>
                    <DoorsTableHeader
                        setSearchTerm={setSearchTerm}
                        searchFieldValue={searchFieldValue}
                        searchHelperOptions={searchHelperOptions}
                        resourceName="Door"
                        onDataUpdate={refetchAllDoors}
                    />
                    <IndexTable
                        columns={columns}
                        data={doors}
                        status={status}
                        onDataUpdate={onDataUpdate}
                        initialState={initialTableState}
                        groupRowMetadata={{
                            FIRE: {
                                count: totalFireDoors,
                            },
                            Standard: {
                                count: totalStandardDoors,
                            },
                            TIPCO: {
                                count: totalTipcoDoors,
                            },
                            Misc: {
                                count: totalStandaloneItems,
                            },
                        }}
                        rowProps={row => ({
                            className: getRowClassName(row),
                            key: row.values.id,
                        })}
                    />
                    <span className="table-footer-label">
            Showing {totalStandardDoors + totalTipcoDoors + totalFireDoors + totalStandaloneItems} doors
          </span>
                </IndexTableWrapper>
            </div>
            <FreightItemsList quoteId={quoteId}/>
        </>
    );
}

export default DoorsList;
