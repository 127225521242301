import React from 'react';
import { useFormikContext } from 'formik';

import OverridableField from 'components/DoorForm/OverridableField';

export default function PricingOverview({ doorData }) {
  const { values } = useFormikContext();

  return (
    <p>
      <span>Door Cost: ${doorData.cost_price}</span>
      <br />
      <OverridableField
        label="Door Sell Price: $"
        formFieldAccessor={'manual_sell_price'}
        fieldType={'number'}
        overrideValue={values.manual_sell_price}
        calculatedValue={doorData.calculated_sell_price}
      />
      <span>
        <OverridableField
          label="Jamb Sell Price: $"
          formFieldAccessor={'fire_prehang.fire_prehang_override.sell_price'}
          fieldType={'number'}
          overrideValue={values.fire_prehang.fire_prehang_override.sell_price}
          calculatedValue={doorData.phang_sell_price}
        />
      </span>
    </p>
  );
}
