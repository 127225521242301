import React, { useMemo } from "react";
import { find } from "lodash";
import { useDoorConfigOptions } from "hooks/useDoorConfigOptions";

export default function useCalculatorOverrideValidations(values) {
  const { skinCodes } = useDoorConfigOptions();

  const canOverrideExtraSkins = useMemo(() => {
    const chosenCode = find(
      skinCodes,
      (code) => code.value === values.extra_skin_code_id
    );
    if (typeof chosenCode !== "undefined" && chosenCode.shortcode === "FOR") {
      return true;
    } else {
      return false;
    }
  }, [values]);

  const canOverrideSkins = useMemo(() => {
    const chosenCode = find(
      skinCodes,
      (code) => code.value === values.skin_code_id
    );
    if (typeof chosenCode !== "undefined" && chosenCode.shortcode === "SPE") {
      return true;
    } else {
      return false;
    }
  }, [values]);

  return {
    canOverrideExtraSkins,
    canOverrideSkins,
  };
}
