import React from "react";
import SaveButton from "components/QuoteForm/SaveButton";
import XeroSendButton from "./XeroSendButton";
import {useParams} from "react-router-dom";
import {useQuote} from "../../hooks/useQuote";

function EditQuoteHeaderNav({isSaving, handleSave}) {
    const params = useParams()
    const {data: quote} = useQuote({id: params.id})

    return (
        <>
            {quote.status == "ready_for_dispatch" && (
                <XeroSendButton quoteId={params.id}/>
            )}
            <SaveButton isSaving={isSaving} handleSave={handleSave}/>
        </>
    );
}

export default EditQuoteHeaderNav;
