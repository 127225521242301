import React, {useEffect, useMemo} from 'react';
import {Field, useFormikContext} from 'formik';

import {useFireConfigOptions} from 'hooks/useFireConfigOptions';
import {withBlankOption} from '../../../utils/form-utils';
import DropdownInput from '../../Formik/DropdownInput';
import Input from '../../Formik/Input';
import EditableDropdownInput from "../../Formik/EditableDropdownInput";
import {useDoorConfigOptions} from "../../../hooks/useDoorConfigOptions";

export default function PrehangSection({prehangActive, toggleShowPrehangActive}) {
    const {values, setFieldValue} = useFormikContext();


    const handlePrehangToggle = e => {
        let confirmed;
        e.preventDefault();

        if (prehangActive) {
            confirmed = window.confirm(`This will clear the prehang from the door. Continue?`);
        }

        if (confirmed || !prehangActive) {
            const newVal = !prehangActive;
            setFieldValue('prehang_enabled', String(newVal));
            toggleShowPrehangActive(newVal);
        }
    };

    return (
        <div className="card mt-0">
            <div className="card-header d-flex align-items-center">
                <h2 className="card-heading mb-0">Prehang Details</h2>
                <a href="#" className="btn btn-rounded btn-red btn-shadow ml-auto" onClick={handlePrehangToggle}>
                    <span className="material-icons-outlined">{prehangActive ? 'remove' : 'add'}</span>
                </a>
            </div>
            {prehangActive && <PrehangSectionFields/>}
        </div>
    );
}

function CleanFieldOnUnmount({...props}) {
    const {setFieldValue} = useFormikContext();
    useEffect(() => () => setFieldValue(props.name, undefined), []);
    return <Field {...props} />;
}

function PrehangSectionFields() {
    const {setFieldValue, values} = useFormikContext();
    const {fireDoorJambTimbers, doorJambProfiles, fireDoorJambHandings} = useFireConfigOptions();
    const {hinges} = useDoorConfigOptions();

    const hingeSelectOptions = useMemo(() => withBlankOption(hinges), [hinges]);

    const fireDoorJambTimberSelectOptions = useMemo(() => withBlankOption(fireDoorJambTimbers), [fireDoorJambTimbers]);
    const fireDoorJambProfileSelectOptions = useMemo(() => withBlankOption(doorJambProfiles), [doorJambProfiles]);
    const fireDoorJambHandingSelectOptions = useMemo(() => withBlankOption(fireDoorJambHandings), [fireDoorJambHandings]);

    const defaultToZero = ({event, field, value}) => {
        if (value === '') {
            setFieldValue(field.name, 0);
        }
    };

    return (
        <div className="card-body">
            <div className="form-row">
                <div className="col-md-4 col-sm-6">
                    <div className="form-group">
                        <label htmlFor="fire_prehang.door_jamb_profile_id">Jamb Profile</label>
                        <CleanFieldOnUnmount
                            name="fire_prehang.door_jamb_profile_id"
                            component={DropdownInput}
                            options={fireDoorJambProfileSelectOptions}
                            as="select"
                        >
                            <option value=""></option>
                        </CleanFieldOnUnmount>
                    </div>
                </div>

                <div className="col-md-4 col-sm-6">
                    <div className="form-group">
                        <label htmlFor="fire_prehang.fire_door_jamb_timber_id">Jamb Timber</label>
                        <CleanFieldOnUnmount
                            name="fire_prehang.fire_door_jamb_timber_id"
                            component={DropdownInput}
                            options={fireDoorJambTimberSelectOptions}
                            as="select"
                        >
                            <option value=""></option>
                        </CleanFieldOnUnmount>
                    </div>
                </div>

                <div className="col-md-4 col-sm-6">
                    <div className="form-group">
                        <label htmlFor="fire_prehang.door_jamb_timber_id">Jamb Handing</label>
                        <CleanFieldOnUnmount
                            name="fire_prehang.door_jamb_handing_id"
                            component={DropdownInput}
                            options={fireDoorJambHandingSelectOptions}
                            as="select"
                        >
                            <option value=""></option>
                        </CleanFieldOnUnmount>
                    </div>
                </div>
            </div>
            <div className="form-row mt-5">
                <div className="col-md-4 col-sm-6">
                    <div className="form-group">
                        <label htmlFor="fire_prehang.fire_prehang_override.hinge_part_code">Manual Hinge Or Track Part
                            Code</label>
                        <Field
                            name="fire_prehang.fire_prehang_override.hinge_part_code"
                            placeholder={values.calculator.hinge.part_code}
                            component={EditableDropdownInput}
                            options={hingeSelectOptions}
                            as="select"
                        ></Field>
                    </div>
                </div>

                <div className="col-md-4 col-sm-6">
                    <div className="form-group">
                        <label htmlFor="fire_prehang.fire_prehang_override.hinge_part_price">Hinge Unit Price</label>
                        <CleanFieldOnUnmount name="fire_prehang.fire_prehang_override.hinge_part_price"
                                             component={Input}/>
                    </div>
                </div>

                <div className="col-md-4 col-sm-6">
                    <div className="form-group">
                        <label htmlFor="fire_prehang.fire_prehang_override.hinge_quantity">Hinge Quantity</label>
                        <CleanFieldOnUnmount
                            name="fire_prehang.fire_prehang_override.hinge_quantity"
                            component={Input}
                            afterChange={defaultToZero}
                        />
                    </div>
                </div>
            </div>

            <div className="form-row mt-5">
                <div className="col-md-3 col-sm-12">
                    <div className="form-group">
                        <label htmlFor="fire_prehang.wall_stud_width">Wall Stud Width</label>
                        <CleanFieldOnUnmount name="fire_prehang.wall_stud_width" component={Input}/>
                    </div>
                </div>
                <div className="col-md-3 col-sm-12">
                    <div className="form-group">
                        <label htmlFor="fire_prehang.hinge_face_gib_lining_thickness">Hinge Face Gib Lining
                            Thickness</label>
                        <CleanFieldOnUnmount name="fire_prehang.hinge_face_gib_lining_thickness" component={Input}/>
                    </div>
                </div>
                <div className="col-md-3 col-sm-12">
                    <div className="form-group">
                        <label htmlFor="fire_prehang.non_hinge_face_gib_lining_thickness">
                            Non-Hinge Face Gib Lining Thickness
                        </label>
                        <CleanFieldOnUnmount name="fire_prehang.non_hinge_face_gib_lining_thickness" component={Input}/>
                    </div>
                </div>
                <div className="col-md-3 col-sm-12">
                    <div className="form-group">
                        <label htmlFor="fire_prehang.door_jamb_floor_clearance">Floor Clearance</label>
                        <CleanFieldOnUnmount name="fire_prehang.door_jamb_floor_clearance" component={Input}/>
                    </div>
                </div>
            </div>
        </div>
    );
}
