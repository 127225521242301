import React from 'react';
import {getTipcoDoors} from 'utils/horizon-api-client';
import {useIndexData} from 'hooks/useIndexData';
import {searchOptions} from 'utils/base-door-search-options';

import DoorUtilities from 'components/DoorsList/DoorUtilities';

export function useTipcoDoors({quoteId = null, additionalSearchOptions = {}}) {
    const {refetch, recordData, totalCount, searchFieldValue, setSearchTerm, status} = useIndexData({
        requestData: getTipcoDoors,
        requestParams: {quoteId},
        searchOptions: {...additionalSearchOptions, ...searchOptions},
        mapRecordToTable: mapDoorToTable,
    });

    function mapDoorToTable(door) {
        const vpCount = door.vision_panels.length;
        const deCount = door.door_door_extras.length;
        const peCount = door.door_prehang_extras.length;

        let attachedItemCounts = "";
        if (vpCount) {
            attachedItemCounts = attachedItemCounts.concat(`VP:${vpCount} `)
        }
        if (deCount) {
            attachedItemCounts = attachedItemCounts.concat(`Ex:${deCount} `)
        }
        if (peCount) {
            attachedItemCounts = attachedItemCounts.concat(`PEx:${peCount}`)
        }

        return {
            id: door.id,
            door_class: 'TIPCO',
            quote_id: door.quote_id,
            door_type: door.door_type,
            leaf_type: door.tipco_leaf?.code || '',
            jamb_type: door.tipco_jamb?.code || '',
            subRows: door.children.map(child => mapDoorToTable(child)),
            height: door.actual_door_height,
            actual_door_height: door.actual_door_height,
            width: door.width,
            width_pair: door.width_pair,
            thickness: door.thickness,
            reference: door.reference,
            parent_id: door.parent_id,
            position: door.position,
            serial: door.serial,
            sell_price_with_phang: (Number(door.sell_price) + Number(door.phang_sell_price)).toFixed(2) || 0,
            attached_item_counts: attachedItemCounts,
            dropdown: <DoorUtilities door={{door_class: 'TIPCO', ...door}}/>,
        };
    }

    return {
        doors: recordData,
        totalDoors: totalCount,
        searchFieldValue: searchFieldValue,
        setSearchTerm: setSearchTerm,
        status: status,
        refetch,
    };
}
