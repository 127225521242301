import React from "react";
import SaveButton from "components/QuoteForm/SaveButton";

function NewQuoteHeaderNav({ isSaving, handleSave }) {
  return (
    <>
      <li className="nav-item">
        <a
          href={`/jobs/new?postSaveRedirect=newQuote`}
          className="nav-btn btn-fill"
        >
          Create Job
        </a>
      </li>
      <SaveButton isSaving={isSaving} handleSave={handleSave} />
    </>
  );
}

export default NewQuoteHeaderNav;
