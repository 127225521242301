import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { cloneQuote } from 'utils/horizon-api-client';

function DuplicateQuote({ resourceId }) {
  const [submitting, setSubmitting] = useState(false);

  async function handleConfirm(e) {
    e.preventDefault();
    if (submitting) {
      return false;
    }

    const confirmed = window.confirm(`This will duplicate the quote. Continue?`);

    if (confirmed) {
      setSubmitting(true);
      const response = await cloneQuote({ id: resourceId });
      setSubmitting(false);

      switch (response.status) {
        case 200:
          toast.success(`Successfully duplicated quote`);
          window.location.reload();
          break;
        case 403:
          toast.error('You do not have permissions to perform this action');
          break;
        case 422:
          toast.error('Error duplicating quote');
          break;
        case 500:
          toast.error('Internal server error.');
          break;
      }
    }
  }

  return (
    <>
      <Link to="#" className="dropdown-item" onClick={handleConfirm}>
        {submitting ? 'Duplicating..' : 'Duplicate Quote'}
      </Link>
    </>
  );
}

export default DuplicateQuote;
