import React, { useEffect, useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import { Persist } from 'formik-persist';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import 'assets/stylesheets/doorForm.css';

import QuoteFormHeader from 'components/QuoteForm/QuoteFormHeader';

import LoadingOverlay from 'components/LoadingOverlay';

import DoorDetails from 'components/DoorForm/Fire/DoorDetails';

import { useQuote } from 'hooks/useQuote';
import PrehangSection from './PrehangSection';
import { FireGrilleOpenings } from './FireGrilleOpenings';
import { FireVisionPanelsList } from './FireVisionPanelsList';
import DoorExtrasList from '../DoorExtrasList';

function buildDoorSchema({ skipValidations }) {
  if (skipValidations) {
    return Yup.object();
  }

  return Yup.object().shape({});
}

/**
 * @param {number} quoteId
 * @param {func} onSubmit
 * @param {object} initialValues
 * @param {boolean} isLoading
 * @param {boolean} shouldPersist
 * @param {string} formName
 * @param {JSX.Element} headerRightNav
 * @returns {JSX.Element}
 * @constructor
 */
export default function FireDoorForm({
  quoteId,
  onSubmit,
  initialValues,
  isLoading,
  shouldPersist,
  formName,
  headerRightNav,
}) {
  const history = useHistory();
  const { data: quoteData } = useQuote({ id: quoteId });
  const [prehangActive, toggleShowPrehangActive] = useState(false);

  useEffect(() => {
    toggleShowPrehangActive(initialValues.prehang_enabled);
  }, [initialValues.prehang_enabled]);

  const doorSchema = useMemo(
    () =>
      buildDoorSchema({
        prehangActive,
      }),
    [prehangActive]
  );

  function handleNavigation(e) {
    e.preventDefault();
    const selected = e.target.dataset.name;
    history.push(`/quotes/${quoteId}/edit?tab=${selected}`);
  }

  return (
    <Formik validationSchema={doorSchema} initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true}>
      <>
        <QuoteFormHeader
          onSubmit={onSubmit}
          quoteId={quoteId}
          onNavigation={handleNavigation}
          selectedNav={'quoteDoors'}
          isSaving={false}
          headerTitle={`Quote No: ${quoteData.quote_no}`}
          rightNav={headerRightNav}
        />
        <LoadingOverlay isLoading={isLoading} />
        <Form>
          {shouldPersist && <Persist name={formName} />}
          <DoorDetails />
          <FireVisionPanelsList />
          <FireGrilleOpenings />
          <DoorExtrasList onDelete={() => {}} />
          <PrehangSection prehangActive={prehangActive} toggleShowPrehangActive={toggleShowPrehangActive} />
        </Form>
      </>
    </Formik>
  );
}
