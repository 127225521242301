import React, {useEffect, useMemo, useState} from 'react';
import {Form, Formik} from 'formik';
import {Persist} from 'formik-persist';
import {useHistory} from 'react-router-dom';
import * as Yup from 'yup';

import 'assets/stylesheets/doorForm.css';

import QuoteFormHeader from 'components/QuoteForm/QuoteFormHeader';

import LoadingOverlay from 'components/LoadingOverlay';

import DoorDetails from 'components/DoorForm/Tipco/DoorDetails';
import PrehangSection from 'components/DoorForm/PrehangSection';
import DoorExtrasList from 'components/DoorForm/DoorExtrasList';
import PrehangExtrasList from 'components/DoorForm/PrehangExtrasList';

import {useQuote} from 'hooks/useQuote';
import VisionPanelsList from "../VisionPanelsList";

function buildDoorSchema({prehangActive, hingesNeeded, skipValidations}) {
    if (skipValidations) {
        return Yup.object();
    }

    return Yup.object().shape({
        tipco_hung_type_id: Yup.string().required('Required')
    });
}

function TipcoDoorForm({quoteId, onSubmit, initialValues, isLoading, shouldPersist, formName, headerRightNav}) {
    const history = useHistory();
    const [prehangActive, toggleShowPrehangActive] = useState(false);
    const [hingesNeeded, markHingesNeeded] = useState(true);
    const {data: quoteData} = useQuote({id: quoteId});

    useEffect(() => {
        toggleShowPrehangActive(initialValues.prehang_enabled);
    }, [initialValues.prehang_enabled]);

    const doorSchema = useMemo(
        () =>
            buildDoorSchema({
                prehangActive,
                hingesNeeded,
            }),
        [prehangActive, hingesNeeded]
    );

    function handleNavigation(e) {
        e.preventDefault();
        const selected = e.target.dataset.name;
        history.push(`/quotes/${quoteId}/edit?tab=${selected}`);
    }

    return (
        <Formik validationSchema={doorSchema} initialValues={initialValues} onSubmit={onSubmit}
                enableReinitialize={true}>
            <>
                <QuoteFormHeader
                    onSubmit={onSubmit}
                    quoteId={quoteId}
                    onNavigation={handleNavigation}
                    selectedNav={'quoteDoors'}
                    isSaving={false}
                    headerTitle={`Quote No: ${quoteData.quote_no}`}
                    rightNav={headerRightNav}
                />
                <LoadingOverlay isLoading={isLoading}/>
                <Form>
                    {shouldPersist && <Persist name={formName}/>}
                    <DoorDetails/>
                    <DoorExtrasList onDelete={() => {
                    }}/>
                    <VisionPanelsList onVisionPanelDelete={() => {
                    }}/>
                    <PrehangSection
                        prehangActive={prehangActive}
                        toggleShowPrehangActive={toggleShowPrehangActive}
                        markHingesNeeded={markHingesNeeded}
                    />
                    <PrehangExtrasList onDelete={() => {
                    }}/>
                </Form>
            </>
        </Formik>
    );
}

export default TipcoDoorForm;
