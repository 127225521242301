import React, { useRef, useCallback } from "react";
import { debounce } from "lodash";

const useDebouncedCallback = (callback, delay, opts) => {
  const callbackRef = useRef();
  callbackRef.current = callback;
  return useCallback(
    debounce((...args) => callbackRef.current(...args), delay, opts),
    []
  );
};

export default useDebouncedCallback;
