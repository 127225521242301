import {createXeroInvoice} from "./horizon-api-client";
import {toast} from "react-toastify";

export async function syncWithXero(quoteId, onMiscFailure) {
    const invResponse = await createXeroInvoice({quoteId: quoteId})
    switch (invResponse.status) {
        case 200:
            toast.success("Invoice synced successfully")
            break;
        case 400:
            toast.error(invResponse.data.error || "Something went wrong")
            break;
        case 403:
            await onMiscFailure(invResponse);
            break;
    }
}