import React from 'react';
import QuoteFormHeader from '../QuoteForm/QuoteFormHeader';
import LoadingOverlay from '../LoadingOverlay';
import { Form, Formik } from 'formik';
import { Persist } from 'formik-persist';
import DoorExtrasList from '../DoorForm/DoorExtrasList';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import StandaloneItemDetails from './StandaloneItemDetails';
import StandaloneComponentsList from './StandaloneComponentsList';

const validationSchema = Yup.object().shape({
  notes: Yup.string(),
  reference: Yup.string(),
  contribution_factor: Yup.number().required(),
});

function StandaloneItemForm({ quoteId, onSubmit, initialValues, isLoading, shouldPersist, formName, headerRightNav }) {
  const history = useHistory();

  function handleNavigation(e) {
    e.preventDefault();
    const selected = e.target.dataset.name;
    history.push(`/quotes/${quoteId}/edit?tab=${selected}`);
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      <>
        <QuoteFormHeader
          onSubmit={onSubmit}
          quoteId={quoteId}
          onNavigation={handleNavigation}
          selectedNav={'quoteDoors'}
          isSaving={false}
          headerTitle={`Quote No: ${quoteId}`}
          rightNav={headerRightNav}
        />
        <LoadingOverlay isLoading={isLoading} />
        <Form>
          {shouldPersist && <Persist name={formName} />}
          <StandaloneItemDetails />
          <StandaloneComponentsList onDelete={() => {}} />
          <DoorExtrasList onDelete={() => {}} />
        </Form>
      </>
    </Formik>
  );
}

export default StandaloneItemForm;
