import React from "react";
import Quotes from "pages/Quotes";

function DefaultQuotes() {
  return (
    <>
      <Quotes prefilter="quotes" />
    </>
  );
}

export default DefaultQuotes;
