import { validateTokenWithApi } from "./horizon-api-client";

export const setToken = ({ accessToken, client, uid, expiry }) => {
  if (typeof accessToken !== "undefined" && accessToken.length) {
    localStorage.setItem(
      "auth-tokens",
      JSON.stringify({
        "access-token": accessToken,
        client: client,
        uid: uid,
        expiry: expiry,
      })
    );

    return getToken();
  } else {
    return false;
  }
};

export const clearToken = () => {
  localStorage.removeItem("auth-tokens");
};

export const getToken = () => {
  try {
    return JSON.parse(localStorage.getItem("auth-tokens"));
  } catch {
    return false;
  }
};

export const validateToken = async ({
  onSuccessfulValidation,
  onFailedValidation,
}) => {
  validateTokenWithApi()
    .then((response) => {
      setToken({
        client: response.headers.client,
        uid: response.headers.uid,
        accessToken: response.headers["access-token"],
        expiry: response.headers.expiry,
      });

      const user = response.data.data;
      try {
        onSuccessfulValidation(user);
      } catch {
        console.log("Validation callback failure in validateToken");
      }
    })
    .catch((response) => {
      onFailedValidation();
    });
};
